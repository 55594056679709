import { FC, useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import Button from "@mui/material/Button";
import { useWizardContext } from "../../context/WizardContext";

interface LoaderProps {
  sx?: SxProps;
  timeToWait?: number;
  onReloadContent: () => void;
  loadingError?: boolean;
}

const Loader: FC<LoaderProps> = ({ sx, timeToWait = 3000, onReloadContent, loadingError }) => {
  const [loadingTimeOut, setLoadingTimeOut] = useState<boolean>(false);
  const { locale, appTexts } = useWizardContext();
  const { loader } = appTexts || {};

  useEffect(() => {
    const timerId = setTimeout(setLoadingTimeOut, timeToWait, true);
    return () => clearTimeout(timerId);
  }, [timeToWait]);

  const handleReload = () => {
    onReloadContent();
    setLoadingTimeOut(false);
    setTimeout(setLoadingTimeOut, timeToWait, true);
  };

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      {loadingTimeOut || loadingError ? (
        <>
          <Typography sx={{ margin: 1, fontWeight: "bold", fontStretch: "condensed", color: "grey.500" }}>
            {loader?.errorMessage?.[locale]}
          </Typography>
          <Button onClick={handleReload} sx={{ py: 0.75, px: 2.25 }} variant="contained">
            {loader?.tryAgainBtn?.[locale]}
          </Button>
        </>
      ) : (
        <>
          <Typography sx={{ margin: 1, fontWeight: "bold", fontStretch: "condensed" }}>
            {loader?.loadingText?.[locale]}
          </Typography>
          <LinearProgress color="primary" sx={{ borderRadius: "3px", width: "86px" }} />
        </>
      )}
    </Stack>
  );
};

export default Loader;
