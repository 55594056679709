import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { LOCALE, useWizardContext } from "../context/WizardContext";

const cacheRtl = createCache({ key: "muirtl", stylisPlugins: [rtlPlugin] });
const cacheLtr = createCache({ key: "muiltr", stylisPlugins: [] });

const RtlProvider: React.FC = ({ children }) => {
  const { locale } = useWizardContext();
  const isRtl = locale === LOCALE.HE;

  return <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>{children}</CacheProvider>;
};

export default RtlProvider;
