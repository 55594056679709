import React, { useCallback, useState, useMemo, useRef, useEffect } from "react";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Project, Apartment, Building } from "../../generated/graphql";
import StepLayout from "../../components/StepLayout/StepLayout";
import { useWizardContext, LOCALE } from "../../context/WizardContext";
import SliderRange from "../../components/SliderRange/SliderRange";
import CustomRadioButton from "../../components/CustomRadioButton/CustomRadioButton";
import ModelViewer from "../../components/BuildingModel/ModelViewer/ModelViewer";
import { formatPrice, layoutSize } from "../../utils/format";
import "../../transitionStyle.css";
import PreviewPopup from "./components/PreviewPopup";
import StepLayoutMobile from "../../components/StepLayoutMobile/StepLayoutMobile";
import useMedia from "../../context/hooks/useMedia";
import useScreenTitle from "../../components/ScreenTitle/ScreenTitle";
import { useNavigate } from "react-router-dom";
import { Popover } from "@mui/material";
import { ApartmentDialog } from "../../components/ApartmentDialog/ApartmentDialog";

const BASE_ROUTE = process.env.REACT_APP_BASE_ROUTE || "";

export interface FilterableApartment {
  price: number;
  roomsNumber: number;
  layout: {
    id: NonNullable<NonNullable<Apartment["layout"]>["_id"]>;
    name: string;
    size: string;
    iconUrl: string;
  };
  direction: NonNullable<Apartment["direction"]>;
  building: {
    id: NonNullable<Building["_id"]>;
    name: string;
  };
  floor: NonNullable<Apartment["floor"]>;
}

export type SelectionParams<T> = {
  [fieldName in keyof T]: (fieldName extends "price" ? number[] : T[fieldName]) | null;
};

type SelectionOptions<T> = {
  [fieldName in keyof T]: T[fieldName][];
};

export type ApartmentSelectionParams = SelectionParams<FilterableApartment>;

type ApartmentSelectionOptions = SelectionOptions<FilterableApartment>;

const filterFieldsInSelectionOrder: Partial<keyof FilterableApartment>[] = [
  "price",
  "roomsNumber",
  "layout",
  "direction",
  "floor",
];

export enum View {
  Layout3D = 0,
  Layout2D = 1,
  ApartmentView = 2,
  FloorView = 3,
}

const priceStep = 500;

const wideSelectionControlsStyle = {
  display: "grid",
  gridTemplateColumns: "157px 157px",
  gap: 0.75,
  marginTop: 0,
  marginLeft: 0,
  "& .MuiFormControlLabel-root": {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
  },
};

export function ApartmentSelection() {
  const {
    project: data,
    selectedApartment,
    setSelectedApartmentId,
    setSelectedBuilding,
    threeDModelsData,
    setPriceRange,
    locale,
    appTexts,
    selectedParams: selectedParamsFromContext,
    setSelectedParams,
    activeStep,
    steps,
  } = useWizardContext();
  const navigate = useNavigate();

  useScreenTitle(appTexts?.screenName?.ApartmentSelection?.[locale]);

  const { directions, apSelection } = appTexts || {};
  const buildingCount = data?.buildings?.length || 0;

  const apartments = useMemo(() => prepareApartmentsData(data, locale), [data, locale]);
  const contentContainerRef = useRef();
  const { isMobile } = useMedia();

  const [apartmentDialog, setApartmentDialog] = useState<ApartmentSimple | null>();
  const [apartmentAnchor, setApartmentAnchor] = useState<Element | null | undefined>(null);
  const [apartmentDialogShow, setApartmentDialogShow] = useState(false);
  const [apartmentDialogHovered, setApartmentDialogHovered] = useState(false);

  const [isContentPopupOpen, setIsContentPopupOpen] = useState(false);
  const [previewLayoutId, setPreviewLayoutId] = useState<string | null>(null);

  useEffect(() => {
    if (!apartments || !apartments.length) {
      return;
    }
    const maxPrice = apartments?.reduce((max, apt) => Math.max(max, apt.price ?? 0), 0);
    const minPrice = apartments?.reduce((min, apt) => Math.min(min, apt.price ?? apartments[0].price), 0);

    setTimeout(() => handlePriceRangeChange(null, [minPrice, maxPrice], 0), 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartments]);

  const selectedParams = useMemo(
    () => ({
      ...selectedParamsFromContext,
      // find the layout object in one of the apartments to enable comparing layout objects by reference
      layout: apartments?.find(({ layout }) => layout.id === selectedParamsFromContext.layout?.id)?.layout || null,
      // find the building object in one of the apartments to enable comparing building objects by reference
      building:
        apartments?.find(({ building }) => selectedParamsFromContext?.building?.id === building.id)?.building || null,
    }),
    [selectedParamsFromContext, apartments]
  );

  const [view, setView] = useState<View | null>(null);
  const [currentFieldIndex, setCurrentFieldIndex] = useState(() => {
    const lastFieldIndex = filterFieldsInSelectionOrder.length - 1;
    return selectedParams[filterFieldsInSelectionOrder[lastFieldIndex]] ? lastFieldIndex : 0;
  });

  const renderPrice = (price: number) => {
    const formattedPrice = formatPrice(locale, price, false);
    return (
      <span dir="ltr">
        <span dir="rtl">{formattedPrice}</span>K
      </span>
    );
  };

  // scroll to the next field once it appears
  // inspired by this https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const fieldContainerRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView();
    }
  }, []);

  function setParam<T extends keyof ApartmentSelectionParams>(paramName: T, value: ApartmentSelectionParams[T]) {
    const { options: newOptions, matchedApartments } = filterData(apartments || [], {
      ...selectedParams,
      [paramName]: value,
    });

    // find conflicting params to reset
    const fieldsWithoutPrice = filterFieldsInSelectionOrder.slice(1);
    const firstConflictingParamIndex = fieldsWithoutPrice.findIndex((paramName) => {
      if (selectedParams[paramName] === null) return false;
      return !newOptions[paramName].some((option) => option === selectedParams[paramName]);
    });

    const paramsToReset =
      firstConflictingParamIndex !== -1
        ? fieldsWithoutPrice
            .slice(firstConflictingParamIndex)
            .reduce((acc: Partial<ApartmentSelectionParams>, paramKey) => {
              acc[paramKey] = null;
              return acc;
            }, {})
        : {};

    setSelectedParams((prevState: ApartmentSelectionParams) => ({
      ...prevState,
      [paramName]: value,
      ...paramsToReset,
    }));

    // update selected apartment and building in the context
    if (matchedApartments.length > 0 && (selectedParams["floor"] !== null || paramName === "floor")) {
      setSelectedApartmentId(matchedApartments[0].id);
      setSelectedBuilding(buildingCount > 1 ? selectedParams.building : matchedApartments[0].building);
    } else {
      setSelectedApartmentId(null);
      setSelectedBuilding(null);
    }

    !isMobile && setIsContentPopupOpen(paramName === "layout" || paramName === "floor");
    setCurrentFieldIndex(filterFieldsInSelectionOrder.indexOf(paramName));
  }

  if (!apartments) return <div>Loading...</div>; // TODO: use loader animation

  const { options, matchedApartments } = filterData<ApartmentSimple>(apartments, selectedParams);
  const priceSliderMarks = (options.price as number[])
    .sort((a, z) => a - z)
    .map((price, index, array) => ({
      value: price,
      label: index === 0 || index === array.length - 1 ? renderPrice(price) : undefined,
    }));

  const handlePriceRangeChange = (event: Event | null, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const lowerPrice = selectedParams.price?.[0] || priceSliderMarks[0].value;
    const upperPrice = selectedParams.price?.[1] || priceSliderMarks[priceSliderMarks.length - 1].value;
    let priceRange;
    if (activeThumb === 0) {
      priceRange = [Math.min(newValue[0], upperPrice - priceStep), upperPrice];
    } else {
      priceRange = [lowerPrice, Math.max(newValue[1], lowerPrice + priceStep)];
    }
    setParam("price", priceRange);
  };

  const setContextPriceRange = () => {
    if (selectedParams.price) {
      setPriceRange([selectedParams.price[0], selectedParams.price[1]]);
    }
  };

  const priceField = (
    <SelectionField gap={isMobile ? 1.3 : 2.5} label={apSelection?.choseThePrice?.[locale]}>
      <SliderRange
        max={priceSliderMarks[priceSliderMarks.length - 1].value}
        min={priceSliderMarks[0].value}
        marks={priceSliderMarks}
        step={null}
        disableSwap
        onChange={handlePriceRangeChange}
        value={
          selectedParams.price
            ? [selectedParams.price[0], selectedParams.price[1]]
            : [priceSliderMarks[0].value, priceSliderMarks[priceSliderMarks.length - 1].value]
        }
        sx={{ verticalAlign: "middle" }}
      />
    </SelectionField>
  );

  const roomsNumberField = (
    <SelectionField label={apSelection?.numOfRoomsInApartment?.[locale]}>
      <CustomRadioButton
        value={selectedParams.roomsNumber}
        options={(options.roomsNumber as number[]).sort((a, z) => a - z)}
        onChange={(event, value) => {
          const rooms = parseFloat(value);
          if (rooms === selectedParams.roomsNumber) {
            setParam("roomsNumber", null);
          } else {
            setParam("roomsNumber", rooms);
          }
        }}
        variant={isMobile ? "mobile" : undefined}
      />
    </SelectionField>
  );

  const layoutSelector = (
    <CustomRadioButton
      value={selectedParams.layout && selectedParams.layout.id}
      options={options.layout
        .sort()
        .map((layoutOption) => ({ ...layoutOption, name: layoutSize(locale, layoutOption.size) }))}
      widthType="wide"
      onChange={(event, value) => {
        if (value === selectedParams.layout?.id) {
          setParam("layout", null);
          setPreviewLayoutId(null);
          setIsContentPopupOpen(false);
          return;
        }
        setView(View.Layout2D);
        if (isMobile) {
          setPreviewLayoutId(value);
          setIsContentPopupOpen(true);
          return;
        }
        setParam(
          "layout",
          options.layout.find((layout) => layout.id === value) as ApartmentSelectionOptions["layout"][number]
        );
      }}
      sx={[
        isMobile
          ? {
              "& .MuiFormControlLabel-root": {
                flexShrink: 0,
                width: 157,
                height: 142,
                marginLeft: 1.125,
              },
            }
          : { ...wideSelectionControlsStyle, gridAutoRows: "142px" },
      ]}
      variant={isMobile ? "mobile" : undefined}
    />
  );

  const layoutField = (
    <SelectionField ref={fieldContainerRef} label={apSelection?.apartmentLayout?.[locale]}>
      {layoutSelector}
    </SelectionField>
  );

  const directionField = (
    <SelectionField ref={fieldContainerRef} label={apSelection?.apartmentDirection?.[locale]}>
      <CustomRadioButton
        value={selectedParams.direction}
        options={(options.direction as string[]).sort().map((direction) => ({
          id: direction,
          name: directions?.[direction]?.[locale] || "",
        }))}
        widthType="wide"
        onChange={(event, value) => {
          if (value === selectedParams.direction) {
            setParam("direction", null);
          } else {
            setParam("direction", value);
          }
        }}
        variant={isMobile ? "mobile" : undefined}
        sx={[!isMobile && wideSelectionControlsStyle]}
      />
    </SelectionField>
  );

  const buildingField = (
    <SelectionField ref={fieldContainerRef} label={apSelection?.choseBuilding?.[locale]}>
      <CustomRadioButton
        value={selectedParams.building && selectedParams.building.id}
        options={options.building?.sort()}
        widthType="wide"
        onChange={(event, value) => {
          setParam(
            "building",
            options.building?.find((building) => building.id === value) as ApartmentSelectionOptions["building"][number] // the result of .find is supposed to always be defined here
          );
        }}
        variant={isMobile ? "mobile" : undefined}
        sx={[!isMobile && wideSelectionControlsStyle]}
      />
    </SelectionField>
  );

  const floorField = (
    <SelectionField ref={fieldContainerRef} label={apSelection?.apartmentFloor?.[locale]}>
      <CustomRadioButton
        value={selectedParams.floor}
        options={(options.floor as number[]).sort((a, z) => a - z)}
        onChange={(event, value) => {
          const floor = parseInt(value);

          if (isMobile) setIsContentPopupOpen(true);
          if (floor === selectedParams.floor) {
            setParam("floor", null);
            setPreviewLayoutId(null);
            setIsContentPopupOpen(false);
          } else {
            setParam("floor", floor);
          }
          setView(View.FloorView);
        }}
        variant={isMobile ? "mobile" : undefined}
      />
    </SelectionField>
  );

  const handleSelectApartment = (id: string) => {
    const activeStepIndex = steps.indexOf(activeStep);
    setSelectedApartmentId(id);

    if (activeStep < steps[steps.length - 1]) {
      navigate(`/${BASE_ROUTE}/${steps[activeStepIndex + 1]}`);
    }
  };

  const showApartmentDialog = (apartment: ApartmentSimple | null, element?: Element) => {
    if ((!apartment || !element) && !apartmentDialogHovered) {
      setApartmentDialogShow(false);
      return;
    }
    setApartmentDialogShow(true);
    apartment && setApartmentDialog(apartment);
    element && setApartmentAnchor(element);
  };

  const apartmentDialogView = (
    <Popover
      open={apartmentDialogShow || apartmentDialogHovered}
      onClose={() => showApartmentDialog(null)}
      anchorEl={apartmentAnchor}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      sx={{ pointerEvents: "none" }}
      PaperProps={{
        sx: { pointerEvents: "auto" },
        onMouseEnter: () => {
          setApartmentDialogHovered(true);
          setApartmentDialogShow(true);
        },
        onMouseLeave: () => {
          setApartmentDialogHovered(false);
          setApartmentDialogShow(false);
        },
      }}
    >
      <ApartmentDialog
        apartment={apartmentDialog}
        onClose={() => {
          showApartmentDialog(null);
        }}
        onSelect={() => {
          showApartmentDialog(null);
          handleSelectApartment(apartmentDialog!.id);
        }}
      />
    </Popover>
  );

  const threeDBuildingView = (
    <ModelViewer
      images={threeDModelsData?.building?.buildingModelImageList || []}
      overlayImages={threeDModelsData?.building?.buildingModelOverlayImage || []}
      highlightIds={matchedApartments?.map((apt) => apt.modelId)}
      matchedApartments={matchedApartments}
      showApartmentDialog={showApartmentDialog}
    />
  );

  function handleForwardMobile() {
    setCurrentFieldIndex((currentFieldIndex) => currentFieldIndex + 1);
  }

  function handleBackMobile() {
    setCurrentFieldIndex((currentFieldIndex) => currentFieldIndex - 1);
  }

  const currentFieldName = filterFieldsInSelectionOrder[currentFieldIndex];

  if (isMobile) {
    const fieldNodeTreeByName: Record<typeof filterFieldsInSelectionOrder[number], React.ReactElement> = {
      price: priceField,
      roomsNumber: roomsNumberField,
      layout: layoutField,
      direction: directionField,
      building: buildingField,
      floor: floorField,
    };

    return (
      <StepLayoutMobile
        footerContent={
          <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
            <TransitionGroup component={null}>
              <CSSTransition key={currentFieldName} classNames="mobile-footer" timeout={500}>
                <Box
                  sx={[
                    currentFieldName === "price"
                      ? { width: "80%", mx: "auto" }
                      : { display: "flex", justifyContent: "center", alignItems: "center" },
                  ]}
                >
                  {currentFieldName === "layout"
                    ? apSelection?.apartmentLayout?.[locale]
                    : fieldNodeTreeByName[currentFieldName]}
                </Box>
              </CSSTransition>
            </TransitionGroup>
          </Box>
        }
        onBack={currentFieldIndex > 0 ? handleBackMobile : null}
        onForward={
          currentFieldIndex < filterFieldsInSelectionOrder.length - 1
            ? handleForwardMobile
            : () => {
                setContextPriceRange();
                return true;
              }
        }
        dimBackground={currentFieldName === "layout"}
        showForwardButton={!!selectedParams[currentFieldName]}
        forwardButtonText={currentFieldName === "floor" ? apSelection?.continueToSummary?.[locale] : ""}
      >
        {threeDBuildingView}

        {apartmentDialogView}

        <CSSTransition in={currentFieldName === "layout"} timeout={500} unmountOnExit classNames="mobile-content">
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          />
        </CSSTransition>
        <TransitionGroup component={null}>
          {currentFieldName === "layout" && (
            <CSSTransition key={currentFieldName} classNames="mobile-content" timeout={500}>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "100%",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": { display: "none" },
                    scrollbarWidth: "none",
                    pt: "15px", // prevents selected (check) icon from being cut on very narrow screens
                  }}
                >
                  <Box sx={{ px: "min(4rem, 8vw)", width: "max-content" }}>{layoutSelector}</Box>
                </Box>
              </Stack>
            </CSSTransition>
          )}
        </TransitionGroup>
        <PreviewPopup
          onClose={() => {
            setPreviewLayoutId(null);
            setIsContentPopupOpen(false);
          }}
          hideBackdrop={true}
          isOpen={isContentPopupOpen}
          apartment={
            previewLayoutId
              ? (apartments.find((apartment) => apartment.layout.id === previewLayoutId) as ApartmentSimple)
              : matchedApartments[0]
          }
          onViewChange={(view: View) => setView(view)}
          currentView={view}
          containerRef={contentContainerRef}
          onLayoutSelectedClick={
            currentFieldName === "layout"
              ? (value: string) => {
                  setPreviewLayoutId(null);
                  setIsContentPopupOpen(false);
                  setParam(
                    "layout",
                    options.layout.find((layout) => layout.id === value) as ApartmentSelectionOptions["layout"][number]
                  );
                }
              : undefined
          }
          currentFieldName={currentFieldName}
        />
      </StepLayoutMobile>
    );
  }

  // This is the children of StepSidePanel component
  const sidePanelContent = (
    <Stack spacing={3} mb={1.875}>
      <Typography variant="h1">{apSelection?.chooseYourApartment?.[locale]}</Typography>
      {priceField}
      {selectedParams.price &&
        (matchedApartments.length > 0 ? (
          <>
            {roomsNumberField}
            {selectedParams.roomsNumber && layoutField}
            {selectedParams.layout && directionField}
            {selectedParams.direction && floorField}
            {selectedParams.floor && buildingCount > 1 ? buildingField : null}
          </>
        ) : (
          <div>{apSelection?.noApartmentsFound?.[locale]}</div>
        ))}
    </Stack>
  );

  // This is the children of StepSideContent component
  const stepContent = (
    <Box sx={{ height: "100%", width: "100%" }} ref={contentContainerRef}>
      {threeDBuildingView}

      {apartmentDialogView}

      <PreviewPopup
        onClose={() => {
          setIsContentPopupOpen(false);
        }}
        isOpen={isContentPopupOpen}
        apartment={matchedApartments[0]}
        onViewChange={(view) => setView(view)}
        currentView={view}
        containerRef={contentContainerRef}
        currentFieldName={currentFieldName}
      />
    </Box>
  );

  return (
    <StepLayout
      sidePanelChildren={sidePanelContent}
      nextDisabled={!selectedApartment}
      contentChildren={stepContent}
      onNextStep={setContextPriceRange}
    />
  );
}

interface SelectionFieldProps {
  label: React.ReactNode;
  children: React.ReactNode;
  gap?: number;
  ref?: React.ForwardedRef<HTMLElement>;
}

const SelectionField: React.ForwardRefExoticComponent<SelectionFieldProps> = React.forwardRef(
  ({ label, children, gap }: SelectionFieldProps, ref) => {
    const { isMobile } = useMedia();

    return (
      <Box
        ref={ref}
        sx={{
          display: "grid",
          gridTemplateColumns: isMobile ? "max-content 1fr" : "",
          alignItems: isMobile ? "center" : "start",
          gap: gap ?? (isMobile ? 0.75 : 1.125),
        }}
      >
        <Typography
          variant="subtitle1"
          sx={[
            {
              whiteSpace: "nowrap",
            },
            isMobile && {
              "&::after": {
                content: "':'",
              },
            },
          ]}
        >
          {label}
        </Typography>
        <Box
          sx={[isMobile && { overflowX: "auto", "&::-webkit-scrollbar": { display: "none" }, scrollbarWidth: "none" }]}
        >
          {children}
        </Box>
      </Box>
    );
  }
);

export interface ApartmentSimple extends FilterableApartment {
  id: NonNullable<Apartment["_id"]>;
  apartmentNumber: string;
  layoutImage: { url: string; altText: string };
  unfurnishedImage: { url: string; altText: string };
  balconyViews: { url: string; altText: string };
  floorView: { url: string; altText: string };
  modelId: string;
  isAvailable: boolean;
}

const generateModelId = (apt: Apartment | null): string => {
  const toNumber = (num: string | null | number = 0): string =>
    Number(num)?.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
  const toInitials = (dir: string | null = ""): string =>
    dir
      ?.split("_")
      ?.map((d) => d.charAt(0).toUpperCase())
      ?.join("") || "";

  return `${apt?.layout?.name}_${toNumber(apt?.apartmentNumber)}_${toNumber(apt?.floor)}_${toNumber(
    apt?.layout?.roomCount
  )}_${toInitials(apt?.direction)}`;
};

/*
 * merges apartments received from the server to a single list and converts each apartment to the format
 * that is better suited for filtering
 *
 * IMPORTANT: All apartment field values produced by this function should be comparable by reference. For example:
 * if 2 apartments belong to the same building which is described by the object {id: 123, name: "Building A"} they should reference the same object in their "building" field.
 * This is necessary to enable comparing apartment fields using === no matter if the value is of primitive type or object type.
 * */
function prepareApartmentsData(data: Project | null, locale: LOCALE) {
  const layoutObjects: Record<string, FilterableApartment["layout"]> = {};

  return data?.buildings?.reduce((acc: ApartmentSimple[], building) => {
    const { apartments, _id, name } = building!;

    const buildingObject = { id: _id!, name: name![locale] };

    if (apartments) {
      const filterableApartments = apartments.map((apartment) => {
        const layoutId = apartment!.layout!._id!;

        if (!layoutObjects[layoutId]) {
          layoutObjects[layoutId] = {
            id: layoutId,
            iconUrl: apartment!.layout!.icon!.asset!.url!,
            name: apartment!.layout!.name!,
            size: apartment!.layout!.size ? `${apartment!.layout!.size} ${locale === "he" ? "מ”ר" : "sq. ft."}` : "",
          };
        }

        return {
          id: apartment!._id!,
          price: apartment!.basePrice![locale]!,
          roomsNumber: apartment!.layout!.roomCount!,
          layout: layoutObjects[layoutId],
          direction: apartment!.direction!,
          providerId: apartment!.providerId,
          apartmentNumber: apartment!.apartmentNumber,
          isAvailable: apartment!.isVacant,
          modelId: generateModelId(apartment),

          // associate each apartment with its building to enable filtering by building
          building: buildingObject,

          floor: apartment!.floor!,

          // TODO: consider moving these inside layout field
          layoutImage: {
            url: apartment!.layout!.layoutImage!.asset!.url!,
            altText: apartment!.layout!.layoutImage!.asset!.altText!,
          },
          unfurnishedImage: {
            url: apartment!.layout!.unfurnishedImage!.asset!.url! || apartment!.layout!.furnishedImage!.asset!.url!,
            altText: apartment!.layout!.unfurnishedImage!.asset!.altText!,
          },
          balconyViews: {
            url: apartment!.layout!.balconyViews!.asset!.url!,
            altText: apartment!.layout!.balconyViews!.asset!.altText!,
          },
          floorView: {
            url: apartment!.layout!.floorView!.asset!.url!,
            altText: apartment!.layout!.floorView!.asset!.altText!,
          },
        };
      });

      return acc.concat(filterableApartments as unknown as ApartmentSimple);
    }

    return acc;
  }, []);
}

/*
 * filters the apartment that matches selectedParams and builds selection options for each field along the way
 * */
function filterData<Apartment extends ApartmentSimple>(
  apartments: Apartment[],
  selectedParams: SelectionParams<Partial<Apartment>> // TODO: not sure about the usage of Partial here
): { options: SelectionOptions<Apartment>; matchedApartments: Apartment[] } {
  const options = {} as SelectionOptions<Apartment>;

  let matchedApartments: Apartment[] | null = null;

  for (let fieldName of filterFieldsInSelectionOrder as (keyof Apartment)[]) {
    // build selection options for the field from apartments filtered so far
    const allFieldValues = (matchedApartments || apartments).map((apartment) => apartment[fieldName]);
    options[fieldName] = Array.from(new Set(allFieldValues));

    if (selectedParams[fieldName] !== null) {
      // further filter apartments by the field value'
      matchedApartments = (matchedApartments || apartments)
        .filter((apartment) => {
          if (fieldName === "price") {
            return !selectedParams["price"]
              ? true
              : selectedParams["price"][1] >= apartment.price && selectedParams["price"][0] <= apartment.price;
          }

          return selectedParams[fieldName] === apartment[fieldName];
        })
        .filter((a) => a.isAvailable);
    }

    if (!matchedApartments) {
      matchedApartments = [];
    }
  }

  return { options, matchedApartments: matchedApartments as unknown as Apartment[] };
}
