const values = {
  xs: 0,
  sm: 768,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const aptWizTheme = {
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        // prevents useMediaQuery from always returning false the first time it runs
        // see https://mui.com/material-ui/react-use-media-query/#client-side-only-rendering
        noSsr: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontWeight: "bold",
          boxShadow: "0 15px 30px 0 rgba(0, 0, 0, 0.12)",
          textTransform: "Capitalize",
          "&:hover": { boxShadow: "0 15px 30px 0 rgba(0, 0, 0, 0.12)" },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#f0f0f0",
          border: "none",
          position: "relative",
          padding: "0.875rem 1.625rem",
          fontStretch: "condensed",
          fontWeight: 600,
          color: "#000",
          lineHeight: 1,
          letterSpacing: "0.26px",
          fontSize: "14px",
          textTransform: "capitalize",
          [`@media (max-width:${values.sm}px)`]: {
            padding: "0.375rem 0.875rem",
            fontSize: "11px",
          },
          "&:not(:first-of-type):after": {
            position: "absolute",
            top: "50%",
            left: "0",
            transform: "translateY(-50%)",
            width: "1px",
            height: "20px",
            backgroundColor: "#000",
            opacity: "0.1",
            content: "''",
          },
          "&:hover": {
            backgroundColor: "#e5e5e5",
            "&:after, & + .MuiToggleButton-root:after": {
              content: "unset",
            },
          },
          "&.Mui-selected": {
            backgroundColor: "#fff",
            boxShadow: "2px 0 10px 2px rgba(0, 0, 0, 0.08), -2px 0 10px 2px rgba(0, 0, 0, 0.08)",
            "&:after, & + .MuiToggleButton-root:after": {
              content: "unset",
            },
            "&:hover": {
              backgroundColor: "#fff",
            },
            zIndex: 1,
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: 38,
      letterSpacing: 0.3,
      fontWeight: "bold",
      lineHeight: 1,
      [`@media (max-width:${values.sm}px)`]: {
        fontSize: 30,
        lineHeight: 1.27,
        letterSpacing: 0.24,
      },
    },
    h6: {
      fontSize: 15,
      letterSpacing: 0.28,
      fontWeight: "bold",
      lineHeight: 1,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: 1,
      letterSpacing: 0.3,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: "0.26px",
    },
    fontWeightMedium: 600,
  },
  palette: {
    background: {
      default: "#ebebeb",
      paper: "#fff",
    },
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#fff",
    },
    info: {
      main: "#f7b81a",
      dark: "#eaab0d",
      light: "#f7b81a",
    },
    error: {
      main: "#ff0000",
      light: "#ff0000",
      dark: "#ff0000",
    },
    action: {
      selected: "#f4e5dd",
    },
    grey: {
      100: "#f2f2f2",
      200: "#f0f0f0",
      300: "#ebebeb",
      400: "#e5e5e5",
      500: "#9b9b9b",
      600: "#858484",
      700: "#666666"
    },
  },
  shape: {
    borderRadius: 6,
  },
  breakpoints: {
    values,
  },
  spacing: 16,
  direction: "ltr",
};
