export const aptWizthemeHeFontStyle = {
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    h1: {
      fontStretch: "condensed",
    },
    h6: {
      fontStretch: "condensed",
    },
    subtitle1: {
      fontStretch: "condensed",
    },
    body1: {
      fontWeight: 600,
      fontStretch: "condensed",
    },
    body2: {
      fontWeight: 600,
      fontStretch: "condensed",
    },
  },
};
