import { FC, useRef, useEffect, useMemo, useState, useCallback } from "react";
import { Box, Button } from "@mui/material";
import {
  ArrowForwardRounded,
  ArrowBackRounded,
  AddRounded,
  RemoveRounded,
  // RemoveRedEyeRounded
} from "@mui/icons-material";

import Viewer, { ViewerType } from "./viewer";
import { Image, File } from "../../../generated/graphql";
import { useWizardContext } from "../../../context/WizardContext";

import "./index.scss";
import { ApartmentSimple } from "../../../screens/ApartmentSelection/ApartmentSelection";

interface Props {
  images?: Array<Image | null>;
  overlayImages?: Array<File | null>;
  highlightIds?: string[];
  matchedApartments?: ApartmentSimple[];
  showApartmentDialog: (apartment: ApartmentSimple | null, element?: Element) => void;
}

const ModelViewer: FC<Props> = ({
  images = [],
  overlayImages,
  highlightIds = [],
  matchedApartments = [],
  showApartmentDialog,
}) => {
  const { locale } = useWizardContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [viewer, setViewer] = useState<ViewerType | null>(null);
  const [canRotate, setCanRotate] = useState<boolean>(true);
  // const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const buttons = [
    {
      key: "3d-model-rotate-right-btn",
      func: () => rotate("left"), // this is backwards because the model is backwards
      content: <ArrowForwardRounded />,
    },
    {
      key: "3d-model-rotate-left-btn",
      func: () => rotate("right"), // this is backwards because the model is backwards
      content: <ArrowBackRounded />,
    },
    {
      key: "3d-model-zoom-in-btn",
      func: () => zoom("in"),
      content: <AddRounded />,
    },
    {
      key: "3d-model-zoom-out-btn",
      func: () => zoom("out"),
      content: <RemoveRounded />,
    },
    // {
    //     key: '3d-model-show-grid',
    //     func: () => show(),
    //     content: <RemoveRedEyeRounded />
    // }
  ];

  const imageList = useMemo(() => {
    return images?.map((i) => i?.asset?.url || "") || [];
  }, [images]);

  const svgList = useMemo(() => {
    return overlayImages?.map((i) => i?.asset?.url || "") || [];
  }, [overlayImages]);

  useEffect(() => {
    const current = containerRef.current;

    if (imageList && svgList) {
      setViewer(new Viewer(containerRef.current, imageList, svgList));
    }

    return () => {
      current && (current.innerHTML = "");
    };
  }, [imageList, svgList]);

  const handleShowApartmentDialog = useCallback(
    (event: MouseEvent, id: string | null) => {
      if (!id) {
        showApartmentDialog(null);
      }
      const apartment = matchedApartments.find((apt) => apt.modelId === id);
      if (!apartment || !event.target) {
        return;
      }

      showApartmentDialog(apartment, event.target as Element);
    },
    [matchedApartments, showApartmentDialog]
  );

  useEffect(() => {
    viewer?.clearAllHighlights();
    viewer?.highlightItems(highlightIds, handleShowApartmentDialog);
  }, [highlightIds, viewer, handleShowApartmentDialog]);

  const rotate = (direction: "right" | "left") => {
    viewer?.clearAllHighlights();
    if (canRotate) {
      setCanRotate(false);
      viewer?.rotate(direction).then(() => {
        viewer?.highlightItems(highlightIds, handleShowApartmentDialog);
        setCanRotate(true);
      });
    }
  };

  const zoom = (direction: "in" | "out") => {
    return viewer?.zoomInOut(direction);
  };

  // const show = () => {
  //     viewer?.toggleOverlay(!showOverlay);
  //     setShowOverlay(prev => !prev);
  // }

  return (
    <>
      <Box className={`main-container`} ref={containerRef}>
        <div className={`container-btn-rotation-and-zoom ${locale}`}>
          {buttons.map((button) => (
            <Button
              key={button.key}
              variant="contained"
              color="secondary"
              onClick={button.func}
              className={"model-btn"}
            >
              {button.content}
            </Button>
          ))}
        </div>
      </Box>
    </>
  );
};

export default ModelViewer;
