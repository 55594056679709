import { FC, useEffect, useState } from "react";
import { useWizardContext, WIZARD_STEP } from "../../context/WizardContext";
import { Button, ButtonProps } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import useMedia from "../../context/hooks/useMedia";

interface GroupedButtonsProps extends ButtonProps {
  onNextStep?: () => void;
  nextDisabled?: boolean;
}

const BASE_ROUTE = process.env.REACT_APP_BASE_ROUTE || '';

const GroupedButtons: FC<GroupedButtonsProps> = ({ onNextStep, nextDisabled }) => {
  const navigate = useNavigate();
  const { isMobile } = useMedia();
  const { steps, activeStep, locale, appTexts, project } = useWizardContext();
  const { stepSidePanel } = appTexts || {};
  const { SUMMARY, ABOUT } = WIZARD_STEP;
  const [isDisable, setIsDisable] = useState(true);
  const buttonTextColor = project?.buttonTextColor?.hex;

  useEffect(() => {
    setIsDisable(!!nextDisabled);
  }, [nextDisabled]);

  const handleNext = () => {
    onNextStep?.();
    if (activeStep === steps[steps.length - 1]) return;
    setIsDisable(true);
    const activeIndex = steps.indexOf(activeStep);
    const nextStep = steps[activeIndex + 1];
    navigate(`/${BASE_ROUTE}/${nextStep}`);
  };

  const handleBack = () => {
    const activeIndex = steps.indexOf(activeStep);
    const prevStep = steps[activeIndex - 1];
    navigate(`/${BASE_ROUTE}/${prevStep}`);
  };

  const handleTextContent = (activeStep: WIZARD_STEP) => {
    const textContentMap: Partial<Record<WIZARD_STEP, string>> = {
      [SUMMARY]: stepSidePanel?.send?.[locale],
      [ABOUT]: stepSidePanel?.apartment?.[locale],
    };

    return textContentMap[activeStep] || stepSidePanel?.next?.[locale];
  };

  return (
    <Box
      sx={{
        py: !isMobile ? 1.25 : 1.125,
        display: "flex",
        alignItems: "center",
        gap: 1,
        justifyContent: "space-around",
        borderRadius: "6px",
        backgroundColor: "background.paper",
        px: !isMobile ? 2.25 : 1.5,
        order: isMobile ? 3 : "unset",
      }}
    >
      {activeStep !== WIZARD_STEP.ABOUT && (
        <Button
          onClick={handleBack}
          color="inherit"
          size="large"
          variant="text"
          tabIndex={-1}
          sx={{
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "grey.100",
            },
          }}
          fullWidth
        >
          {stepSidePanel?.prev?.[locale]}
        </Button>
      )}
      <Button
        onClick={handleNext}
        disabled={isDisable}
        color="info"
        size="large"
        variant="contained"
        fullWidth
        sx={{
          py: 0.875,
          px: 0,
          lineHeight: 1.07,
          fontSize: "fontSize",
          color: buttonTextColor || "common.black",
          "&:hover": {
            backgroundColor: "info.dark",
          },
          "&.Mui-disabled": {
            backgroundColor: "info.main",
            color: buttonTextColor || "common.black",
            opacity: 0.3,
          },
        }}
      >
        {handleTextContent(activeStep)}
      </Button>
    </Box>
  );
};

export default GroupedButtons;
