import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ProjectSection } from "../../generated/graphql";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useWizardContext } from "../../context/WizardContext";

const Accordion = (props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />;

interface CustomizedAccordionsProps {
  sections: ProjectSection[];
  onSelectedSectionChange: (index: number) => void;
  onSelectedItemChange: (index: number | null) => void;
  selectedSectionIndex: number | null;
  selectedItemIndex: number | null;
}

const CustomizedAccordions: React.FC<CustomizedAccordionsProps> = ({
  sections,
  onSelectedSectionChange,
  onSelectedItemChange,
  selectedItemIndex,
  selectedSectionIndex,
}) => {
  const [expandedPanels, setExpandedPanels] = useState(new Set(["panel0"]));
  const { locale } = useWizardContext();

  const handleChangeExpandedPanels =
    (panel: string, section: ProjectSection, sectionIndex: number) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded) {
        onSelectedItemChange(null);
        onSelectedSectionChange(sectionIndex);
      }
      if (section.items) {
        const newExpandedPanels = new Set(expandedPanels);
        isExpanded ? newExpandedPanels.add(panel) : newExpandedPanels.delete(panel);
        setExpandedPanels(newExpandedPanels);
      }
    };

  const handleSetActiveItem = (sectionIndex: number, itemIndex: number) => {
    onSelectedSectionChange(sectionIndex);
    onSelectedItemChange(itemIndex);
  };

  const scrollToElement = (e: React.TransitionEvent<HTMLDivElement>) => {
    (e.target as HTMLDivElement).scrollIntoView({ block: "nearest", behavior: "smooth" });
  };

  return (
    <>
      {sections?.map((section, sectionIndex) => {
        const panelId = `panel${sectionIndex}`;
        return (
          <React.Fragment key={panelId}>
            <Accordion
              expanded={expandedPanels.has(panelId)}
              onChange={handleChangeExpandedPanels(panelId, section, sectionIndex)}
              onTransitionEnd={scrollToElement}
              sx={{
                "&:before": {
                  content: "unset",
                },
              }}
            >
              <AccordionSummary
                sx={{
                  p: 0,
                  "& .MuiSvgIcon-root": {
                    color: "common.black",
                    display: !section.items?.length ? "none" : "block",
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography fontWeight="fontWeightBold" fontSize="13px" sx={{ py: 0.75 }} variant="subtitle2">
                  {section?.title?.[locale]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {section.items?.map((item, index) => {
                  const isActive = selectedSectionIndex === sectionIndex && selectedItemIndex === index;
                  return (
                    <Box
                      component="button"
                      key={index.toString()}
                      onClick={() => item && handleSetActiveItem(sectionIndex, index)}
                      sx={{
                        my: 0.75,
                        mx: 0,
                        borderRadius: "6px",
                        height: "48px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: isActive ? "background.paper" : "grey.100",
                        border: isActive ? "solid 1px #000" : "none",
                        color: "primary.main",
                        ":first-of-type": { mt: 0 },
                        ":last-of-type": { mb: 1.5 },
                        "&:hover": {
                          cursor: "pointer",
                          backgroundColor: !isActive ? "grey.200" : "background.paper",
                        },
                      }}
                    >
                      <Typography sx={{ pl: 1 }} fontWeight={600} lineHeight={1} variant="caption">
                        {item?.name?.[locale]}
                      </Typography>
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            {sectionIndex < sections.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default CustomizedAccordions;
