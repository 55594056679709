import { FC } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Maybe, Room } from "../../generated/graphql";
import { LOCALE } from "../../context/WizardContext";

interface RoomsButtonsGroupProps {
  isMobileLandscape?: boolean;
  rooms: Maybe<Room>[];
  locale: LOCALE;
  openModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const RoomsButtonsGroup: FC<RoomsButtonsGroupProps> = ({ isMobileLandscape = false, rooms, locale, openModal }) => {
  const renderButton = (room: Maybe<Room>) => (
    <Button
      name={room?.type?.name?.[locale] || ""}
      onClick={(e) => openModal(e)}
      variant="outlined"
      sx={{
        fontSize: "fontSize",
        fontWeight: "fontWeightMedium",
        fontStretch: "condensed",
        width: "100%",
        height: "100%",
        aspectRatio: "154 / 123",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "none",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: isMobileLandscape ? "inherit" : "info.main",
          border: "none",
        },
        "&.MuiButton-root": {
          border: "none",
          backgroundColor: isMobileLandscape ? "white" : "grey.100",
          "&:active": {
            backgroundColor: "info.main",
          },
        },
      }}
    >
      <Box
        component="img"
        sx={{ mb: 1, width: "40px" }}
        src={room?.type?.icon?.asset?.url || ""}
        alt={room?.type?.icon?.asset?.label || ""}
      />
      {room?.type?.name?.[locale]}
    </Button>
  );

  const mobileContainer = () => (
    <Box
      component="div"
      sx={{
        background: "transparent",
        width: "fit-content",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          // gap: 1, // Using margin instead of gap because of safari not supporting it
        }}
      >
        {rooms.map((room) => (
          <Box
            component="div"
            key={room?._id}
            sx={{
              width: "184px",
              height: "156px",
              backgroundColor: "white",
              borderRadius: "6px",
              marginRight: 1, // Using margin instead of gap because of safari not supporting it
            }}
          >
            {renderButton(room)}
          </Box>
        ))}
      </Box>
    </Box>
  );

  const desktopContainer = () => (
    <Box
      component="div"
      sx={{
        marginTop: 2,
      }}
    >
      <Grid container columns={2} spacing={1}>
        {rooms.map((room) => (
          <Grid item xs={1} key={room?._id}>
            {renderButton(room)}
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return isMobileLandscape ? mobileContainer() : desktopContainer();
};
export default RoomsButtonsGroup;
