import { FC } from "react";
import { Box } from '@mui/material';
import {SxProps, Theme, useTheme} from "@mui/system";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";

import {LocaleImage} from "../../generated/graphql";
import ResponsiveMainImage from "../ResponsiveMainImage/ResponsiveMainImage";
import {useWizardContext} from "../../context/WizardContext";

import 'swiper/swiper-bundle.min.css'


interface Props {
    images?: LocaleImage[];
    sx?: SxProps<Theme>;
}

const ImagesCarousel: FC<Props> = ({ images, sx}) => {
    const { locale } = useWizardContext();
    const theme = useTheme();
    return (
        <Box
            width="100%"
            height="100%"
            sx={{
                '.swiper': {
                    width: '100%',
                    height: '100%',
                    zIndex: 7,
                    '--swiper-theme-color': theme.palette.grey[700]
                }
            }}
        >
            {images ?
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    dir={'rtl'}
                >
                    {images.map((image, i) =>
                        <SwiperSlide key={`${image[locale]?.asset?._id}_${i}`}>
                            <ResponsiveMainImage src={image[locale]?.asset?.url || ''} sx={sx}/>
                        </SwiperSlide>
                    )}
                </Swiper>
                : null}
        </Box>
    )
}

export default ImagesCarousel;
