import { FC } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { SxProps, Theme } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMedia from "../../context/hooks/useMedia";
import { useTheme } from "@mui/system";

const contentPaddingY = "1.25rem";
const contentPaddingX = "1.5rem";

interface InfoDialogProps {
  isOpen: boolean;
  container?: DialogProps["container"];
  onClose: DialogProps["onClose"];
  hideBackdrop?: DialogProps["hideBackdrop"];
  sx?: SxProps<Theme>;
}

const InfoDialog: FC<InfoDialogProps> = ({ isOpen, onClose, hideBackdrop = false, container, children, sx = [] }) => {
  const theme = useTheme();

  const { isMobile, isLandscape } = useMedia();

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      onClose={onClose}
      container={container || document.getElementById("root")}
      hideBackdrop={hideBackdrop}
      sx={[
        {
          "--info-dialog-content-padding-x": contentPaddingX,
          "--info-dialog-content-padding-y": contentPaddingY,
          "& .MuiPaper-root": {
            height: `calc(100% - ${theme.spacing(!isMobile && !isLandscape ? 4 : 3)})`,
            width: "100%",
            maxWidth: "100%",
            margin: !isMobile && !isLandscape ? 2 : `${theme.spacing(1.5)} ${theme.spacing(3)}`,
            borderRadius: !isMobile && !isLandscape ? 1 : "12px",
            maxHeight: `calc(100% - ${theme.spacing(!isMobile && !isLandscape ? 4 : 3)})`,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "background.default",
            opacity: "0.2 !important",
          },
          "& .MuiDialogContent-root": {
            py: contentPaddingY,
            px: contentPaddingX,
          },
          "&.MuiModal-root": {
            zIndex: isMobile && !isLandscape ? -1 : 1300,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <IconButton
        aria-label="close"
        sx={(theme) => ({
          "&.MuiIconButton-root": {
            position: "absolute",
            right: theme.spacing(isMobile ? 0.5 : 1),
            top: theme.spacing(isMobile ? 0.5 : 1),
          },
          zIndex: 10,
        })}
        onClick={(e) => {
          onClose?.(e, "escapeKeyDown");
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default InfoDialog;
