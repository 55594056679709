import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import { Box, SxProps, Theme } from "@mui/system";
import { ProjectSection } from "../../generated/graphql";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useWizardContext } from "../../context/WizardContext";
import SectionItemsList from "../SectionItemsList/SectionItemsList";

interface SectionsProps {
  sections: ProjectSection[];
  onSelectedSectionChange: (index: number) => void;
  onSelectedItemChange: (index: number | null) => void;
  selectedSectionIndex: number | null;
  selectedItemIndex: number | null;
  sx?: SxProps<Theme>;
}

const Sections: React.FC<SectionsProps> = ({
  sections,
  onSelectedSectionChange,
  onSelectedItemChange,
  selectedSectionIndex,
  selectedItemIndex,
  sx = [],
}) => {
  const [showSectionItemsList, setShowSectionItemsList] = useState(false);
  const [activeSectionPosition, setActiveSectionPosition] = useState<number>(0);

  const { locale } = useWizardContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const containerChildBoxRef = useRef<HTMLDivElement>(null);
  const sectionButton = useRef<HTMLButtonElement>(null);

  function calculateActiveSectionPosition() {
    const containerPosition = containerRef?.current?.getBoundingClientRect().y;
    const containerChildPosition = containerChildBoxRef?.current?.getBoundingClientRect().y;
    if (!containerPosition || !containerChildPosition) return 0;
    return containerPosition - containerChildPosition;
  }

  const handleSelectSection = (section: ProjectSection, index: number) => {
    if (!section) return;
    setShowSectionItemsList((prevState) => !prevState);
    onSelectedSectionChange(index);
    const activePosition = calculateActiveSectionPosition();
    setActiveSectionPosition(activePosition);
  };

  const sectionButtonHeight = sectionButton?.current?.getBoundingClientRect()?.height;

  return (
    <Box
      ref={containerRef}
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          overflowY: !showSectionItemsList ? "auto" : "hidden",
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box sx={{ borderRadius: "6px" }} ref={containerChildBoxRef}>
        {sections?.map((section, index) => {
          if (!section) return null;
          const isActive = index === selectedSectionIndex && showSectionItemsList;
          return (
            <React.Fragment key={`section${index}`}>
              <Button
                variant="contained"
                endIcon={<ExpandMore />}
                onClick={() => handleSelectSection(section, index)}
                ref={sectionButton}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  p: 0.875,
                  mb: `${buttonGap}px`,
                  fontStretch: "condensed",
                  lineHeight: 1,
                  letterSpacing: "0.26",
                  fontSize: "fontSize",
                  fontWeight: "fontWeightBold",
                  color: "primary.main",
                  boxShadow: "none",
                  backgroundColor: isActive ? "info.dark" : "common.white",
                  visibility: showSectionItemsList ? "hidden" : "visible",
                  transition: "all 1s",

                  ":last-of-type": {
                    m: 0,
                  },
                  ":hover": {
                    boxShadow: "none",
                    backgroundColor: isActive ? "info.dark" : "common.white",
                  },
                }}
              >
                {section.title?.[locale]}
              </Button>
              <SectionItemsList
                section={section}
                isOpen={showSectionItemsList}
                onToggleSelection={() => setShowSectionItemsList((prevState) => !prevState)}
                onChange={onSelectedItemChange}
                isActive={selectedSectionIndex === index}
                selectedItemIndex={selectedItemIndex}
                sx={{
                  top: sectionButtonHeight ? `${index * (buttonGap + sectionButtonHeight)}px` : 0,
                }}
                activePosition={activeSectionPosition}
              />
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default Sections;

const buttonGap = 20;
