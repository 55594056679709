import { ApolloClient, InMemoryCache } from "@apollo/client";

const { REACT_APP_SANITY_URL, REACT_APP_SANITY_API_TOKEN } = process.env;
const client = new ApolloClient({
  uri: REACT_APP_SANITY_URL,
  cache: new InMemoryCache(),
  headers: {
    Authorization: "Bearer " + REACT_APP_SANITY_API_TOKEN,
  },
});

export default client;
