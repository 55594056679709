import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Address = {
  __typename?: "Address";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  city?: Maybe<LocaleString>;
  number?: Maybe<Scalars["Float"]>;
  street?: Maybe<LocaleString>;
};

export type AddressFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  city?: InputMaybe<LocaleStringFilter>;
  number?: InputMaybe<FloatFilter>;
  street?: InputMaybe<LocaleStringFilter>;
};

export type AddressSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  city?: InputMaybe<LocaleStringSorting>;
  number?: InputMaybe<SortOrder>;
  street?: InputMaybe<LocaleStringSorting>;
};

export type Apartment = Document & {
  __typename?: "Apartment";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  apartmentNumber?: Maybe<Scalars["String"]>;
  basePrice?: Maybe<LocaleNumber>;
  direction?: Maybe<Scalars["String"]>;
  floor?: Maybe<Scalars["Float"]>;
  isVacant?: Maybe<Scalars["Boolean"]>;
  layout?: Maybe<ApartmentLayout>;
  name?: Maybe<Scalars["String"]>;
  /** The ID for the apartment in the original provider DB */
  providerId?: Maybe<Scalars["String"]>;
};

export type ApartmentFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  apartmentNumber?: InputMaybe<StringFilter>;
  basePrice?: InputMaybe<LocaleNumberFilter>;
  direction?: InputMaybe<StringFilter>;
  floor?: InputMaybe<FloatFilter>;
  isVacant?: InputMaybe<BooleanFilter>;
  layout?: InputMaybe<ApartmentLayoutFilter>;
  name?: InputMaybe<StringFilter>;
  providerId?: InputMaybe<StringFilter>;
};

export type ApartmentLayout = Document & {
  __typename?: "ApartmentLayout";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  balconyViews?: Maybe<Image>;
  floorView?: Maybe<Image>;
  furnishedImage?: Maybe<Image>;
  icon?: Maybe<Image>;
  layoutImage?: Maybe<Image>;
  /** Short description of the layout type - e.g. Layout B1 */
  name?: Maybe<Scalars["String"]>;
  roomCount?: Maybe<Scalars["Float"]>;
  rooms?: Maybe<Array<Maybe<Room>>>;
  size?: Maybe<Scalars["Float"]>;
  unfurnishedImage?: Maybe<Image>;
  whiteAppliancesImage?: Maybe<Image>;
};

export type ApartmentLayoutFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  balconyViews?: InputMaybe<ImageFilter>;
  floorView?: InputMaybe<ImageFilter>;
  furnishedImage?: InputMaybe<ImageFilter>;
  icon?: InputMaybe<ImageFilter>;
  layoutImage?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
  roomCount?: InputMaybe<FloatFilter>;
  size?: InputMaybe<FloatFilter>;
  unfurnishedImage?: InputMaybe<ImageFilter>;
  whiteAppliancesImage?: InputMaybe<ImageFilter>;
};

export type ApartmentLayoutSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  balconyViews?: InputMaybe<ImageSorting>;
  floorView?: InputMaybe<ImageSorting>;
  furnishedImage?: InputMaybe<ImageSorting>;
  icon?: InputMaybe<ImageSorting>;
  layoutImage?: InputMaybe<ImageSorting>;
  name?: InputMaybe<SortOrder>;
  roomCount?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  unfurnishedImage?: InputMaybe<ImageSorting>;
  whiteAppliancesImage?: InputMaybe<ImageSorting>;
};

export type ApartmentSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  apartmentNumber?: InputMaybe<SortOrder>;
  basePrice?: InputMaybe<LocaleNumberSorting>;
  direction?: InputMaybe<SortOrder>;
  floor?: InputMaybe<SortOrder>;
  isVacant?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  providerId?: InputMaybe<SortOrder>;
};

export type ApplicationText = Document & {
  __typename?: "ApplicationText";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<Image>;
  items?: Maybe<Array<Maybe<TextItem>>>;
  name?: Maybe<Scalars["String"]>;
};

export type ApplicationTextFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ApplicationTextSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  name?: InputMaybe<SortOrder>;
};

export type Background = {
  __typename?: "Background";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  defaultImage?: Maybe<LocaleImage>;
  layoutBgColor?: Maybe<Color>;
  useBgImage?: Maybe<Scalars["Boolean"]>;
};

export type BackgroundFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  defaultImage?: InputMaybe<LocaleImageFilter>;
  layoutBgColor?: InputMaybe<ColorFilter>;
  useBgImage?: InputMaybe<BooleanFilter>;
};

export type BackgroundSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  defaultImage?: InputMaybe<LocaleImageSorting>;
  layoutBgColor?: InputMaybe<ColorSorting>;
  useBgImage?: InputMaybe<SortOrder>;
};

export type Block = {
  __typename?: "Block";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  children?: Maybe<Array<Maybe<Span>>>;
  list?: Maybe<Scalars["String"]>;
  style?: Maybe<Scalars["String"]>;
};

export type BooleanFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars["Boolean"]>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars["Boolean"]>;
};

export type Building = Document & {
  __typename?: "Building";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  apartments?: Maybe<Array<Maybe<Apartment>>>;
  floors?: Maybe<Scalars["Float"]>;
  name?: Maybe<LocaleString>;
};

export type BuildingFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  floors?: InputMaybe<FloatFilter>;
  name?: InputMaybe<LocaleStringFilter>;
};

export type BuildingSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  floors?: InputMaybe<SortOrder>;
  name?: InputMaybe<LocaleStringSorting>;
};

export type Color = {
  __typename?: "Color";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  alpha?: Maybe<Scalars["Float"]>;
  hex?: Maybe<Scalars["String"]>;
  hsl?: Maybe<HslaColor>;
  hsv?: Maybe<HsvaColor>;
  rgb?: Maybe<RgbaColor>;
};

export type ColorFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  alpha?: InputMaybe<FloatFilter>;
  hex?: InputMaybe<StringFilter>;
  hsl?: InputMaybe<HslaColorFilter>;
  hsv?: InputMaybe<HsvaColorFilter>;
  rgb?: InputMaybe<RgbaColorFilter>;
};

export type ColorSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  alpha?: InputMaybe<SortOrder>;
  hex?: InputMaybe<SortOrder>;
  hsl?: InputMaybe<HslaColorSorting>;
  hsv?: InputMaybe<HsvaColorSorting>;
  rgb?: InputMaybe<RgbaColorSorting>;
};

export type DateFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars["Date"]>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars["Date"]>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars["Date"]>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars["Date"]>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars["Date"]>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars["Date"]>;
};

export type DatetimeFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars["DateTime"]>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars["DateTime"]>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars["DateTime"]>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars["DateTime"]>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars["DateTime"]>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars["DateTime"]>;
};

/** A Sanity document */
export type Document = {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type DocumentFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
};

export type DocumentSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
};

export type Element = Document & {
  __typename?: "Element";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<LocaleString>;
  options?: Maybe<Array<Maybe<ElementOption>>>;
};

export type ElementFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  name?: InputMaybe<LocaleStringFilter>;
};

export type ElementOption = Document & {
  __typename?: "ElementOption";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<LocaleText>;
  icon?: Maybe<Image>;
  image?: Maybe<Image>;
  name?: Maybe<LocaleString>;
  price?: Maybe<Scalars["Float"]>;
};

export type ElementOptionFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<LocaleTextFilter>;
  icon?: InputMaybe<ImageFilter>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<LocaleStringFilter>;
  price?: InputMaybe<FloatFilter>;
};

export type ElementOptionSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<LocaleTextSorting>;
  icon?: InputMaybe<ImageSorting>;
  image?: InputMaybe<ImageSorting>;
  name?: InputMaybe<LocaleStringSorting>;
  price?: InputMaybe<SortOrder>;
};

export type ElementSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<LocaleStringSorting>;
};

export type File = {
  __typename?: "File";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  asset?: Maybe<SanityFileAsset>;
};

export type FileFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  asset?: InputMaybe<SanityFileAssetFilter>;
};

export type FileSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
};

export type FloatFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars["Float"]>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars["Float"]>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars["Float"]>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars["Float"]>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars["Float"]>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars["Float"]>;
};

export type Gallery = {
  __typename?: "Gallery";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  cards?: Maybe<Array<Maybe<ImageCard>>>;
  description?: Maybe<LocaleText>;
  image?: Maybe<Image>;
  title?: Maybe<LocaleString>;
};

export type GalleryFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  description?: InputMaybe<LocaleTextFilter>;
  image?: InputMaybe<ImageFilter>;
  title?: InputMaybe<LocaleStringFilter>;
};

export type GallerySorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  description?: InputMaybe<LocaleTextSorting>;
  image?: InputMaybe<ImageSorting>;
  title?: InputMaybe<LocaleStringSorting>;
};

export type Geopoint = {
  __typename?: "Geopoint";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  alt?: Maybe<Scalars["Float"]>;
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

export type GeopointFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  alt?: InputMaybe<FloatFilter>;
  lat?: InputMaybe<FloatFilter>;
  lng?: InputMaybe<FloatFilter>;
};

export type GeopointSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  alt?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  lng?: InputMaybe<SortOrder>;
};

export type HighlightModels = {
  __typename?: "HighlightModels";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  occupiedModel?: Maybe<File>;
  vacantModel?: Maybe<File>;
};

export type HighlightModelsFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  occupiedModel?: InputMaybe<FileFilter>;
  vacantModel?: InputMaybe<FileFilter>;
};

export type HighlightModelsSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  occupiedModel?: InputMaybe<FileSorting>;
  vacantModel?: InputMaybe<FileSorting>;
};

export type HslaColor = {
  __typename?: "HslaColor";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  a?: Maybe<Scalars["Float"]>;
  h?: Maybe<Scalars["Float"]>;
  l?: Maybe<Scalars["Float"]>;
  s?: Maybe<Scalars["Float"]>;
};

export type HslaColorFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  a?: InputMaybe<FloatFilter>;
  h?: InputMaybe<FloatFilter>;
  l?: InputMaybe<FloatFilter>;
  s?: InputMaybe<FloatFilter>;
};

export type HslaColorSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  a?: InputMaybe<SortOrder>;
  h?: InputMaybe<SortOrder>;
  l?: InputMaybe<SortOrder>;
  s?: InputMaybe<SortOrder>;
};

export type HsvaColor = {
  __typename?: "HsvaColor";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  a?: Maybe<Scalars["Float"]>;
  h?: Maybe<Scalars["Float"]>;
  s?: Maybe<Scalars["Float"]>;
  v?: Maybe<Scalars["Float"]>;
};

export type HsvaColorFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  a?: InputMaybe<FloatFilter>;
  h?: InputMaybe<FloatFilter>;
  s?: InputMaybe<FloatFilter>;
  v?: InputMaybe<FloatFilter>;
};

export type HsvaColorSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  a?: InputMaybe<SortOrder>;
  h?: InputMaybe<SortOrder>;
  s?: InputMaybe<SortOrder>;
  v?: InputMaybe<SortOrder>;
};

export type IdFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars["ID"]>;
  in?: InputMaybe<Array<Scalars["ID"]>>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars["ID"]>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars["ID"]>;
  nin?: InputMaybe<Array<Scalars["ID"]>>;
};

export type Image = {
  __typename?: "Image";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  asset?: Maybe<SanityImageAsset>;
  crop?: Maybe<SanityImageCrop>;
  hotspot?: Maybe<SanityImageHotspot>;
};

export type ImageCard = {
  __typename?: "ImageCard";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  caption?: Maybe<LocaleText>;
  image?: Maybe<Image>;
  label?: Maybe<LocaleString>;
  name?: Maybe<LocaleString>;
};

export type ImageCardFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  caption?: InputMaybe<LocaleTextFilter>;
  image?: InputMaybe<ImageFilter>;
  label?: InputMaybe<LocaleStringFilter>;
  name?: InputMaybe<LocaleStringFilter>;
};

export type ImageCardSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  caption?: InputMaybe<LocaleTextSorting>;
  image?: InputMaybe<ImageSorting>;
  label?: InputMaybe<LocaleStringSorting>;
  name?: InputMaybe<LocaleStringSorting>;
};

export type ImageFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  asset?: InputMaybe<SanityImageAssetFilter>;
  crop?: InputMaybe<SanityImageCropFilter>;
  hotspot?: InputMaybe<SanityImageHotspotFilter>;
};

export type ImageSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  crop?: InputMaybe<SanityImageCropSorting>;
  hotspot?: InputMaybe<SanityImageHotspotSorting>;
};

export type IntFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars["Int"]>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars["Int"]>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars["Int"]>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars["Int"]>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars["Int"]>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars["Int"]>;
};

export type LocaleBlock = {
  __typename?: "LocaleBlock";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  enRaw?: Maybe<Scalars["JSON"]>;
  heRaw?: Maybe<Scalars["JSON"]>;
};

export type LocaleBlockFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
};

export type LocaleBlockSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
};

export type LocaleImage = {
  __typename?: "LocaleImage";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  en?: Maybe<Image>;
  he?: Maybe<Image>;
};

export type LocaleImageFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  en?: InputMaybe<ImageFilter>;
  he?: InputMaybe<ImageFilter>;
};

export type LocaleImageSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  en?: InputMaybe<ImageSorting>;
  he?: InputMaybe<ImageSorting>;
};

export type LocaleNumber = {
  __typename?: "LocaleNumber";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  en?: Maybe<Scalars["Float"]>;
  he?: Maybe<Scalars["Float"]>;
};

export type LocaleNumberFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  en?: InputMaybe<FloatFilter>;
  he?: InputMaybe<FloatFilter>;
};

export type LocaleNumberSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  en?: InputMaybe<SortOrder>;
  he?: InputMaybe<SortOrder>;
};

export type LocaleString = {
  __typename?: "LocaleString";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  en?: Maybe<Scalars["String"]>;
  he?: Maybe<Scalars["String"]>;
};

export type LocaleStringFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  en?: InputMaybe<StringFilter>;
  he?: InputMaybe<StringFilter>;
};

export type LocaleStringSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  en?: InputMaybe<SortOrder>;
  he?: InputMaybe<SortOrder>;
};

export type LocaleText = {
  __typename?: "LocaleText";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  en?: Maybe<Scalars["String"]>;
  he?: Maybe<Scalars["String"]>;
};

export type LocaleTextFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  en?: InputMaybe<StringFilter>;
  he?: InputMaybe<StringFilter>;
};

export type LocaleTextSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  en?: InputMaybe<SortOrder>;
  he?: InputMaybe<SortOrder>;
};

export type Project = Document & {
  __typename?: "Project";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  accentColor?: Maybe<Color>;
  address?: Maybe<Address>;
  building3dImages?: Maybe<Array<Maybe<Image>>>;
  building3dOverlay?: Maybe<Array<Maybe<File>>>;
  buildings?: Maybe<Array<Maybe<Building>>>;
  buttonTextColor?: Maybe<Color>;
  defaultBackground?: Maybe<Background>;
  modelApartments?: Maybe<Array<Maybe<ApartmentLayout>>>;
  name?: Maybe<LocaleString>;
  projectLogo?: Maybe<ProjectLogo>;
  sections?: Maybe<Array<Maybe<ProjectSection>>>;
};

export type ProjectFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  accentColor?: InputMaybe<ColorFilter>;
  address?: InputMaybe<AddressFilter>;
  buttonTextColor?: InputMaybe<ColorFilter>;
  defaultBackground?: InputMaybe<BackgroundFilter>;
  name?: InputMaybe<LocaleStringFilter>;
  projectLogo?: InputMaybe<ProjectLogoFilter>;
};

export type ProjectLogo = {
  __typename?: "ProjectLogo";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  darkThemeLogo?: Maybe<File>;
  lightThemeLogo?: Maybe<File>;
};

export type ProjectLogoFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  darkThemeLogo?: InputMaybe<FileFilter>;
  lightThemeLogo?: InputMaybe<FileFilter>;
};

export type ProjectLogoSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  darkThemeLogo?: InputMaybe<FileSorting>;
  lightThemeLogo?: InputMaybe<FileSorting>;
};

export type ProjectSection = {
  __typename?: "ProjectSection";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  image?: Maybe<LocaleImage>;
  items?: Maybe<Array<Maybe<SectionImage>>>;
  title?: Maybe<LocaleString>;
};

export type ProjectSectionFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  image?: InputMaybe<LocaleImageFilter>;
  title?: InputMaybe<LocaleStringFilter>;
};

export type ProjectSectionSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  image?: InputMaybe<LocaleImageSorting>;
  title?: InputMaybe<LocaleStringSorting>;
};

export type ProjectSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  accentColor?: InputMaybe<ColorSorting>;
  address?: InputMaybe<AddressSorting>;
  buttonTextColor?: InputMaybe<ColorSorting>;
  defaultBackground?: InputMaybe<BackgroundSorting>;
  name?: InputMaybe<LocaleStringSorting>;
  projectLogo?: InputMaybe<ProjectLogoSorting>;
};

export type RgbaColor = {
  __typename?: "RgbaColor";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  a?: Maybe<Scalars["Float"]>;
  b?: Maybe<Scalars["Float"]>;
  g?: Maybe<Scalars["Float"]>;
  r?: Maybe<Scalars["Float"]>;
};

export type RgbaColorFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  a?: InputMaybe<FloatFilter>;
  b?: InputMaybe<FloatFilter>;
  g?: InputMaybe<FloatFilter>;
  r?: InputMaybe<FloatFilter>;
};

export type RgbaColorSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  a?: InputMaybe<SortOrder>;
  b?: InputMaybe<SortOrder>;
  g?: InputMaybe<SortOrder>;
  r?: InputMaybe<SortOrder>;
};

export type Room = Document & {
  __typename?: "Room";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  elements?: Maybe<Array<Maybe<Element>>>;
  furnishedImage?: Maybe<Image>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<RoomType>;
  unfurnishedImage?: Maybe<Image>;
  whiteAppliancesImage?: Maybe<Image>;
};

export type RoomFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  furnishedImage?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<RoomTypeFilter>;
  unfurnishedImage?: InputMaybe<ImageFilter>;
  whiteAppliancesImage?: InputMaybe<ImageFilter>;
};

export type RoomSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  furnishedImage?: InputMaybe<ImageSorting>;
  name?: InputMaybe<SortOrder>;
  unfurnishedImage?: InputMaybe<ImageSorting>;
  whiteAppliancesImage?: InputMaybe<ImageSorting>;
};

export type RoomType = Document & {
  __typename?: "RoomType";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  icon?: Maybe<Image>;
  name?: Maybe<LocaleString>;
};

export type RoomTypeFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  icon?: InputMaybe<ImageFilter>;
  name?: InputMaybe<LocaleStringFilter>;
};

export type RoomTypeSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  icon?: InputMaybe<ImageSorting>;
  name?: InputMaybe<LocaleStringSorting>;
};

export type RootQuery = {
  __typename?: "RootQuery";
  Apartment?: Maybe<Apartment>;
  ApartmentLayout?: Maybe<ApartmentLayout>;
  ApplicationText?: Maybe<ApplicationText>;
  Building?: Maybe<Building>;
  Document?: Maybe<Document>;
  Element?: Maybe<Element>;
  ElementOption?: Maybe<ElementOption>;
  Project?: Maybe<Project>;
  Room?: Maybe<Room>;
  RoomType?: Maybe<RoomType>;
  SanityFileAsset?: Maybe<SanityFileAsset>;
  SanityImageAsset?: Maybe<SanityImageAsset>;
  allApartment: Array<Apartment>;
  allApartmentLayout: Array<ApartmentLayout>;
  allApplicationText: Array<ApplicationText>;
  allBuilding: Array<Building>;
  allDocument: Array<Document>;
  allElement: Array<Element>;
  allElementOption: Array<ElementOption>;
  allProject: Array<Project>;
  allRoom: Array<Room>;
  allRoomType: Array<RoomType>;
  allSanityFileAsset: Array<SanityFileAsset>;
  allSanityImageAsset: Array<SanityImageAsset>;
};

export type RootQueryApartmentArgs = {
  id: Scalars["ID"];
};

export type RootQueryApartmentLayoutArgs = {
  id: Scalars["ID"];
};

export type RootQueryApplicationTextArgs = {
  id: Scalars["ID"];
};

export type RootQueryBuildingArgs = {
  id: Scalars["ID"];
};

export type RootQueryDocumentArgs = {
  id: Scalars["ID"];
};

export type RootQueryElementArgs = {
  id: Scalars["ID"];
};

export type RootQueryElementOptionArgs = {
  id: Scalars["ID"];
};

export type RootQueryProjectArgs = {
  id: Scalars["ID"];
};

export type RootQueryRoomArgs = {
  id: Scalars["ID"];
};

export type RootQueryRoomTypeArgs = {
  id: Scalars["ID"];
};

export type RootQuerySanityFileAssetArgs = {
  id: Scalars["ID"];
};

export type RootQuerySanityImageAssetArgs = {
  id: Scalars["ID"];
};

export type RootQueryAllApartmentArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<ApartmentSorting>>;
  where?: InputMaybe<ApartmentFilter>;
};

export type RootQueryAllApartmentLayoutArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<ApartmentLayoutSorting>>;
  where?: InputMaybe<ApartmentLayoutFilter>;
};

export type RootQueryAllApplicationTextArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<ApplicationTextSorting>>;
  where?: InputMaybe<ApplicationTextFilter>;
};

export type RootQueryAllBuildingArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<BuildingSorting>>;
  where?: InputMaybe<BuildingFilter>;
};

export type RootQueryAllDocumentArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<DocumentSorting>>;
  where?: InputMaybe<DocumentFilter>;
};

export type RootQueryAllElementArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<ElementSorting>>;
  where?: InputMaybe<ElementFilter>;
};

export type RootQueryAllElementOptionArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<ElementOptionSorting>>;
  where?: InputMaybe<ElementOptionFilter>;
};

export type RootQueryAllProjectArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<ProjectSorting>>;
  where?: InputMaybe<ProjectFilter>;
};

export type RootQueryAllRoomArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<RoomSorting>>;
  where?: InputMaybe<RoomFilter>;
};

export type RootQueryAllRoomTypeArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<RoomTypeSorting>>;
  where?: InputMaybe<RoomTypeFilter>;
};

export type RootQueryAllSanityFileAssetArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SanityFileAssetSorting>>;
  where?: InputMaybe<SanityFileAssetFilter>;
};

export type RootQueryAllSanityImageAssetArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<SanityImageAssetSorting>>;
  where?: InputMaybe<SanityImageAssetFilter>;
};

export type SanityAssetSourceData = {
  __typename?: "SanityAssetSourceData";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  /** The unique ID for the asset within the originating source so you can programatically find back to it */
  id?: Maybe<Scalars["String"]>;
  /** A canonical name for the source this asset is originating from */
  name?: Maybe<Scalars["String"]>;
  /** A URL to find more information about this asset in the originating source */
  url?: Maybe<Scalars["String"]>;
};

export type SanityAssetSourceDataFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityAssetSourceDataSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityFileAsset = Document & {
  __typename?: "SanityFileAsset";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  altText?: Maybe<Scalars["String"]>;
  assetId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  extension?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  mimeType?: Maybe<Scalars["String"]>;
  originalFilename?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  sha1hash?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Float"]>;
  source?: Maybe<SanityAssetSourceData>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type SanityFileAssetFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  altText?: InputMaybe<StringFilter>;
  assetId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  extension?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  mimeType?: InputMaybe<StringFilter>;
  originalFilename?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  sha1hash?: InputMaybe<StringFilter>;
  size?: InputMaybe<FloatFilter>;
  source?: InputMaybe<SanityAssetSourceDataFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityFileAssetSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  altText?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  extension?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  originalFilename?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  sha1hash?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  source?: InputMaybe<SanityAssetSourceDataSorting>;
  title?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityImageAsset = Document & {
  __typename?: "SanityImageAsset";
  /** Date the document was created */
  _createdAt?: Maybe<Scalars["DateTime"]>;
  /** Document ID */
  _id?: Maybe<Scalars["ID"]>;
  _key?: Maybe<Scalars["String"]>;
  /** Current document revision */
  _rev?: Maybe<Scalars["String"]>;
  /** Document type */
  _type?: Maybe<Scalars["String"]>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars["DateTime"]>;
  altText?: Maybe<Scalars["String"]>;
  assetId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  extension?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  metadata?: Maybe<SanityImageMetadata>;
  mimeType?: Maybe<Scalars["String"]>;
  originalFilename?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
  sha1hash?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Float"]>;
  source?: Maybe<SanityAssetSourceData>;
  title?: Maybe<Scalars["String"]>;
  uploadId?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type SanityImageAssetFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<Sanity_DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  altText?: InputMaybe<StringFilter>;
  assetId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  extension?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<SanityImageMetadataFilter>;
  mimeType?: InputMaybe<StringFilter>;
  originalFilename?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  sha1hash?: InputMaybe<StringFilter>;
  size?: InputMaybe<FloatFilter>;
  source?: InputMaybe<SanityAssetSourceDataFilter>;
  title?: InputMaybe<StringFilter>;
  uploadId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityImageAssetSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  altText?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  extension?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SanityImageMetadataSorting>;
  mimeType?: InputMaybe<SortOrder>;
  originalFilename?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  sha1hash?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  source?: InputMaybe<SanityAssetSourceDataSorting>;
  title?: InputMaybe<SortOrder>;
  uploadId?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityImageCrop = {
  __typename?: "SanityImageCrop";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  bottom?: Maybe<Scalars["Float"]>;
  left?: Maybe<Scalars["Float"]>;
  right?: Maybe<Scalars["Float"]>;
  top?: Maybe<Scalars["Float"]>;
};

export type SanityImageCropFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  bottom?: InputMaybe<FloatFilter>;
  left?: InputMaybe<FloatFilter>;
  right?: InputMaybe<FloatFilter>;
  top?: InputMaybe<FloatFilter>;
};

export type SanityImageCropSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  bottom?: InputMaybe<SortOrder>;
  left?: InputMaybe<SortOrder>;
  right?: InputMaybe<SortOrder>;
  top?: InputMaybe<SortOrder>;
};

export type SanityImageDimensions = {
  __typename?: "SanityImageDimensions";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  aspectRatio?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

export type SanityImageDimensionsFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  aspectRatio?: InputMaybe<FloatFilter>;
  height?: InputMaybe<FloatFilter>;
  width?: InputMaybe<FloatFilter>;
};

export type SanityImageDimensionsSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  aspectRatio?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type SanityImageHotspot = {
  __typename?: "SanityImageHotspot";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
  x?: Maybe<Scalars["Float"]>;
  y?: Maybe<Scalars["Float"]>;
};

export type SanityImageHotspotFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  height?: InputMaybe<FloatFilter>;
  width?: InputMaybe<FloatFilter>;
  x?: InputMaybe<FloatFilter>;
  y?: InputMaybe<FloatFilter>;
};

export type SanityImageHotspotSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type SanityImageMetadata = {
  __typename?: "SanityImageMetadata";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  blurHash?: Maybe<Scalars["String"]>;
  dimensions?: Maybe<SanityImageDimensions>;
  hasAlpha?: Maybe<Scalars["Boolean"]>;
  isOpaque?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<Geopoint>;
  lqip?: Maybe<Scalars["String"]>;
  palette?: Maybe<SanityImagePalette>;
};

export type SanityImageMetadataFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  blurHash?: InputMaybe<StringFilter>;
  dimensions?: InputMaybe<SanityImageDimensionsFilter>;
  hasAlpha?: InputMaybe<BooleanFilter>;
  isOpaque?: InputMaybe<BooleanFilter>;
  location?: InputMaybe<GeopointFilter>;
  lqip?: InputMaybe<StringFilter>;
  palette?: InputMaybe<SanityImagePaletteFilter>;
};

export type SanityImageMetadataSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  blurHash?: InputMaybe<SortOrder>;
  dimensions?: InputMaybe<SanityImageDimensionsSorting>;
  hasAlpha?: InputMaybe<SortOrder>;
  isOpaque?: InputMaybe<SortOrder>;
  location?: InputMaybe<GeopointSorting>;
  lqip?: InputMaybe<SortOrder>;
  palette?: InputMaybe<SanityImagePaletteSorting>;
};

export type SanityImagePalette = {
  __typename?: "SanityImagePalette";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  darkMuted?: Maybe<SanityImagePaletteSwatch>;
  darkVibrant?: Maybe<SanityImagePaletteSwatch>;
  dominant?: Maybe<SanityImagePaletteSwatch>;
  lightMuted?: Maybe<SanityImagePaletteSwatch>;
  lightVibrant?: Maybe<SanityImagePaletteSwatch>;
  muted?: Maybe<SanityImagePaletteSwatch>;
  vibrant?: Maybe<SanityImagePaletteSwatch>;
};

export type SanityImagePaletteFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  darkMuted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  darkVibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  dominant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  lightMuted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  lightVibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  muted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  vibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
};

export type SanityImagePaletteSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  darkMuted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  darkVibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  dominant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  lightMuted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  lightVibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  muted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  vibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
};

export type SanityImagePaletteSwatch = {
  __typename?: "SanityImagePaletteSwatch";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  background?: Maybe<Scalars["String"]>;
  foreground?: Maybe<Scalars["String"]>;
  population?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SanityImagePaletteSwatchFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  background?: InputMaybe<StringFilter>;
  foreground?: InputMaybe<StringFilter>;
  population?: InputMaybe<FloatFilter>;
  title?: InputMaybe<StringFilter>;
};

export type SanityImagePaletteSwatchSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  background?: InputMaybe<SortOrder>;
  foreground?: InputMaybe<SortOrder>;
  population?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type Sanity_DocumentFilter = {
  /** All documents that are drafts. */
  is_draft?: InputMaybe<Scalars["Boolean"]>;
  /** All documents referencing the given document ID. */
  references?: InputMaybe<Scalars["ID"]>;
};

export type SectionImage = {
  __typename?: "SectionImage";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  images?: Maybe<Array<Maybe<LocaleImage>>>;
  name?: Maybe<LocaleString>;
};

export type SectionImageFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  name?: InputMaybe<LocaleStringFilter>;
};

export type SectionImageSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  name?: InputMaybe<LocaleStringSorting>;
};

export type Slug = {
  __typename?: "Slug";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  current?: Maybe<Scalars["String"]>;
};

export type SlugFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  current?: InputMaybe<StringFilter>;
};

export type SlugSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  current?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  /** Sorts on the value in ascending order. */
  Asc = "ASC",
  /** Sorts on the value in descending order. */
  Desc = "DESC",
}

export type Span = {
  __typename?: "Span";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  marks?: Maybe<Array<Maybe<Scalars["String"]>>>;
  text?: Maybe<Scalars["String"]>;
};

export type StringFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars["String"]>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars["String"]>;
  nin?: InputMaybe<Array<Scalars["String"]>>;
};

export type TextItem = {
  __typename?: "TextItem";
  _key?: Maybe<Scalars["String"]>;
  _type?: Maybe<Scalars["String"]>;
  /** The key that will be used in the application code to fetch the item - determined by the developer */
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<LocaleString>;
};

export type TextItemFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  value?: InputMaybe<LocaleStringFilter>;
};

export type TextItemSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  value?: InputMaybe<LocaleStringSorting>;
};

export type ApartmentSectionFragment = {
  __typename?: "Apartment";
  name?: string | null | undefined;
  floor?: number | null | undefined;
};

export type AppDataQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AppDataQuery = {
  __typename?: "RootQuery";
  allProject: Array<{
    __typename?: "Project";
    _id?: string | null | undefined;
    projectLogo?:
      | {
          __typename?: "ProjectLogo";
          lightThemeLogo?:
            | {
                __typename?: "File";
                asset?: { __typename?: "SanityFileAsset"; url?: string | null | undefined } | null | undefined;
              }
            | null
            | undefined;
          darkThemeLogo?:
            | {
                __typename?: "File";
                asset?: { __typename?: "SanityFileAsset"; url?: string | null | undefined } | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    accentColor?: { __typename?: "Color"; hex?: string | null | undefined } | null | undefined;
    buttonTextColor?: { __typename?: "Color"; hex?: string | null | undefined } | null | undefined;
    name?:
      | { __typename?: "LocaleString"; en?: string | null | undefined; he?: string | null | undefined }
      | null
      | undefined;
    address?:
      | {
          __typename?: "Address";
          number?: number | null | undefined;
          city?:
            | { __typename?: "LocaleString"; he?: string | null | undefined; en?: string | null | undefined }
            | null
            | undefined;
          street?:
            | { __typename?: "LocaleString"; he?: string | null | undefined; en?: string | null | undefined }
            | null
            | undefined;
        }
      | null
      | undefined;
    defaultBackground?:
      | {
          __typename?: "Background";
          useBgImage?: boolean | null | undefined;
          defaultImage?:
            | {
                __typename?: "LocaleImage";
                he?:
                  | {
                      __typename?: "Image";
                      asset?: { __typename?: "SanityImageAsset"; url?: string | null | undefined } | null | undefined;
                    }
                  | null
                  | undefined;
                en?:
                  | {
                      __typename?: "Image";
                      asset?: { __typename?: "SanityImageAsset"; url?: string | null | undefined } | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          layoutBgColor?: { __typename?: "Color"; hex?: string | null | undefined } | null | undefined;
        }
      | null
      | undefined;
    sections?:
      | Array<
          | {
              __typename?: "ProjectSection";
              title?:
                | { __typename?: "LocaleString"; en?: string | null | undefined; he?: string | null | undefined }
                | null
                | undefined;
              image?:
                | {
                    __typename?: "LocaleImage";
                    he?:
                      | {
                          __typename?: "Image";
                          asset?:
                            | { __typename?: "SanityImageAsset"; url?: string | null | undefined }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    en?:
                      | {
                          __typename?: "Image";
                          asset?:
                            | { __typename?: "SanityImageAsset"; url?: string | null | undefined }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              items?:
                | Array<
                    | {
                        __typename: "SectionImage";
                        name?:
                          | {
                              __typename?: "LocaleString";
                              en?: string | null | undefined;
                              he?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        images?:
                          | Array<
                              | {
                                  __typename?: "LocaleImage";
                                  en?:
                                    | {
                                        __typename?: "Image";
                                        asset?:
                                          | { __typename?: "SanityImageAsset"; url?: string | null | undefined }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined;
                                  he?:
                                    | {
                                        __typename?: "Image";
                                        asset?:
                                          | { __typename?: "SanityImageAsset"; url?: string | null | undefined }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    buildings?:
      | Array<
          | {
              __typename: "Building";
              _id?: string | null | undefined;
              floors?: number | null | undefined;
              name?:
                | { __typename?: "LocaleString"; en?: string | null | undefined; he?: string | null | undefined }
                | null
                | undefined;
              apartments?:
                | Array<
                    | {
                        __typename?: "Apartment";
                        _id?: string | null | undefined;
                        name?: string | null | undefined;
                        floor?: number | null | undefined;
                        apartmentNumber?: string | null | undefined;
                        direction?: string | null | undefined;
                        isVacant?: boolean | null | undefined;
                        providerId?: string | null | undefined;
                        basePrice?:
                          | {
                              __typename?: "LocaleNumber";
                              en?: number | null | undefined;
                              he?: number | null | undefined;
                            }
                          | null
                          | undefined;
                        layout?:
                          | {
                              __typename?: "ApartmentLayout";
                              _id?: string | null | undefined;
                              name?: string | null | undefined;
                              size?: number | null | undefined;
                              roomCount?: number | null | undefined;
                              icon?:
                                | {
                                    __typename?: "Image";
                                    asset?:
                                      | {
                                          __typename?: "SanityImageAsset";
                                          label?: string | null | undefined;
                                          altText?: string | null | undefined;
                                          url?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              layoutImage?:
                                | {
                                    __typename?: "Image";
                                    asset?:
                                      | {
                                          __typename?: "SanityImageAsset";
                                          label?: string | null | undefined;
                                          altText?: string | null | undefined;
                                          url?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              furnishedImage?:
                                | {
                                    __typename?: "Image";
                                    asset?:
                                      | {
                                          __typename?: "SanityImageAsset";
                                          label?: string | null | undefined;
                                          altText?: string | null | undefined;
                                          url?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              unfurnishedImage?:
                                | {
                                    __typename?: "Image";
                                    asset?:
                                      | {
                                          __typename?: "SanityImageAsset";
                                          label?: string | null | undefined;
                                          altText?: string | null | undefined;
                                          url?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              whiteAppliancesImage?:
                                | {
                                    __typename?: "Image";
                                    asset?:
                                      | {
                                          __typename?: "SanityImageAsset";
                                          label?: string | null | undefined;
                                          altText?: string | null | undefined;
                                          url?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              balconyViews?:
                                | {
                                    __typename?: "Image";
                                    asset?:
                                      | {
                                          __typename?: "SanityImageAsset";
                                          label?: string | null | undefined;
                                          altText?: string | null | undefined;
                                          url?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              floorView?:
                                | {
                                    __typename?: "Image";
                                    asset?:
                                      | {
                                          __typename?: "SanityImageAsset";
                                          label?: string | null | undefined;
                                          altText?: string | null | undefined;
                                          url?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              rooms?:
                                | Array<
                                    | {
                                        __typename?: "Room";
                                        _id?: string | null | undefined;
                                        name?: string | null | undefined;
                                        type?:
                                          | {
                                              __typename?: "RoomType";
                                              name?:
                                                | {
                                                    __typename?: "LocaleString";
                                                    en?: string | null | undefined;
                                                    he?: string | null | undefined;
                                                  }
                                                | null
                                                | undefined;
                                              icon?:
                                                | {
                                                    __typename?: "Image";
                                                    asset?:
                                                      | {
                                                          __typename?: "SanityImageAsset";
                                                          label?: string | null | undefined;
                                                          altText?: string | null | undefined;
                                                          url?: string | null | undefined;
                                                        }
                                                      | null
                                                      | undefined;
                                                  }
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined;
                                        furnishedImage?:
                                          | {
                                              __typename?: "Image";
                                              asset?:
                                                | {
                                                    __typename?: "SanityImageAsset";
                                                    label?: string | null | undefined;
                                                    altText?: string | null | undefined;
                                                    url?: string | null | undefined;
                                                  }
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined;
                                        unfurnishedImage?:
                                          | {
                                              __typename?: "Image";
                                              asset?:
                                                | {
                                                    __typename?: "SanityImageAsset";
                                                    label?: string | null | undefined;
                                                    altText?: string | null | undefined;
                                                    url?: string | null | undefined;
                                                  }
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined;
                                        whiteAppliancesImage?:
                                          | {
                                              __typename?: "Image";
                                              asset?:
                                                | {
                                                    __typename?: "SanityImageAsset";
                                                    label?: string | null | undefined;
                                                    altText?: string | null | undefined;
                                                    url?: string | null | undefined;
                                                  }
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined;
                                        elements?:
                                          | Array<
                                              | {
                                                  __typename?: "Element";
                                                  _id?: string | null | undefined;
                                                  name?:
                                                    | {
                                                        __typename?: "LocaleString";
                                                        en?: string | null | undefined;
                                                        he?: string | null | undefined;
                                                      }
                                                    | null
                                                    | undefined;
                                                  options?:
                                                    | Array<
                                                        | {
                                                            __typename?: "ElementOption";
                                                            image?:
                                                              | {
                                                                  __typename?: "Image";
                                                                  asset?:
                                                                    | {
                                                                        __typename?: "SanityImageAsset";
                                                                        url?: string | null | undefined;
                                                                        altText?: string | null | undefined;
                                                                      }
                                                                    | null
                                                                    | undefined;
                                                                }
                                                              | null
                                                              | undefined;
                                                            name?:
                                                              | {
                                                                  __typename?: "LocaleString";
                                                                  he?: string | null | undefined;
                                                                  en?: string | null | undefined;
                                                                }
                                                              | null
                                                              | undefined;
                                                            description?:
                                                              | {
                                                                  __typename?: "LocaleText";
                                                                  he?: string | null | undefined;
                                                                  en?: string | null | undefined;
                                                                }
                                                              | null
                                                              | undefined;
                                                          }
                                                        | null
                                                        | undefined
                                                      >
                                                    | null
                                                    | undefined;
                                                }
                                              | null
                                              | undefined
                                            >
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    building3dOverlay?:
      | Array<
          | {
              __typename?: "File";
              asset?: { __typename?: "SanityFileAsset"; url?: string | null | undefined } | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    building3dImages?:
      | Array<
          | {
              __typename?: "Image";
              asset?: { __typename?: "SanityImageAsset"; url?: string | null | undefined } | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    modelApartments?:
      | Array<
          | {
              __typename?: "ApartmentLayout";
              _id?: string | null | undefined;
              name?: string | null | undefined;
              size?: number | null | undefined;
              roomCount?: number | null | undefined;
              icon?:
                | {
                    __typename?: "Image";
                    asset?:
                      | {
                          __typename?: "SanityImageAsset";
                          label?: string | null | undefined;
                          altText?: string | null | undefined;
                          url?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              layoutImage?:
                | {
                    __typename?: "Image";
                    asset?:
                      | {
                          __typename?: "SanityImageAsset";
                          label?: string | null | undefined;
                          altText?: string | null | undefined;
                          url?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              furnishedImage?:
                | {
                    __typename?: "Image";
                    asset?:
                      | {
                          __typename?: "SanityImageAsset";
                          label?: string | null | undefined;
                          altText?: string | null | undefined;
                          url?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              unfurnishedImage?:
                | {
                    __typename?: "Image";
                    asset?:
                      | {
                          __typename?: "SanityImageAsset";
                          label?: string | null | undefined;
                          altText?: string | null | undefined;
                          url?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              whiteAppliancesImage?:
                | {
                    __typename?: "Image";
                    asset?:
                      | {
                          __typename?: "SanityImageAsset";
                          label?: string | null | undefined;
                          altText?: string | null | undefined;
                          url?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              balconyViews?:
                | {
                    __typename?: "Image";
                    asset?:
                      | {
                          __typename?: "SanityImageAsset";
                          label?: string | null | undefined;
                          altText?: string | null | undefined;
                          url?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              floorView?:
                | {
                    __typename?: "Image";
                    asset?:
                      | {
                          __typename?: "SanityImageAsset";
                          label?: string | null | undefined;
                          altText?: string | null | undefined;
                          url?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              rooms?:
                | Array<
                    | {
                        __typename?: "Room";
                        _id?: string | null | undefined;
                        name?: string | null | undefined;
                        type?:
                          | {
                              __typename?: "RoomType";
                              name?:
                                | {
                                    __typename?: "LocaleString";
                                    en?: string | null | undefined;
                                    he?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              icon?:
                                | {
                                    __typename?: "Image";
                                    asset?:
                                      | {
                                          __typename?: "SanityImageAsset";
                                          label?: string | null | undefined;
                                          altText?: string | null | undefined;
                                          url?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        furnishedImage?:
                          | {
                              __typename?: "Image";
                              asset?:
                                | {
                                    __typename?: "SanityImageAsset";
                                    label?: string | null | undefined;
                                    altText?: string | null | undefined;
                                    url?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        unfurnishedImage?:
                          | {
                              __typename?: "Image";
                              asset?:
                                | {
                                    __typename?: "SanityImageAsset";
                                    label?: string | null | undefined;
                                    altText?: string | null | undefined;
                                    url?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        whiteAppliancesImage?:
                          | {
                              __typename?: "Image";
                              asset?:
                                | {
                                    __typename?: "SanityImageAsset";
                                    label?: string | null | undefined;
                                    altText?: string | null | undefined;
                                    url?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        elements?:
                          | Array<
                              | {
                                  __typename?: "Element";
                                  _id?: string | null | undefined;
                                  name?:
                                    | {
                                        __typename?: "LocaleString";
                                        en?: string | null | undefined;
                                        he?: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                  options?:
                                    | Array<
                                        | {
                                            __typename?: "ElementOption";
                                            image?:
                                              | {
                                                  __typename?: "Image";
                                                  asset?:
                                                    | {
                                                        __typename?: "SanityImageAsset";
                                                        url?: string | null | undefined;
                                                        altText?: string | null | undefined;
                                                      }
                                                    | null
                                                    | undefined;
                                                }
                                              | null
                                              | undefined;
                                            name?:
                                              | {
                                                  __typename?: "LocaleString";
                                                  he?: string | null | undefined;
                                                  en?: string | null | undefined;
                                                }
                                              | null
                                              | undefined;
                                            description?:
                                              | {
                                                  __typename?: "LocaleText";
                                                  he?: string | null | undefined;
                                                  en?: string | null | undefined;
                                                }
                                              | null
                                              | undefined;
                                          }
                                        | null
                                        | undefined
                                      >
                                    | null
                                    | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  }>;
};

export type BuildingSectionFragment = {
  __typename?: "Building";
  floors?: number | null | undefined;
  name?:
    | { __typename?: "LocaleString"; en?: string | null | undefined; he?: string | null | undefined }
    | null
    | undefined;
};

export type ImageFragment = {
  __typename?: "Image";
  asset?:
    | {
        __typename?: "SanityImageAsset";
        label?: string | null | undefined;
        altText?: string | null | undefined;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type LayoutSectionFragment = {
  __typename?: "ApartmentLayout";
  _id?: string | null | undefined;
  name?: string | null | undefined;
  size?: number | null | undefined;
  roomCount?: number | null | undefined;
  icon?:
    | {
        __typename?: "Image";
        asset?:
          | {
              __typename?: "SanityImageAsset";
              label?: string | null | undefined;
              altText?: string | null | undefined;
              url?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  layoutImage?:
    | {
        __typename?: "Image";
        asset?:
          | {
              __typename?: "SanityImageAsset";
              label?: string | null | undefined;
              altText?: string | null | undefined;
              url?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  furnishedImage?:
    | {
        __typename?: "Image";
        asset?:
          | {
              __typename?: "SanityImageAsset";
              label?: string | null | undefined;
              altText?: string | null | undefined;
              url?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  unfurnishedImage?:
    | {
        __typename?: "Image";
        asset?:
          | {
              __typename?: "SanityImageAsset";
              label?: string | null | undefined;
              altText?: string | null | undefined;
              url?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  whiteAppliancesImage?:
    | {
        __typename?: "Image";
        asset?:
          | {
              __typename?: "SanityImageAsset";
              label?: string | null | undefined;
              altText?: string | null | undefined;
              url?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  balconyViews?:
    | {
        __typename?: "Image";
        asset?:
          | {
              __typename?: "SanityImageAsset";
              label?: string | null | undefined;
              altText?: string | null | undefined;
              url?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  floorView?:
    | {
        __typename?: "Image";
        asset?:
          | {
              __typename?: "SanityImageAsset";
              label?: string | null | undefined;
              altText?: string | null | undefined;
              url?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  rooms?:
    | Array<
        | {
            __typename?: "Room";
            _id?: string | null | undefined;
            name?: string | null | undefined;
            type?:
              | {
                  __typename?: "RoomType";
                  name?:
                    | { __typename?: "LocaleString"; en?: string | null | undefined; he?: string | null | undefined }
                    | null
                    | undefined;
                  icon?:
                    | {
                        __typename?: "Image";
                        asset?:
                          | {
                              __typename?: "SanityImageAsset";
                              label?: string | null | undefined;
                              altText?: string | null | undefined;
                              url?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            furnishedImage?:
              | {
                  __typename?: "Image";
                  asset?:
                    | {
                        __typename?: "SanityImageAsset";
                        label?: string | null | undefined;
                        altText?: string | null | undefined;
                        url?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            unfurnishedImage?:
              | {
                  __typename?: "Image";
                  asset?:
                    | {
                        __typename?: "SanityImageAsset";
                        label?: string | null | undefined;
                        altText?: string | null | undefined;
                        url?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            whiteAppliancesImage?:
              | {
                  __typename?: "Image";
                  asset?:
                    | {
                        __typename?: "SanityImageAsset";
                        label?: string | null | undefined;
                        altText?: string | null | undefined;
                        url?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            elements?:
              | Array<
                  | {
                      __typename?: "Element";
                      _id?: string | null | undefined;
                      name?:
                        | {
                            __typename?: "LocaleString";
                            en?: string | null | undefined;
                            he?: string | null | undefined;
                          }
                        | null
                        | undefined;
                      options?:
                        | Array<
                            | {
                                __typename?: "ElementOption";
                                image?:
                                  | {
                                      __typename?: "Image";
                                      asset?:
                                        | {
                                            __typename?: "SanityImageAsset";
                                            url?: string | null | undefined;
                                            altText?: string | null | undefined;
                                          }
                                        | null
                                        | undefined;
                                    }
                                  | null
                                  | undefined;
                                name?:
                                  | {
                                      __typename?: "LocaleString";
                                      he?: string | null | undefined;
                                      en?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                                description?:
                                  | {
                                      __typename?: "LocaleText";
                                      he?: string | null | undefined;
                                      en?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ProjectSectionFragment = {
  __typename?: "Project";
  name?:
    | { __typename?: "LocaleString"; en?: string | null | undefined; he?: string | null | undefined }
    | null
    | undefined;
  sections?:
    | Array<
        | {
            __typename?: "ProjectSection";
            title?:
              | { __typename?: "LocaleString"; en?: string | null | undefined; he?: string | null | undefined }
              | null
              | undefined;
            items?: Array<{ __typename: "SectionImage" } | null | undefined> | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type RoomSectionFragment = { __typename?: "Room"; name?: string | null | undefined };

export type SectionImageFragment = {
  __typename?: "SectionImage";
  name?:
    | { __typename?: "LocaleString"; en?: string | null | undefined; he?: string | null | undefined }
    | null
    | undefined;
  images?:
    | Array<
        | {
            __typename?: "LocaleImage";
            en?:
              | {
                  __typename?: "Image";
                  asset?: { __typename?: "SanityImageAsset"; url?: string | null | undefined } | null | undefined;
                }
              | null
              | undefined;
            he?:
              | {
                  __typename?: "Image";
                  asset?: { __typename?: "SanityImageAsset"; url?: string | null | undefined } | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type AppTextsQueryVariables = Exact<{ [key: string]: never }>;

export type AppTextsQuery = {
  __typename?: "RootQuery";
  allApplicationText: Array<{
    __typename?: "ApplicationText";
    name?: string | null | undefined;
    items?:
      | Array<
          | {
              __typename?: "TextItem";
              key?: string | null | undefined;
              value?:
                | { __typename?: "LocaleString"; he?: string | null | undefined; en?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  }>;
};

export const ApartmentSectionFragmentDoc = gql`
  fragment apartmentSection on Apartment {
    name
    floor
  }
`;
export const BuildingSectionFragmentDoc = gql`
  fragment buildingSection on Building {
    name {
      en
      he
    }
    floors
  }
`;
export const ImageFragmentDoc = gql`
  fragment image on Image {
    asset {
      label
      altText
      url
    }
  }
`;
export const LayoutSectionFragmentDoc = gql`
  fragment layoutSection on ApartmentLayout {
    _id
    name
    size
    icon {
      ...image
    }
    layoutImage {
      ...image
    }
    furnishedImage {
      ...image
    }
    unfurnishedImage {
      ...image
    }
    whiteAppliancesImage {
      ...image
    }
    balconyViews {
      ...image
    }
    floorView {
      ...image
    }
    roomCount
    rooms {
      _id
      name
      type {
        name {
          en
          he
        }
        icon {
          ...image
        }
      }
      furnishedImage {
        ...image
      }
      unfurnishedImage {
        ...image
      }
      whiteAppliancesImage {
        ...image
      }
      elements {
        _id
        name {
          en
          he
        }
        options {
          image {
            asset {
              url
              altText
            }
          }
          name {
            he
            en
          }
          description {
            he
            en
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const ProjectSectionFragmentDoc = gql`
  fragment projectSection on Project {
    name {
      en
      he
    }
    sections {
      title {
        en
        he
      }
      items {
        __typename
      }
    }
  }
`;
export const RoomSectionFragmentDoc = gql`
  fragment roomSection on Room {
    name
  }
`;
export const SectionImageFragmentDoc = gql`
  fragment sectionImage on SectionImage {
    name {
      en
      he
    }
    images {
      en {
        asset {
          url
        }
      }
      he {
        asset {
          url
        }
      }
    }
  }
`;
export const AppDataDocument = gql`
  query AppData($id: ID!) {
    allProject(where: { _id: { matches: $id } }) {
      _id
      projectLogo {
        lightThemeLogo {
          asset {
            url
          }
        }
        darkThemeLogo {
          asset {
            url
          }
        }
      }
      accentColor {
        hex
      }
      buttonTextColor {
        hex
      }
      name {
        en
        he
      }
      address {
        city {
          he
          en
        }
        street {
          he
          en
        }
        number
      }
      defaultBackground {
        defaultImage {
          he {
            asset {
              url
            }
          }
          en {
            asset {
              url
            }
          }
        }
        layoutBgColor {
          hex
        }
        useBgImage
      }
      sections {
        title {
          en
          he
        }
        image {
          he {
            asset {
              url
            }
          }
          en {
            asset {
              url
            }
          }
        }
        items {
          __typename
          ...sectionImage
        }
      }
      buildings {
        _id
        __typename
        name {
          en
          he
        }
        floors
        apartments {
          _id
          name
          floor
          apartmentNumber
          basePrice {
            en
            he
          }
          direction
          isVacant
          providerId
          layout {
            ...layoutSection
          }
        }
      }
      building3dOverlay {
        asset {
          url
        }
      }
      building3dImages {
        asset {
          url
        }
      }
      modelApartments {
        ...layoutSection
      }
    }
  }
  ${SectionImageFragmentDoc}
  ${LayoutSectionFragmentDoc}
`;

/**
 * __useAppDataQuery__
 *
 * To run a query within a React component, call `useAppDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppDataQuery(baseOptions: Apollo.QueryHookOptions<AppDataQuery, AppDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppDataQuery, AppDataQueryVariables>(AppDataDocument, options);
}
export function useAppDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppDataQuery, AppDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppDataQuery, AppDataQueryVariables>(AppDataDocument, options);
}
export type AppDataQueryHookResult = ReturnType<typeof useAppDataQuery>;
export type AppDataLazyQueryHookResult = ReturnType<typeof useAppDataLazyQuery>;
export type AppDataQueryResult = Apollo.QueryResult<AppDataQuery, AppDataQueryVariables>;
export const AppTextsDocument = gql`
  query AppTexts {
    allApplicationText {
      name
      items {
        key
        value {
          he
          en
        }
      }
    }
  }
`;

/**
 * __useAppTextsQuery__
 *
 * To run a query within a React component, call `useAppTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppTextsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppTextsQuery(baseOptions?: Apollo.QueryHookOptions<AppTextsQuery, AppTextsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppTextsQuery, AppTextsQueryVariables>(AppTextsDocument, options);
}
export function useAppTextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppTextsQuery, AppTextsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppTextsQuery, AppTextsQueryVariables>(AppTextsDocument, options);
}
export type AppTextsQueryHookResult = ReturnType<typeof useAppTextsQuery>;
export type AppTextsLazyQueryHookResult = ReturnType<typeof useAppTextsLazyQuery>;
export type AppTextsQueryResult = Apollo.QueryResult<AppTextsQuery, AppTextsQueryVariables>;
