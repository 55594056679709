import { useState, createContext, FC, useContext, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Project,
  Apartment,
  useAppDataQuery,
  AppDataQuery,
  useAppTextsQuery,
  Building,
  Image,
  File,
} from "../generated/graphql";
import appTextsReducer, { AppTexts } from "../utils/appTextsReducer";
import appDataNormalizer from "../utils/appDataNormalizer";
import { getLogger } from "../utils/logging/logger";
import type { ApartmentSelectionParams } from "../screens/ApartmentSelection/ApartmentSelection";

const logger = getLogger("APPLICATION CONTEXT");

const TIDHAR_PROJECT_ID = process.env.REACT_APP_PROJECT_ID || "";
const BASE_ROUTE = process.env.REACT_APP_BASE_ROUTE || "";

export type WizardSteps = WIZARD_STEP[];

export enum WIZARD_STEP {
  ABOUT = "about",
  SELECTION = "selection",
  MODEL_APT = "model",
  SUMMARY = "summary",
}

export enum LOCALE {
  EN = "en",
  HE = "he",
}

const DEFAULT_LOCALE = (process.env.REACT_APP_DEFAULT_LOCALE as LOCALE) || LOCALE.EN;

export type Lang = typeof LOCALE[keyof typeof LOCALE];

export enum DIRECTION {
  LTR = "ltr",
  RTL = "rtl",
}

export interface ThreeDModelsData {
  building: {
    id: NonNullable<Building["_id"]>;
    buildingModelImageList?: Array<Image | null> | null;
    buildingModelOverlayImage?: Array<File | null> | null;
  };
}

interface WizardContext {
  activeStep: WIZARD_STEP;
  visitedSteps: { [key in WIZARD_STEP]: boolean } | null;
  steps: WizardSteps;
  locale: LOCALE;
  setLocale: (locale: LOCALE) => void;
  project: Project | null;
  appTexts: AppTexts | null | undefined;
  isLoading: boolean;
  threeDModelsData: ThreeDModelsData | null;
  selectedApartmentId: string | null;
  setSelectedApartmentId: (id: string | null) => void;
  selectedBuilding: { name: string; id: string } | null;
  setSelectedBuilding: (building: { name: string; id: string } | null) => void;
  selectedApartment: Apartment | null;
  isMobileContentOpen: boolean;
  setIsMobileContentOpen: (isOpen: boolean) => void;
  priceRange: number[];
  setPriceRange: (priceRange: number[]) => void;
  selectedParams: ApartmentSelectionParams;
  setSelectedParams: (
    params: ApartmentSelectionParams | ((prevState: ApartmentSelectionParams) => ApartmentSelectionParams)
  ) => void;
}

const defaultState: WizardContext = {
  activeStep: WIZARD_STEP.ABOUT,
  visitedSteps: null,
  steps: [],
  locale: LOCALE.HE,
  setLocale: () => {},
  selectedApartmentId: "",
  setSelectedApartmentId: () => {},
  selectedBuilding: null,
  setSelectedBuilding: () => {},
  project: null,
  appTexts: null,
  isLoading: true,
  threeDModelsData: null,
  selectedApartment: null,
  isMobileContentOpen: false,
  setIsMobileContentOpen: () => {},
  priceRange: [],
  setPriceRange: () => {},
  selectedParams: {
    price: null,
    roomsNumber: null,
    direction: null,
    layout: null,
    building: null,
    floor: null,
  },
  setSelectedParams: () => {},
};

const Context = createContext<WizardContext>(defaultState);

export const useWizardContext = () => useContext(Context);

export const WizardProvider: FC = ({ children }) => {
  const [isMobileContentOpen, setIsMobileContentOpen] = useState<boolean>(false);
  const [locale, setLocale] = useState<LOCALE>(DEFAULT_LOCALE);
  const [selectedApartmentId, setSelectedApartmentId] = useState<string | null>(null);
  const [selectedBuilding, setSelectedBuilding] = useState<WizardContext["selectedBuilding"]>(null);
  const [priceRange, setPriceRange] = useState<number[]>([]);
  const [visitedSteps, setVisitedSteps] = useState({
    [WIZARD_STEP.ABOUT]: false,
    [WIZARD_STEP.SELECTION]: false,
    [WIZARD_STEP.MODEL_APT]: false,
    [WIZARD_STEP.SUMMARY]: false,
  });

  const activeStep = useLocation().pathname.replace(BASE_ROUTE, "").replaceAll("/", "") as WIZARD_STEP;

  useEffect(() => {
    if (!visitedSteps[activeStep]) {
      setVisitedSteps((prevSteps) => {
        const newSteps = { ...prevSteps };
        newSteps[activeStep] = true;
        return newSteps;
      });
    }
  }, [activeStep, visitedSteps]);

  const [selectedParams, setSelectedParams] = useState<ApartmentSelectionParams>({
    price: null,
    roomsNumber: null,
    direction: null,
    layout: null,
    building: null,
    floor: null,
  });

  if (!process.env.REACT_APP_PROJECT_ID) logger.error("TIDHAR_PROJECT_ID is missing!");

  const {
    data: rawData,
    error: fetchingError,
    loading: dataLoading,
  } = useAppDataQuery({ variables: { id: TIDHAR_PROJECT_ID } });
  const { data: textsRawData, error: textsFetchError, loading: textsLoading } = useAppTextsQuery();

  const data: AppDataQuery | undefined = useMemo(() => {
    return appDataNormalizer(rawData);
  }, [rawData]);

  const appTexts = useMemo(() => {
    return textsRawData && appTextsReducer(textsRawData);
  }, [textsRawData]);

  const project = data?.allProject?.[0] as Project;
  const isLoading = dataLoading || textsLoading;
  const steps = [WIZARD_STEP.ABOUT, WIZARD_STEP.MODEL_APT, WIZARD_STEP.SELECTION, WIZARD_STEP.SUMMARY];
  const threeDModelsData = useMemo(() => {
    if (!project) return null;

    return {
      building: {
        id: project.buildings?.[0]?._id!,
        buildingModelImageList: project.building3dImages || null,
        buildingModelOverlayImage: project.building3dOverlay || null,
        apartmentHighlightModels: project!
          .buildings![0]!.apartments!.map((apartment) => {
            return {
              id: apartment!._id!,
              modelPromise: null,
            };
          })
          .filter((apartmentModel) => apartmentModel.modelPromise),
      },
    };
  }, [project]);

  /*  *
   * * *   Recursive Function to filter out the "en" & "he"
   * * */
  // const recursiveFilter = (target: Partial<Project>): Project => {
  //   return _.cloneDeepWith(target, (value, key, object) => {
  //     if (key === undefined || object === undefined) return;
  //
  //     for (const child in object[key as keyof Project]) {
  //       if (child === LOCALE.EN && locale === LOCALE.EN)
  //         return recursiveFilter(object[key as keyof Project][LOCALE.EN]);
  //       if (child === LOCALE.HE && locale === LOCALE.HE)
  //         return recursiveFilter(object[key as keyof Project][LOCALE.HE]);
  //     }
  //   });
  // };

  const selectedApartment = useMemo(() => {
    if (project?.buildings && selectedApartmentId !== null) {
      for (let building of project.buildings) {
        for (let apartment of building!.apartments!) {
          if (apartment!._id === selectedApartmentId) return apartment;
        }
      }
    }

    return null;
  }, [project, selectedApartmentId]);

  const value = {
    steps,
    activeStep,
    visitedSteps,
    locale,
    setLocale,
    project,
    appTexts,
    isLoading,
    threeDModelsData,
    selectedApartmentId,
    setSelectedApartmentId,
    selectedBuilding,
    setSelectedBuilding,
    selectedApartment,
    isMobileContentOpen,
    setIsMobileContentOpen,
    priceRange,
    setPriceRange,
    selectedParams,
    setSelectedParams,
  };

  if (textsFetchError) {
    logger.error(textsFetchError.message);
  }

  if (fetchingError) {
    logger.error(fetchingError.message);
    return <p>An error has occurred. Please try reloading the application</p>;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
