import React, { FC, useEffect } from "react";
import useMedia from "../../context/hooks/useMedia";
import Box from "@mui/material/Box";
import StepSidePanel from "../StepSidePanel/StepSidePanel";
import StepContent from "../StepContent/StepContent";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { useWizardContext } from "../../context/WizardContext";
import GroupedButtons from "../GroupedButtons/GroupedButtons";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import { useTheme } from "@mui/system";
import { sidebarWidthPx, gapBetweenSidebarAndContentPx, appContainerPaddingDesktopPx } from "../../layoutConstants";

interface StepLayoutProps {
  onNextStep?: () => void;
  nextDisabled?: boolean | undefined;
  contentChildren?: React.ReactElement | undefined;
  sidePanelChildren?: React.ReactElement;
  activeImageUrl?: string | null | undefined;
  isWhiteBackground?: boolean | undefined;
}

const StepLayout: FC<StepLayoutProps> = ({
  contentChildren,
  sidePanelChildren,
  onNextStep,
  activeImageUrl,
  nextDisabled,
}) => {
  const { isMobile } = useMedia();
  const { steps } = useWizardContext();

  const boxStyle = () => ({
    borderRadius: "6px",
    backgroundColor: "background.paper",
    px: !isMobile ? 2.25 : 1.5,
  });

  const { palette } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      component="section"
      sx={{
        display: !isMobile ? "grid" : "flex",
        flexDirection: isMobile ? "column" : "unset",
        gridTemplateColumns: isMobile ? "unset" : `${sidebarWidthPx}px 1fr`,
        gridTemplateRows: !isMobile ? "100%" : "unset",
        gridGap: gapBetweenSidebarAndContentPx,
        height: "100%",
        padding: `${appContainerPaddingDesktopPx}px`,
        backgroundColor: "background.default",
      }}
    >
      {isMobile && (
        <Box
          sx={[
            {
              display: "flex",
              alignItems: "center",
              py: isMobile ? 0.75 : 1.125,
              position: "sticky",
              top: 12,
              zIndex: 1,
              boxShadow: isMobile ? `0px 16px 0 ${palette.grey[300]}, 0px -16px 0 ${palette.grey[300]}` : "unset",
            },
            boxStyle,
          ]}
        >
          <LanguageSelection isWhiteBackground onlyIcon />
          <Breadcrumbs
            sx={{
              "& .MuiBreadcrumbs-ol": {
                justifyContent: "space-between",
                flexWrap: "nowrap",
              },
              flex: 1,
              ml: 1,
            }}
            steps={steps}
          />
        </Box>
      )}
      <StepSidePanel nextDisabled={nextDisabled} onNextStep={onNextStep}>
        {sidePanelChildren}
      </StepSidePanel>
      <StepContent isWhiteBackground backgroundUrl={activeImageUrl || ""}>
        {contentChildren}
      </StepContent>
      {isMobile && <GroupedButtons onNextStep={onNextStep} nextDisabled={nextDisabled} />}
    </Box>
  );
};

export default StepLayout;
