import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

// Sentry must be initialized before any other application code
import "./integrations/sentry";
import firebaseApp from "./integrations/firebase";
import analytics from "./integrations/analytics";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import apolloClient from "./api/client";
import { WizardProvider } from "./context/WizardContext";
import { getLogger } from "./utils/logging/logger";
// import "./integrations/logrocket";

if (firebaseApp) {
  analytics.init(firebaseApp);
}

const logger = getLogger("APPLICATION STARTUP");

if (process.env.NODE_ENV !== "development") {
  logger.info(`APP VERSION: ${process.env.REACT_APP_VERSION}`);
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <WizardProvider>
          <CssBaseline />
          <GlobalStyles
            styles={{
              body: {
                height: "100vh",
                // required for screen transitions to work nicely on mobile since the exiting screen container is stretched via
                // position: absolute; left:0; right: 0; bottom: 0; top: 0;
                // and if the body is not position: relative the exiting screen container is stretched respective to the html element which has height 100% (smallest viewport) instead of 100vh (largest viewport)
                position: "relative",
                "@media screen and (max-width: 1200px)": {
                  backgroundColor: "black", // paints screen edges (unsafe areas) in black instead of default - white on iPhone and probably Android too.
                },
                //For full screen on mobile landscape
                "@media screen and (max-width: 1200px) and (orientation:landscape)": {
                  marginBottom: 180,
                  overflow: "scroll",
                },
              },
              "#root": {
                height: "100%",
                "@media screen and (max-width: 1200px) and (orientation: portrait)": {
                  overflow: "visible !important",
                  height: "100vw",
                  transform: "rotate(90deg)",
                  width: "100%",
                },
                //For full screen on mobile landscape
                "@media screen and (max-width: 1200px) and (orientation:landscape)": {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
              },
              ".react-transform-wrapper": {
                overflow: "visible",
              },
              ".react-transform-component, .react-transform-wrapper": {
                width: "100%",
                height: "100%",
              },
            }}
          />
          <App />
        </WizardProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
