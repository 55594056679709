import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

const useMedia = () => {
  const theme = useTheme();
  const mediaQuery = theme.breakpoints.down(1000);
  const isMobile = useMediaQuery(mediaQuery);
  const isLandscape = useMediaQuery(`${mediaQuery} and (orientation: landscape)`);
  const isMobileLandscape = isMobile && isLandscape;

  return { isMobile, isLandscape, isMobileLandscape };
};

export default useMedia;
