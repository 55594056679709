import React from "react";
import { Select, SelectProps, ListItemIcon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@mui/system";

interface DropdownItem {
  id: string;
  value: string;
  innerText: string;
}
interface DropDownProps extends SelectProps {
  data: DropdownItem[];
}

export const Dropdown: React.FC<DropDownProps> = ({ data, value, ...rest }) => {
  const theme = useTheme();
  const white = theme.palette.background.paper;

  return (
    <Select value={value} {...rest}>
      {data.map(({ id, value: itemValue, innerText }) => (
        <MenuItem
          key={id}
          value={itemValue}
          divider
          dir={theme.direction}
          sx={{
            width: "190px",
            py: 1.25,
            background: "background.paper",
            fontWeight: "fontWeightMedium",
            // If hover and selected wont change to a different style consider combine classes
            ":hover": {
              background: white,
            },
            "&.Mui-selected": {
              background: white,
            },
            "&.Mui-selected:hover": {
              background: white,
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
        >
          <ListItemIcon>{value === itemValue && <CheckIcon sx={{ color: "primary.main" }} />}</ListItemIcon>
          {innerText}
        </MenuItem>
      ))}
    </Select>
  );
};
