import { Box, SxProps, Theme } from "@mui/system";
import React from "react";
import { useTheme } from "@mui/system";

import {
  appContainerPaddingMobilePx,
  appContainerPaddingDesktopPx,
  sidebarWidthPx,
  gapBetweenSidebarAndContentPx,
} from "../../layoutConstants";

interface ResponsiveMainImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  sx?: SxProps<Theme>;
}

export default function ResponsiveMainImage({ src, sx = [], ...restProps }: ResponsiveMainImageProps) {
  const theme = useTheme();

  if (!src) return null;

  return (
    <Box
      component="img"
      sx={[
        {
          objectPosition: "50% 50%",
          objectFit: "scale-down",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      src={src}
      width="100%"
      height="100%"
      srcSet={`
          ${src}?w=640 640w,
          ${src}?w=960 960w,
          ${src}?w=1280 1280w,
          ${src}?w=1440 1440w,
          ${src}?w=1920 1920w,
          ${src}?w=2400 2400w
      `}
      sizes={`(max-width: ${theme.breakpoints.values.sm}px) calc(100vw - ${appContainerPaddingMobilePx * 2}px),
        calc(100vw - ${sidebarWidthPx}px - ${gapBetweenSidebarAndContentPx}px - ${appContainerPaddingDesktopPx * 2}px)`}
      {...restProps}
    />
  );
}
