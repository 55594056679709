import { FC } from "react";
import { ApartmentLayout, Maybe } from "../../generated/graphql";
import CustomRadioButton from "../../components/CustomRadioButton/CustomRadioButton";
import { formatText } from "../../utils/appTextsReducer";
import { SxProps } from "@mui/system";

interface ApartmentSelectionsProps {
  selectedApartmentLayout: ApartmentLayout | null;
  modelApartments: Maybe<ApartmentLayout>[];
  roomNumberText: string;
  handleApartmentChange: (layoutId: string) => void;
  isMobileLandscape?: boolean;
}

const ApartmentSelections: FC<ApartmentSelectionsProps> = ({
  selectedApartmentLayout,
  modelApartments,
  handleApartmentChange,
  roomNumberText,
  isMobileLandscape = false,
}) => {
  const mobileStyles: SxProps = {
    maxHeight: "100%",
    width: "100%",
    flexDirection: "column",
    flexWrap: "nowrap",
    overflowY: "auto",
    "&.MuiFormGroup-root": { mt: 0, ml: 0 },
    "& .MuiFormControlLabel-root": { mt: 1, ml: 0, "&:first-of-type": { mt: 0 }, minWidth: "100px" }, // Using margin instead of gap because of safari not supporting it
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
    "&::-webkit-scrollbar": { display: "none" },
  };

  const desktopStyles: SxProps = { flexWrap: modelApartments.length > 3 ? "wrap" : "nowrap" };

  return (
    <CustomRadioButton
      variant={isMobileLandscape ? "mobile" : "desktop"}
      sx={isMobileLandscape ? mobileStyles : desktopStyles}
      value={selectedApartmentLayout!._id! || modelApartments[0]?._id!}
      options={modelApartments
        .sort((a, b) => a!.roomCount! - b!.roomCount!)
        .map((aptLayout) => ({
          id: aptLayout!._id!,
          name: modelApartments.length > 3 ? aptLayout!.roomCount! : formatText(roomNumberText, aptLayout!.roomCount!),
        }))}
      widthType={modelApartments.length > 3 ? "short" : "oneThird"}
      onChange={(_, aptId) => handleApartmentChange(aptId)}
    />
  );
};

export default ApartmentSelections;
