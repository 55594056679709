import { AppTextsQuery } from "../generated/graphql";

export type AppTexts = {
  [key: string]: { [key: string]: { en: string; he: string } | undefined } | undefined;
};

const toCamelCase = (val: string): string => val.charAt(0).toLocaleLowerCase() + val.slice(1);

const appTextsReducer = (textsData: AppTextsQuery) =>
  textsData.allApplicationText.reduce((acc, section) => {
    const sectionName = toCamelCase(section.name!);
    acc[sectionName] = {};
    section!.items!.forEach((text) => {
      acc[sectionName]![text!.key!] = {
        en: text?.value?.en || "",
        he: text?.value?.he || "",
      };
    });
    return acc;
  }, {} as AppTexts);

export default appTextsReducer;

export const formatText = (str?: string, ...values: (string | number)[]) => {
  if (!str) return "";
  if (!values.length) return str;
  values.forEach((value, index) => {
    str = str?.replace(`{val${index}}`, value.toString());
  });
  // if there are missing values, replace them with empty string
  str = str.replace(/{val\d+}/g, "");
  return str;
};
