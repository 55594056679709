import { View } from "../ApartmentSelection";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

interface ViewSwitchProps {
  items: { value: View; id: string; text: string }[];
  onToggle: (newViewId: View) => void;
  value?: View | null;
}

export default function ViewToggle({ items, onToggle, value }: ViewSwitchProps): JSX.Element {
  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      sx={{ direction: "ltr" }}
      onChange={(e, value) => onToggle(value)}
      value={value}
    >
      {items.map(({ value, id, text }) => (
        <ToggleButton value={value} key={id} sx={{ whiteSpace: "nowrap", px: 1.5, py: 0.75 }}>
          {text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
