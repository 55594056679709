import React, { FC } from "react";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { ApartmentSimple } from "../../screens/ApartmentSelection/ApartmentSelection";
import { useWizardContext } from "../../context/WizardContext";
import { ReactComponent as TypeIcon } from "../../assets/typeIcon.svg";
import { ReactComponent as StairsIcon } from "../../assets/stairsIcon.svg";
import { ReactComponent as BedIcon } from "../../assets/bedIcon.svg";
import { ReactComponent as MeasureIcon } from "../../assets/measureIcon.svg";
import { Button } from "@mui/material";
import { layoutSize } from "../../utils/format";

type Props = {
  apartment?: ApartmentSimple | null;
  onClose: () => void;
  onSelect?: () => void;
};

export const ApartmentDialog: FC<Props> = ({ apartment, onClose, onSelect }) => {
  const { locale, appTexts } = useWizardContext();
  const dialogText = appTexts?.apartmentDialog ?? {};

  if (!apartment) {
    return null;
  }

  return (
    <Box width={174} sx={{ mx: "15px", mb: "15px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
        <Box
          sx={{
            padding: "7px 20px 10px",
            background: "rgba(119, 175, 130, 0.6)",
            borderRadius: "0px 0px 20px 20px;",
            fontWeight: 500,
            fontSize: "10px",
            color: "#ffffff",
          }}
        >
          {apartment.isAvailable ? dialogText?.available?.[locale] : dialogText?.unavailable?.[locale]}
        </Box>

        <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </Box>

      <Box sx={{ my: "10px" }}>
        <Box
          component="img"
          sx={{ backgroundColor: "background.paper", width: "100%" }}
          src={apartment.layoutImage?.url}
          alt={apartment.layoutImage?.altText!}
        />
      </Box>

      <Box display="flex" fontSize="12px">
        <Box flex={"1 0 60%"}>
          <TypeIcon />
          <Box>
            {dialogText?.type?.[locale]} {apartment.layout.name}
          </Box>
        </Box>
        <Box flex={"1 0 40%"}>
          <StairsIcon />
          <Box>
            {apartment.floor} {dialogText?.floor?.[locale]}
          </Box>
        </Box>
      </Box>
      <Box display="flex" fontSize="12px" my={"10px"}>
        <Box flex={"1 0 60%"}>
          <BedIcon />
          <Box>
            {apartment.roomsNumber}{" "}
            {apartment.roomsNumber > 1 ? dialogText?.bedrooms?.[locale] : dialogText?.bedroom?.[locale]}
          </Box>
        </Box>
        <Box flex={"1 0 40%"}>
          <MeasureIcon />
          <Box>{layoutSize(locale, apartment.layout?.size ?? 0)}</Box>
        </Box>
      </Box>

      <Button
        onClick={onSelect}
        fullWidth
        variant="outlined"
        sx={{
          borderColor: "#CA7E56",
          color: "#CA7E56",
          fontWeight: 500,
          "&:hover": {
            borderColor: "#CA7E56",
          },
        }}
      >
        {dialogText?.selectApartment?.[locale]}
      </Button>
    </Box>
  );
};
