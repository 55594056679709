import { datadogLogs, LoggerConfiguration, Logger as LoggerType } from "@datadog/browser-logs";
import { LogTag } from "./logTags";

type LogLevel = "error" | "warn" | "info" | "debug";

interface Metadata {
  [index: string]: any;
}

type LogArguments = [message: string, metadata?: Metadata];

const DATADOG_SITE = "datadoghq.eu";
const DATADOG_SERVICE = "apt-wiz-client";

class Logger {
  #tag: string | undefined;
  #dataDogLogger: LoggerType;

  static logToConsole(level: LogLevel, ...logArgs: LogArguments) {
    console[level](...logArgs);
  }

  constructor(tag?: LogTag, datadogLoggerSettings?: LoggerConfiguration) {
    this.#tag = tag;

    if (tag) {
      this.#dataDogLogger =
        datadogLogs.getLogger(tag) ||
        datadogLogs.createLogger(tag, {
          ...datadogLoggerSettings,
          context: { tag },
        });
    } else {
      this.#dataDogLogger = datadogLogs.logger;
    }
  }

  #logToDataDog(level: LogLevel, message: string, metadata?: Metadata) {
    this.#dataDogLogger[level](message, metadata);
  }

  #log(level: LogLevel, message: string, metadata?: Metadata) {
    const messageWithTag = `${this.#tag ? `[${this.#tag}] - ` : ""}${message}`;

    // prevent 'undefined' from being printed next to log message when metadata object has not been supplied
    if (arguments.length === 3) {
      Logger.logToConsole(level, messageWithTag, metadata);
    } else {
      Logger.logToConsole(level, messageWithTag);
    }

    this.#logToDataDog(level, messageWithTag, metadata);
  }

  error(...args: LogArguments) {
    this.#log("error", ...args);
  }

  warn(...args: LogArguments) {
    this.#log("warn", ...args);
  }

  info(...args: LogArguments) {
    this.#log("info", ...args);
  }

  debug(...args: LogArguments) {
    this.#log("debug", ...args);
  }
}

const environment = process.env.REACT_APP_ENVIRONMENT;

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
  site: DATADOG_SITE,
  service: DATADOG_SERVICE,
  env: environment,
  version: process.env.REACT_APP_VERSION,
});

const datadogHandlerType = environment === "production" || environment === "staging" ? "http" : "silent";
const loggingLevel = process.env.REACT_APP_LOGGING_LEVEL as LogLevel;

datadogLogs.logger.setLevel(loggingLevel);
datadogLogs.logger.setHandler(datadogHandlerType);

const defaultLoggerInstance = new Logger();

export function getLogger(tag?: LogTag) {
  return tag
    ? new Logger(tag, {
        level: loggingLevel,
        handler: datadogHandlerType,
      })
    : defaultLoggerInstance;
}

export function setGlobalContextAttrs(props: { [key: string]: any }) {
  Object.entries(props).forEach(([key, value]) => {
    datadogLogs.addLoggerGlobalContext(key, value);
  });
}

export function setUser(user: { id: string | number } | null) {
  const userId = user?.id;
  datadogLogs.addLoggerGlobalContext("usr", { id: userId });
}
