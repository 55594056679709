import { AppDataQuery } from "../generated/graphql";
import cloneDeepWith from "lodash/cloneDeepWith";

const appDataNormalizer = (rawData: AppDataQuery | undefined): AppDataQuery | undefined => {
  if (!rawData?.allProject) return;

  const rawDataNoDrafts = {
    ...rawData,
    allProject: rawData?.allProject?.filter((project) => !project?._id?.includes("drafts.")),
  };

  return cloneDeepWith(rawDataNoDrafts, (value, key) => {
    if (key === "_id") {
      return value.startsWith("i18n.") ? value.split(".")[1] : value;
    }
    if (key === "i18n_lang") {
      return value.replace("_", "-");
    }
  });
};

export default appDataNormalizer;
