import React, { FC, useEffect, useState, useMemo } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import StepLayout from "../../components/StepLayout/StepLayout";
import Title from "../../components/Title/Title";
import { DIRECTION, LOCALE, useWizardContext } from "../../context/WizardContext";
import useMedia from "../../context/hooks/useMedia";
import "../../transitionStyle.css";
import { ApartmentLayout } from "../../generated/graphql";
import useImageLoader from "../../context/hooks/useImageLoader";
import useForceUpdate from "../../context/hooks/useForceUpdate";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ResponsiveMainImage from "../../components/ResponsiveMainImage/ResponsiveMainImage";
import RoomsButtonsGroup from "./RoomsButtonsGroup";
import StepLayoutMobile from "../../components/StepLayoutMobile/StepLayoutMobile";
import ApartmentSelections from "./ApartmentSelections";
import ModelDialogContent from "./ModelDialogContent";
import useScreenTitle from "../../components/ScreenTitle/ScreenTitle";

const TRANSITION_TIMEOUT = 500;

export enum ImageType {
    Furnished = 'furnishedImage',
    Unfurnished = 'unfurnishedImage',
    Appliances = 'whiteAppliancesImage'
}

const setDefault = (
    type: ImageType,
    obj: Record<ImageType, string> | ApartmentLayout | null,
    setFunc: (t: ImageType) => void
) => {
    if(obj && !obj?.[type]) {
        if(obj?.[ImageType.Unfurnished]) {
            setFunc(ImageType.Unfurnished);
        }
        else if(obj?.[ImageType.Furnished]) {
            setFunc(ImageType.Furnished);
        }
        else if(obj?.[ImageType.Appliances]) {
            setFunc(ImageType.Appliances);
        }
    }
}

const ModelApartments: FC = () => {
  const { project, locale, appTexts } = useWizardContext();
  useScreenTitle(appTexts?.screenName?.ModelApartment?.[locale]);
  const { modelApartments: modelApartmentsTexts, global } = appTexts || {};
  const { isMobile, isMobileLandscape, isLandscape } = useMedia();
  const theme = useTheme();

  const modelApartments = useMemo(() => project?.modelApartments || [], [project?.modelApartments]);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [selectedApartmentLayout, setSelectedApartmentLayout] = useState<ApartmentLayout | null>(modelApartments[0]);
  const defaultImage = selectedApartmentLayout?.furnishedImage?.asset?.url;
  const [mainImage, setMainImage] = useState<ImageType>(ImageType.Unfurnished);
  const [modalImage, setModalImage] = useState<ImageType>(ImageType.Unfurnished);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState(defaultImage);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [showFurnitureCollection, setShowFurnitureCollection] = useState(false);
  const isModalImageLoading = useImageLoader(modalImageUrl || "");
  const forceUpdate = useForceUpdate();
  const appDirectionContainer = locale === LOCALE.EN ? DIRECTION.RTL : DIRECTION.LTR;
  const appDirection = locale === LOCALE.EN ? DIRECTION.LTR : DIRECTION.RTL;

  const handleApartmentChange = (layoutId: string) => {
    setSelectedApartmentLayout(modelApartments.find((aptLayout) => aptLayout!._id! === layoutId) || null);
  };

  const handleSectionChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const section = (e.target as HTMLInputElement).name || e.currentTarget.name;
    setSelectedSection(section);
  };

  const handleFurnitureChange = (e: React.MouseEvent<HTMLElement>, value: ImageType) => {
    if (value === null) return;
    isModalOpen ? setModalImage(value) : setMainImage(value);
  };

  const openModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleSectionChange(e);
    setIsModalOpen(true);
    setModalImage(ImageType.Unfurnished);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSection(null);
    if (showFurnitureCollection) {
      setTimeout(() => {
        setShowFurnitureCollection(false);
      }, TRANSITION_TIMEOUT);
    }
  };

  const modalImages: Record<ImageType, string> = useMemo(() => {
    const getImageUrl = (selectedSection: string | null, type: ImageType): string => {
      let imageUrl = selectedApartmentLayout?.rooms
          ?.find((room) => room?.type?.name?.[locale] === selectedSection)?.[type]?.asset?.url;

      if (imageUrl && !isMobile) {
        imageUrl = `${imageUrl}?w=${Math.round(document.documentElement.clientWidth * window.devicePixelRatio)}`;
      }
      return imageUrl || "";
    };

    return Object.values(ImageType).reduce((obj, type: ImageType) => {
        return {
            ...obj,
            [type]: getImageUrl(selectedSection, type),
        }
    }, {} as Record<ImageType, string>)

  }, [selectedSection, selectedApartmentLayout, locale, isMobile]);

  const room = selectedApartmentLayout?.rooms?.find((room) => room?.type?.name?.[locale] === selectedSection);

  useEffect(() => {
    setImgUrl(selectedApartmentLayout?.[mainImage || ImageType.Furnished]?.asset?.url || '');
    setModalImageUrl(modalImages[modalImage]);
  }, [selectedApartmentLayout, mainImage, modalImages, modalImage]);

  useEffect(() => {
    setSelectedApartmentLayout(modelApartments[0]);
  }, [modelApartments]);

  // Set default image for main and modal - I am ashamed of this code
  useEffect(() => {
      setDefault(mainImage, selectedApartmentLayout, setMainImage);
      setDefault(modalImage, modalImages, setModalImage);
  }, [selectedApartmentLayout, modalImages, mainImage, modalImage])

  const buttonsStyle = {
    py: isMobile ? 0.6875 : 0.875,
    px: locale === LOCALE.EN ? 1.5 : 0,
    width: isMobile && locale === LOCALE.HE ? 121 : !isMobile && locale === LOCALE.HE ? 120 : "unset",
  };

  const renderFurnishedToggleGroup = (type: ImageType, group: Record<ImageType, any> | ApartmentLayout | null) => {
    return (
      <ToggleButtonGroup
        sx={{
          direction: "ltr",
          position: "absolute",
          top: 0,
          left: 0,
          p: 1.125,
          zIndex: 5,
        }}
        className="customizationButtonGroup"
        color="primary"
        value={type}
        exclusive
        onChange={handleFurnitureChange}
      >
        {!(group) || group[ImageType.Furnished] ? <ToggleButton value={ImageType.Furnished} sx={{ "&.Mui-disabled": { border: "none" }, ...buttonsStyle }}>
          {modelApartmentsTexts?.furnished?.[locale]}
        </ToggleButton> : null}
        {!(group) || group[ImageType.Unfurnished] ? <ToggleButton value={ImageType.Unfurnished} sx={{ "&.Mui-disabled": { border: "none" }, ...buttonsStyle }}>
          {modelApartmentsTexts?.unFurnished?.[locale]}
        </ToggleButton> : null}
        {!(group) || group[ImageType.Appliances] ? <ToggleButton value={ImageType.Appliances} sx={{ "&.Mui-disabled": { border: "none" }, ...buttonsStyle }}>
            {modelApartmentsTexts?.appliances?.[locale]}
        </ToggleButton> : null}
      </ToggleButtonGroup>
    );
  };

  const renderFurnitureCollectionBox = () => {
    if (!showFurnitureCollection) {
      return;
    }

    return (
      <Box
        dir={theme.direction}
        sx={{
          zIndex: 6,
          background: theme.palette.background.paper,
          maxWidth: isMobileLandscape ? "100%" : "400px",
          position: "absolute",
          left: "18px",
          top: "18px",
          right: isMobileLandscape ? "18px" : "unset",
          bottom: isMobileLandscape ? "18px" : "unset",
          padding: 1.25,
          pb: 0,
          borderRadius: theme.spacing(0.375),
          boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.12)",
          objectFit: "contain",
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={() => setShowFurnitureCollection(false)}
            sx={{
              p: 0,
              color: theme.palette.primary.main,
              boxShadow: "none",
              ":hover": {
                boxShadow: "none",
                backgroundColor: "inherit",
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Typography
          fontSize={isMobileLandscape ? 24 : 30}
          fontWeight="fontWeightBold"
          lineHeight={1}
          letterSpacing="0.24px"
          sx={{ fontStretch: "condensed" }}
        >
          {room?.type?.name?.[locale]}
        </Typography>

        <Box width={isMobileLandscape ? "100%" : "unset"} height={isMobileLandscape ? "80%" : "unset"} overflow="auto">
          <List
            sx={{
              minWidth: "290px",
              pb: 0,
              width: isMobileLandscape ? "100%" : "unset",
              display: isMobileLandscape ? "flex" : "unset",
              flexWrap: "wrap",
            }}
          >
            {room?.elements?.[0]?.options?.map((option, index) => (
              <Box key={`${option?.name}.${index}`} width={isMobileLandscape ? "50%" : "unset"} sx={{}}>
                {index === 0 || (isMobileLandscape && index === 1) ? null : (
                  <Divider
                    sx={{
                      mr: 2,
                    }}
                  />
                )}

                <ListItem component="li" disablePadding sx={{ minHeight: "95px" }}>
                  <Box
                    component="img"
                    src={option?.image?.asset?.url || ""}
                    alt={option?.image?.asset?.altText || ""}
                    sx={{ mr: 1, width: "72px" }}
                  />

                  <ListItemText
                    primary={option?.name?.[locale]}
                    secondary={
                      <Typography fontSize="12px" sx={{ lineHeight: 1, color: "#252525" }}>
                        {option?.description?.[locale]}
                      </Typography>
                    }
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontWeight: "fontWeightBold",
                        fontSize: "15px",
                        fontStretch: "condensed",
                      },
                    }}
                  />
                </ListItem>
              </Box>
            ))}
          </List>
        </Box>
      </Box>
    );
  };

  const renderDialog = () => (
    <Dialog
      fullScreen
      open={isModalOpen}
      onClose={closeModal}
      transitionDuration={TRANSITION_TIMEOUT}
      sx={{
        direction: isMobile ? appDirectionContainer : "unset",
        zIndex: isMobile && !isLandscape ? -1 : 1300,
        "& .MuiPaper-root": { overflow: isMobile ? "hidden" : "auto" },
        "& .react-transform-wrapper": {
          maxHeight: "100vh",
          maxWidth: "100%",
          position: isMobile ? "absolute" : "relative",
          top: isMobile ? "50%" : 0,
          left: 0,
          right: 0,
          transform: isMobile ? "translateY(-50%)" : "unset",
        },
      }}
    >
      <ModelDialogContent
        appDirection={appDirection}
        forceUpdate={forceUpdate}
        isMobileLandscape={isMobileLandscape}
        modalImage={modalImage}
        isModalOpen={isModalOpen}
        isModalImageLoading={isModalImageLoading}
        modalImageUrl={modalImageUrl}
        modalImages={modalImages}
        transitionTimeOut={TRANSITION_TIMEOUT}
      />
      <Stack
        justifyContent="space-between"
        sx={{
          display: "inline-flex",
          flexDirection: theme.direction === "rtl" ? "row" : "row-reverse",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 5,
        }}
      >
        <Button
          sx={{
            minWidth: "unset",
            padding: isMobile ? 0.125 : 0.75,
            position: "absolute",
            right: 0,
            top: 0,
            margin: isMobile ? 0.75 : 1.125,
          }}
          variant="contained"
          onClick={closeModal}
        >
          <CloseIcon
            sx={{
              width: theme.spacing(isMobile ? 1.25 : 1.125),
              height: theme.spacing(isMobile ? 1.25 : 1.125),
            }}
          />
        </Button>
        {!showFurnitureCollection && renderFurnishedToggleGroup(modalImage, modalImages)}
      </Stack>

      {modalImage && !showFurnitureCollection && !!room?.elements?.length && (
        <Button
          dir={appDirection}
          variant="contained"
          endIcon={<ArrowDropDownIcon />}
          onClick={() => setShowFurnitureCollection(true)}
          sx={{
            position: "absolute",
            left: "18px",
            bottom: "18px",
            background: theme.palette.background.paper,
            color: theme.palette.primary.main,
            zIndex: 5,
            fontStretch: "condensed",
            lineHeight: 1,
            letterSpacing: "0.26",
            fontSize: "fontSize",
            fontWeight: 600,
            px: 1.5,
            py: 0.6875,
            "@media screen and (max-width: 768px)": {
              py: 0.375,
            },
            ":hover": {
              background: theme.palette.background.paper,
            },
            "& .MuiButton-startIcon": {
              mx: 0,
              ml: 0.3,
            },
          }}
        >
          {modelApartmentsTexts?.furnitureSelectionButton?.[locale]}
        </Button>
      )}
      {renderFurnitureCollectionBox()}
    </Dialog>
  );

  if (isMobileLandscape) {
    return (
      <StepLayoutMobile showForwardButton>
        <Box display="flex" height="100%" flexWrap="nowrap" sx={{ pl: 1.875 }}>
          <Box width="20%" sx={{ height: "100%", overflowX: "scroll", mr: 1.5, display: "flex", alignItems: "center" }}>
            <ApartmentSelections
              handleApartmentChange={handleApartmentChange}
              modelApartments={modelApartments}
              roomNumberText={global?.roomsNumber?.[locale] || ""}
              selectedApartmentLayout={selectedApartmentLayout}
              isMobileLandscape={isMobileLandscape}
            />
          </Box>
          <Box
            width="80%"
            sx={{
              display: "flex",
              alignItems: "center",
              overflowX: "scroll",
              "-ms-overflow-style": "none" /* IE and Edge */,
              "scrollbar-width": "none" /* Firefox */,
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <RoomsButtonsGroup
              isMobileLandscape
              rooms={selectedApartmentLayout?.rooms || []}
              locale={locale}
              openModal={openModal}
            />
          </Box>
        </Box>
        {renderDialog()}
      </StepLayoutMobile>
    );
  }

  // This is the children of StepSidePanel component
  const sidePanelContent = (
    <>
      <Title variant="h1" sx={{ whiteSpace: "nowrap", marginBottom: 0.5 }}>
        {modelApartmentsTexts?.title?.[locale]}
      </Title>
      <Typography variant="body2">{modelApartmentsTexts?.subTitle?.[locale]}</Typography>
      <Box sx={{ marginTop: 2 }}>
        <ApartmentSelections
          handleApartmentChange={handleApartmentChange}
          modelApartments={modelApartments}
          roomNumberText={global?.roomsNumber?.[locale] || ""}
          selectedApartmentLayout={selectedApartmentLayout}
        />
      </Box>
      <RoomsButtonsGroup locale={locale} rooms={selectedApartmentLayout?.rooms || []} openModal={openModal} />
    </>
  );

  // This is the children of StepSideContent component
  const stepContent = (
    <>
      {renderFurnishedToggleGroup(mainImage, selectedApartmentLayout)}
      <Grid
        container
        direction="row"
        justifyContent="stretch"
        alignItems="stretch"
        height="100%"
        sx={{ direction: "unset", position: "relative", zIndex: 1 }}
      >
        <ResponsiveMainImage
          sx={{
            objectFit: "contain",
            position: "relative",
            zIndex: 1,
          }}
          src={imgUrl || ""}
          alt=""
        />
      </Grid>
      {renderDialog()}
    </>
  );

  // StepLayout hold the StepSidePanel and StepSideContent components,
  // and we transfer their children threw the props sidePanelChildren and contentChildren
  // StepLayout get another props dependent what the screen needs
  // All the props are optional
  return <StepLayout sidePanelChildren={sidePanelContent} isWhiteBackground contentChildren={stepContent} />;
};

export default ModelApartments;
