import { FC } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { formatPrice } from "../../utils/format";
import { useWizardContext, LOCALE } from "../../context/WizardContext";
import useMedia from "../../context/hooks/useMedia";
import { useTheme } from "@mui/system";
import { formatText } from "../../utils/appTextsReducer";

const InfoList: FC = () => {
  const { selectedApartment, locale, appTexts } = useWizardContext();
  const { global, directions } = appTexts || {};
  const { isMobile } = useMedia();
  const theme = useTheme();

  function renderPrice(price: number) {
    const formattedPrice = formatPrice(locale, price);
    return (
      <span dir="ltr">
        <span dir="rtl">{formattedPrice}</span>K
      </span>
    );
  }

  function renderListItem(key: string, value: string | number | null | undefined) {
    return (
      <ListItem
        sx={{
          "&.MuiListItem-root": {
            py: 0,
            px: 1,
            position: "relative",
            flex: !isMobile ? "1 1 auto" : "unset",
            textAlign: "center",
            width: isMobile ? "unset" : "100%",
            "&:first-of-type": {
              paddingLeft: 0,
              "&:after": {
                content: locale === LOCALE.EN ? "''" : "unset",
              },
            },
            "&:last-of-type": {
              paddingRight: 0,
              "&:after": {
                content: locale === LOCALE.EN ? "unset" : "''",
              },
            },
          },
          "&:after": {
            content: "''",
            position: "absolute",
            right: locale === LOCALE.HE ? "100%" : "0",
            width: "1px",
            height: "9px",
            backgroundColor: !isMobile ? "common.black" : "common.white",
            opacity: !isMobile ? 0.2 : 0.7,
          },
        }}
      >
        <ListItemText
          sx={{
            margin: 0,
            whiteSpace: "nowrap",
            "& .MuiTypography-root": {
              fontWeight: "fontWeightMedium",
              color: !isMobile ? "common.black" : "common.white",
              fontStretch: "condensed",
              lineHeight: 1,
              fontSize: !isMobile ? "1.0625rem" : "1.5rem",
              '@media (max-width: 1200px)': {
                fontSize: "0.85rem"
              },
            },
          }}
          primary={
            key === "price"
              ? renderPrice(Number.isNaN(value as number) ? -1 : (value as number))
              : formatText(global?.[key]?.[locale], value ?? "")
          }
        />
      </ListItem>
    );
  }

  if (!selectedApartment) return null;

  return (
    <List
      dense={false}
      sx={{
        "&.MuiList-root": {
          display: "flex",
          alignItems: "center",
          height: !isMobile ? theme.spacing(4) : theme.spacing(2.25),
          margin: !isMobile ? `0 ${theme.spacing(1.5)}` : 0,
          backgroundColor: !isMobile ? "background.paper" : "transparent",
          px: !isMobile ? 1.5 : 0.75,
          borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
          width: isMobile ? "100%" : "unset",
          direction: locale === LOCALE.EN ? "ltr" : "unset",
          justifyContent: isMobile ? "center" : "unset",
        },
      }}
    >
      {renderListItem("price", selectedApartment.basePrice?.[locale])}
      {renderListItem("roomsNumber", selectedApartment.layout?.roomCount)}
      {renderListItem("floor", selectedApartment.floor)}
      {renderListItem(
        "direction",
        selectedApartment.direction
          ? formatText(directions?.[selectedApartment.direction]?.[locale], selectedApartment.direction)
          : ""
      )}
      {renderListItem(!isMobile ? "apartmentNumber" : "apartmentNumberMobile", selectedApartment.apartmentNumber)}
    </List>
  );
};
export default InfoList;
