import { FC } from "react";
import { SxProps } from "@mui/system";
import { DIRECTION } from "../../context/WizardContext";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Loader from "../../components/Loader/Loader";

import { ImageType } from "./ModelApartments";

interface ModelDialogContentProps {
  isMobileLandscape?: boolean;
  isModalOpen: boolean;
  modalImage: ImageType;
  isModalImageLoading: boolean;
  transitionTimeOut: number;
  modalImageUrl: string;
  modalImages: Record<ImageType, string>;
  forceUpdate: () => void;
  appDirection: DIRECTION;
}

const ModelDialogContent: FC<ModelDialogContentProps> = ({
  isMobileLandscape = false,
  appDirection,
  modalImageUrl,
  transitionTimeOut,
  modalImage,
  modalImages,
  isModalOpen,
  isModalImageLoading,
  forceUpdate,
}) => {
  const mobileContent = () => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      height="100%"
      sx={{
        direction: appDirection,
        position: "relative",
        zIndex: 1,
      }}
    >
      <TransitionGroup component={null}>
        <CSSTransition key={modalImageUrl} classNames="mobilePage" timeout={transitionTimeOut}>
          <TransformWrapper centerOnInit centerZoomedOut disabled={!isModalOpen}>
            <TransformComponent>
              <Box
                height="100%"
                width="100%"
                component="img"
                src={modalImageUrl}
                sx={{ position: "relative", zIndex: 1 }}
                hidden={!isModalOpen}
              />
            </TransformComponent>
          </TransformWrapper>
        </CSSTransition>
      </TransitionGroup>
    </Grid>
  );

  const desktopContent = () => {
    const dialogImagesStyle: SxProps = {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };

    return (
      <>
        {modalImages.furnishedImage ? <Box
          sx={{
            height: "100%",
            backgroundImage: `url(${modalImages.furnishedImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: 2,
            zIndex: 1,
            ...dialogImagesStyle,
            opacity: modalImage === ImageType.Furnished ? 1 : 0,
          }}
        /> : null}
        {modalImages.unfurnishedImage ? <Box
          sx={{
            height: "100%",
            backgroundImage: `url(${modalImages.unfurnishedImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: 2,
            zIndex: 2,
            ...dialogImagesStyle,
            opacity: modalImage === ImageType.Unfurnished ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        /> : null}
        {modalImages.whiteAppliancesImage ? <Box
          sx={{
              height: "100%",
              backgroundImage: `url(${modalImages.whiteAppliancesImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: 2,
              zIndex: 2,
              ...dialogImagesStyle,
              opacity: modalImage === ImageType.Appliances ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
          }}
        /> : null }
      </>
    );
  };

  if (isModalImageLoading) {
    return <Loader onReloadContent={forceUpdate} />;
  }

  return isMobileLandscape ? mobileContent() : desktopContent();
};

export default ModelDialogContent;
