import React from "react";
import { Box, useTheme } from "@mui/system";
import { Button, Stack } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import { useWizardContext } from "../../context/WizardContext";
import { useNavigate } from "react-router-dom";

interface StepLayoutMobileProps {
  children?: React.ReactNode;
  footerContent?: React.ReactNode | null;
  onForward?: (() => void | boolean) | null;
  onBack?: (() => void) | null;
  showForwardButton?: boolean;
  forwardButtonText?: string;
  dimBackground?: boolean;
  isHeaderAndFooter?: boolean;
}

export const headerHeight = "23%";
export const footerHeight = "23%";

const breadcrumbsFontSize = "1rem";
export const breadcrumbsHeight = breadcrumbsFontSize; // line-height === 1
export const navButtonHeight = "2.625rem";

const navButtonStyles = {
  fontSize: "0.9375rem",
  flex: "0 0 auto",
  height: navButtonHeight,
  minWidth: "2.625rem",
  py: 0,
  px: 0,
};

const BASE_ROUTE = process.env.REACT_APP_BASE_ROUTE || '';

function StepLayoutMobile({
  footerContent,
  onForward,
  onBack,
  showForwardButton = true,
  forwardButtonText,
  dimBackground = false,
  children,
  isHeaderAndFooter = true,
}: StepLayoutMobileProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { steps, project, activeStep } = useWizardContext();
  const activeIndex = steps.indexOf(activeStep);
  const bgColor = project?.defaultBackground?.layoutBgColor?.hex || theme.palette.grey["300"];

  function handleForward() {
    if (onForward) {
      const navigateToNextStep = onForward();

      // prevent navigation to the next step if the provided onForward callback does not explicitly return *true*
      if (!navigateToNextStep) {
        return;
      }
    }

    if (activeStep < steps[steps.length - 1]) {
      navigate(`/${BASE_ROUTE}/${steps[activeIndex + 1]}`);
    }
  }

  function handleBack() {
    if (onBack) return onBack();

    if (activeIndex > 0) {
      navigate(`/${BASE_ROUTE}/${steps[activeIndex - 1]}`);
    }
  }

  const edgeShadow = "0 0 64px 36px rgba(0, 0, 0, 0.85)";

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        color: "common.white",
        backgroundColor: bgColor,
        ...(dimBackground && { background: "top left linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.4)) ," }),
      }}
    >
      {isHeaderAndFooter && (
        <Box
          sx={[
            {
              position: "relative",
              zIndex: 10,
              height: headerHeight,
              px: 1.875,
              display: "flex",
            },
            !dimBackground && {
              "&::before": {
                content: "''",
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: 0,
                boxShadow: edgeShadow,
              },
            },
          ]}
        >
          <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
            <LanguageSelection isWhiteBackground={false} onlyIcon sx={{ position: "absolute", right: 0 }} />
            <Breadcrumbs
              steps={steps}
              theme="dark"
              sx={{ width: "max-content", lineHeight: 1, fontSize: breadcrumbsFontSize }}
            />
          </Box>
        </Box>
      )}
      {/*The 60px is the address bar height to make on mobile landscape the content always in the screen*/}
      <Box sx={{ height: `calc(100% - (${headerHeight} + ${footerHeight}))` }}>{children}</Box>
      {isHeaderAndFooter && (
        <Box
          sx={[
            {
              display: "flex",
              alignItems: "center",
              position: "relative",
              height: footerHeight,
              px: 1.875,
            },
            !dimBackground &&
              footerContent !== undefined && {
                "&::before": {
                  content: "''",
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  height: 0,
                  boxShadow: edgeShadow,
                },
              },
          ]}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              flex: 1,
              position: "relative",
              "& button": {
                fontStretch: "condensed",
                letterSpacing: "0.28px",
              },
              zIndex: 9,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleBack}
              sx={{ ...navButtonStyles, visibility: activeIndex > 0 ? "visible" : "hidden" }}
            >
              {theme.direction === "rtl" ? <ArrowForwardRoundedIcon /> : <ArrowBackRoundedIcon />}
            </Button>
            {footerContent && <Box sx={{ typography: "h6", flex: "1 1 auto", mx: 1 }}>{footerContent}</Box>}
            <Button
              variant="contained"
              color="info"
              onClick={handleForward}
              sx={[
                {
                  ...navButtonStyles,
                  visibility: showForwardButton ? "visible" : "hidden",
                  "&.Mui-disabled": {
                    backgroundColor: "info.main",
                    color: "common.black",
                  },
                },
                !!forwardButtonText && {
                  px: 0.9375,
                },
              ]}
            >
              {forwardButtonText ||
                (theme.direction === "rtl" ? <ArrowBackRoundedIcon /> : <ArrowForwardRoundedIcon />)}
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default StepLayoutMobile;
