import { useState, FC } from "react";
import StepLayout from "../../components/StepLayout/StepLayout";
import Accordion from "../../components/Accordion/Accordion";
import { DIRECTION, LOCALE, useWizardContext } from "../../context/WizardContext";
import Title from "../../components/Title/Title";
import {LocaleImage, ProjectSection} from "../../generated/graphql";
import { Box, Typography } from "@mui/material";
import useMedia from "../../context/hooks/useMedia";
import Grid from "@mui/material/Grid";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import "../../transitionStyle.css";
import Loader from "../../components/Loader/Loader";
import useForceUpdate from "../../context/hooks/useForceUpdate";
import ImagesCarousel from "../../components/ImagesCarousel/ImagesCarousel";
import StepLayoutMobile, {
  navButtonHeight,
} from "../../components/StepLayoutMobile/StepLayoutMobile";
import Sections from "../../components/Sections/Sections";
import useScreenTitle from "../../components/ScreenTitle/ScreenTitle";

const sidebarWidthMobile = "140px";
const sidebarHorizontalMarginMobile = "1rem";

const AboutProject: FC = () => {
  const { project, isMobileContentOpen, locale, appTexts } = useWizardContext();
  useScreenTitle(appTexts?.screenName?.AboutProject?.[locale]);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number | null>(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
  const forceUpdate = useForceUpdate();
  const { isMobile, isLandscape } = useMedia();
  const appDirection = locale === LOCALE.EN ? DIRECTION.LTR : DIRECTION.RTL;

  const handleSelectedSectionChange = (index: number) => {
    setSelectedSectionIndex(index);
  };

  const handleSelectedItemChange = (index: number | null) => {
    setSelectedItemIndex(index);
  };

  if (!project) {
    return <Loader timeToWait={10000} onReloadContent={forceUpdate} />;
  }

  let activeImageUrl: LocaleImage[] | undefined = [project?.defaultBackground?.defaultImage] as LocaleImage[];
  if (selectedItemIndex !== null && selectedSectionIndex !== null) {
      activeImageUrl = project?.sections?.[selectedSectionIndex]?.items?.[selectedItemIndex]?.images as LocaleImage[];
  } else if (selectedSectionIndex !== null) {
    activeImageUrl = [project.sections?.[selectedSectionIndex]?.image] as LocaleImage[];
  }

  const street = project.address?.street?.[locale as LOCALE];
  const addressNumber = project.address?.number;
  const city = project.address?.city?.[locale as LOCALE];

  const projectAddress = street && addressNumber && city ? `${street} ${addressNumber}, ${city} ` : "";

  // This is the children of StepSidePanel component
  const sidePanelContent = (
    <>
      <Title variant="h1">{project.name?.[locale as LOCALE]}</Title>
      {projectAddress && (
        <Box display="flex" alignItems="center" mt={1} mb={1.875}>
          <LocationOnOutlinedIcon fontSize="inherit" />
          <Typography lineHeight={1} fontSize="15px" fontWeight={600} sx={{ fontStretch: "condensed" }}>
            {projectAddress}
          </Typography>
        </Box>
      )}

      <Box sx={{ maxHeight: !isMobile ? "600px" : "100%" }}>
        <Accordion
          sections={project.sections as ProjectSection[]}
          selectedSectionIndex={selectedSectionIndex}
          selectedItemIndex={selectedItemIndex}
          onSelectedSectionChange={handleSelectedSectionChange}
          onSelectedItemChange={handleSelectedItemChange}
        />
      </Box>
    </>
  );

  const stepContent = (
    <Grid
      container
      direction={isMobile ? "column" : "row"}
      justifyContent={isMobile ? "center" : "stretch"}
      alignItems="stretch"
      height="100%"
      sx={{
        direction: isMobile ? appDirection : "unset",
      }}
    >
      <Box
        sx={{
          backgroundColor: !isMobileContentOpen ? "#fff" : "background.paper", //The background is because the black images and to avoid the flash transition
          height: !isMobileContentOpen ? "100%" : "unset",
          width: '100%',
        }}
      >
        <TransitionGroup component={null}>
          <CSSTransition key={activeImageUrl.length} classNames="page" timeout={500}>
              <ImagesCarousel images={activeImageUrl} />
          </CSSTransition>
        </TransitionGroup>
      </Box>
    </Grid>
  );

  const imageInlineEndOffset = "1rem";
  return isLandscape || isMobile ? (
    <StepLayoutMobile>
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: `${sidebarWidthMobile} calc(100% - (${sidebarWidthMobile} + ${sidebarHorizontalMarginMobile} * 2) - ${imageInlineEndOffset})`,
                columnGap: sidebarHorizontalMarginMobile,
                marginLeft: sidebarHorizontalMarginMobile,
            }}
        >
            <Sections
                sections={project.sections as ProjectSection[]}
                onSelectedSectionChange={handleSelectedSectionChange}
                onSelectedItemChange={handleSelectedItemChange}
                selectedSectionIndex={selectedSectionIndex}
                selectedItemIndex={selectedItemIndex}
                sx={{
                    position: 'relative',
                    zIndex: 1,
                }}
            />
            {activeImageUrl && (
                <Box
                    sx={{
                        width: '100%',
                        height: `calc(100vh - (${navButtonHeight} * 2.5))`,
                    }}
                >
                    <ImagesCarousel
                        images={activeImageUrl}
                        sx={{
                            objectFit: "contain"
                        }}
                    />
                </Box>
            )}
        </Box>
    </StepLayoutMobile>
  ) : (
    <StepLayout sidePanelChildren={sidePanelContent} contentChildren={stepContent} />
  );
};

export default AboutProject;
