import React from "react";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import { Box, useTheme } from "@mui/system";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ResponsiveMainImage from "../../../components/ResponsiveMainImage/ResponsiveMainImage";
import ViewToggle from "./ViewToggle";
import { View } from "../ApartmentSelection";
import useMedia from "../../../context/hooks/useMedia";
import { LOCALE, useWizardContext } from "../../../context/WizardContext";
import type { ApartmentSimple } from "../ApartmentSelection";
import { ListItem, Stack } from "@mui/material";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { layoutSize } from "../../../utils/format";

const contentImageStyle = {
  height: "100%",
  width: "100%",
  objectPosition: "50% 50%",
  objectFit: "scale-down",
  position: "relative",
  zIndex: 1,
};

const fillContainerStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  objectFit: "cover",
};

const navOptionsStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 10,
  insetBlockStart: 0,
  insetInlineStart: 0,
  marginInlineStart: 1.5,
  marginBlockStart: 1.125,
};

const navOptionsStyleMobile = {
  position: "absolute",
  zIndex: 6,
  left: "50%",
  top: 0,
  paddingTop: "0.75rem",
  transform: "translateX(-50%)",
  textAlign: "center",
};

const TRANSITION_TIMEOUT = 500;
const TRANSITION_TIMEOUT_MOBILE = 2000;

interface PreviewPopupProps {
  onClose: () => void;
  isOpen: boolean;
  hideBackdrop?: boolean;
  apartment: ApartmentSimple;
  onViewChange: (newView: View) => void;
  currentView: View | null;
  containerRef: React.RefObject<any>;
  onLayoutSelectedClick?: (value: string) => void;
  currentFieldName: string;
}

export default function PreviewPopup({
  onClose,
  isOpen,
  hideBackdrop,
  apartment,
  onViewChange,
  currentView,
  containerRef,
  onLayoutSelectedClick,
  currentFieldName,
}: PreviewPopupProps) {
  const theme = useTheme();
  const appDirection = theme.direction;
  const { isMobile } = useMedia();
  const { locale, appTexts } = useWizardContext();

  const { apSelection } = appTexts || {};
  const viewToggleItems = [
    {
      value: View.Layout2D,
      id: "view2D",
      text: apSelection?.view2D?.[locale] || "",
    },
    {
      value: View.Layout3D,
      id: "view3D",
      text: apSelection?.view3D?.[locale] || "",
    },
    {
      value: View.ApartmentView,
      id: "viewApartmentView",
      text: apSelection?.viewApartmentView?.[locale] || "",
    },
    {
      value: View.FloorView,
      id: "viewFloorView",
      text: apSelection?.viewFloorView?.[locale] || "",
    },
  ];

  const handleToggleView = (value: number) => {
    // make sure that 1 button is always selected https://mui.com/components/toggle-button/#enforce-value-set
    if (value !== null) {
      onViewChange(value);
    }
  };

  const contentPaddingTopRem = 3.125;

  const renderApartmentLayoutText = () => (
    <Typography
      variant="body1"
      fontFamily="DM Sans, sans-serif"
      lineHeight={0.67}
      fontWeight={500}
      sx={{ fontStretch: "normal", fontSize: !isMobile ? "30px" : "40px", ml: !isMobile ? "auto" : 0 }}
    >
      {apartment?.layout?.name}
    </Typography>
  );

  return (
    <InfoDialog
      onClose={onClose}
      isOpen={isOpen}
      hideBackdrop={hideBackdrop}
      container={!isMobile ? containerRef.current : undefined}
      sx={(theme) => ({
        position: isMobile ? "fixed" : "absolute",
        "& .MuiBackdrop-root": {
          position: isMobile ? "fixed" : "absolute",
        },
        "& .MuiDialog-paper": {
          margin: `${theme.spacing(1.5)} ${theme.spacing(3)} ${theme.spacing(1.5)} ${theme.spacing(3)}`,
          height: `calc(100% - ${theme.spacing(1.5 * 2)})`,
          maxHeight: "none",
        },
      })}
    >
      <Box
        component="section"
        sx={{
          pt: contentPaddingTopRem,
          height: "100%",
          direction: isMobile ? appDirection : "unset",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
          "& .react-transform-wrapper": {
            position: isMobile ? "absolute" : "relative",
            top: isMobile ? "50%" : 0,
            left: 0,
            right: 0,
            transform: isMobile ? "translateY(-50%)" : "unset",
          },
        }}
      >
        <TransitionGroup component={null}>
          {currentView === View.Layout2D || currentView === View.Layout3D ? (
            <CSSTransition
              classNames="preview-popup-content"
              timeout={isMobile ? TRANSITION_TIMEOUT_MOBILE : TRANSITION_TIMEOUT}
            >
              <Stack
                gap={0.8}
                sx={[
                  {
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor: "background.paper",
                  },
                  !isMobile && {
                    pt: `calc(${contentPaddingTopRem}rem + var(--info-dialog-content-padding-y))`,
                    pb: "var(--info-dialog-content-padding-y)",
                    px: "var(--info-dialog-content-padding-x)",
                  },
                ]}
              >
                <Stack
                  gap={!isMobile ? 1.2 : 0}
                  flexDirection={!isMobile ? "row" : "column-reverse"}
                  justifyContent={!isMobile ? "center" : "flex-start"}
                  alignItems={!isMobile ? "baseline" : "flex-start"}
                  p={!isMobile ? 0 : 1.5}
                  dir={!isMobile ? "unset" : locale === LOCALE.HE ? "rtl" : "ltr"}
                  sx={{
                    alignSelf: "start",
                    position: !isMobile ? "unset" : "absolute",
                    left: !isMobile ? "unset" : 0,
                    top: !isMobile ? "unset" : 0,
                    zIndex: 2,
                  }}
                >
                  {!isMobile && renderApartmentLayoutText()}
                  <List
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      direction: "ltr",
                      fontSize: "17px",
                      pt: 1,
                      pb: 1,
                      "& .MuiListItem-root": {
                        width: "unset",
                        py: 0,
                      },
                    }}
                  >
                    <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                      <Typography variant="body1" lineHeight={1}>
                        {apartment.roomsNumber} {apSelection?.rooms?.[locale]}
                      </Typography>
                    </ListItem>
                    {apartment.layout.size && (
                      <>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          sx={{ height: "1ex", borderColor: "rgb(0,0,0,.2)", paddingLeft: 1, margin: 0 }}
                        />
                        <ListItem sx={{ paddingRight: 0 }}>
                          <Typography variant="body1" lineHeight={1}>
                            {layoutSize(locale, apartment.layout.size)}
                          </Typography>
                        </ListItem>{" "}
                      </>
                    )}
                  </List>
                  {isMobile && renderApartmentLayoutText()}
                </Stack>

                <Box sx={{ position: "relative", flex: 1 }}>
                  <TransitionGroup component={null}>
                    {currentView === View.Layout2D && (
                      <CSSTransition
                        key={apartment?.layoutImage?.url!}
                        classNames="preview-popup-content"
                        timeout={isMobile ? TRANSITION_TIMEOUT_MOBILE : TRANSITION_TIMEOUT}
                      >
                        {isMobile ? (
                          <TransformWrapper centerOnInit centerZoomedOut>
                            <TransformComponent>
                              <Box
                                component="img"
                                sx={{
                                  ...contentImageStyle,
                                  backgroundColor: "background.paper",
                                  pt: `calc(${contentPaddingTopRem}rem + var(--info-dialog-content-padding-y))`,
                                  pb: "var(--info-dialog-content-padding-y)",
                                }}
                                src={apartment?.layoutImage?.url!}
                                alt={apartment?.layoutImage?.altText!}
                              />
                            </TransformComponent>
                          </TransformWrapper>
                        ) : (
                          <ResponsiveMainImage
                            sx={{
                              ...contentImageStyle,
                              backgroundColor: "background.paper",
                              position: "absolute",
                              left: 0,
                              top: 0,
                              right: 0,
                              bottom: 0,
                              objectFit: "contain",
                            }}
                            src={apartment?.layoutImage?.url!}
                          />
                        )}
                      </CSSTransition>
                    )}
                    {currentView === View.Layout3D ? (
                      <CSSTransition
                        key={apartment?.unfurnishedImage?.url!}
                        classNames="preview-popup-content"
                        timeout={isMobile ? TRANSITION_TIMEOUT_MOBILE : TRANSITION_TIMEOUT}
                      >
                        {isMobile ? (
                          <TransformWrapper centerOnInit centerZoomedOut>
                            <TransformComponent>
                              <Box
                                component="img"
                                sx={contentImageStyle}
                                src={apartment?.unfurnishedImage?.url!}
                                alt={apartment?.unfurnishedImage?.altText!}
                              />
                            </TransformComponent>
                          </TransformWrapper>
                        ) : (
                          <ResponsiveMainImage
                            src={apartment?.unfurnishedImage?.url!}
                            sx={{
                              ...contentImageStyle,
                              backgroundColor: "background.paper",
                              position: "absolute",
                              left: 0,
                              top: 0,
                              right: 0,
                              bottom: 0,
                            }}
                          />
                        )}
                      </CSSTransition>
                    ) : null}
                  </TransitionGroup>
                </Box>
                {onLayoutSelectedClick && (
                  <Button
                    variant="contained"
                    color="info"
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      margin: 1,
                      fontStretch: "condensed",
                      letterSpacing: "0.28px",
                    }}
                    onClick={() => onLayoutSelectedClick(apartment?.layout?.id)}
                  >
                    {apSelection?.selectThisLayout?.[locale]}
                  </Button>
                )}
              </Stack>
            </CSSTransition>
          ) : null}
          {currentView === View.ApartmentView ? (
            <CSSTransition
              classNames="preview-popup-content"
              timeout={isMobile ? TRANSITION_TIMEOUT_MOBILE : TRANSITION_TIMEOUT}
            >
              {isMobile ? (
                <TransformWrapper centerOnInit centerZoomedOut>
                  <TransformComponent>
                    <Box
                      component="img"
                      sx={{
                        ...fillContainerStyle,
                        position: "relative",
                      }}
                      src={apartment?.balconyViews?.url!}
                      alt={apartment?.balconyViews?.altText!}
                    />
                  </TransformComponent>
                </TransformWrapper>
              ) : (
                <ResponsiveMainImage
                  sx={{
                    ...fillContainerStyle,
                  }}
                  src={apartment?.balconyViews?.url!}
                />
              )}
            </CSSTransition>
          ) : null}
          {currentView === View.FloorView ? (
            <CSSTransition
              key={apartment?.floorView?.url!}
              classNames="preview-popup-content"
              timeout={isMobile ? TRANSITION_TIMEOUT_MOBILE : TRANSITION_TIMEOUT}
            >
              {isMobile ? (
                <TransformWrapper centerOnInit centerZoomedOut>
                  <TransformComponent>
                    <Box
                      component="img"
                      sx={{ ...contentImageStyle, backgroundColor: "background.paper" }}
                      src={apartment?.floorView?.url!}
                      alt={apartment?.floorView?.altText!}
                    />
                  </TransformComponent>
                </TransformWrapper>
              ) : (
                <ResponsiveMainImage
                  src={apartment?.floorView?.url!}
                  sx={{
                    ...contentImageStyle,
                    backgroundColor: "background.paper",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                  }}
                />
              )}
            </CSSTransition>
          ) : null}
        </TransitionGroup>

        {/* view toggle */}
        <Box component="nav" sx={isMobile ? navOptionsStyleMobile : navOptionsStyle}>
          <ViewToggle
            items={viewToggleItems.filter(({ value }) => {
              /* only show "Apartment view" button if the floor has been selected */
              return (value !== View.ApartmentView && value !== View.FloorView) || currentFieldName === "floor";
            })}
            value={currentView}
            onToggle={handleToggleView}
          />
        </Box>
      </Box>
    </InfoDialog>
  );
}
