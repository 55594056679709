import { useLayoutEffect } from "react";

const APP_NAME = document.title;

export default function useScreenTitle(screenTitle: string | undefined) {
  useLayoutEffect(() => {
    if (screenTitle) {
      document.title = `${screenTitle} (${APP_NAME})`;
    }
  }, [screenTitle]);
}
