import Title from "../../components/Title/Title";
import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import LanguageSelection from "../../components/LanguageSelection/LanguageSelection";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMedia from "../../context/hooks/useMedia";
import { LOCALE, useWizardContext } from "../../context/WizardContext";

const Entry: FC = () => {
  const navigate = useNavigate();
  const { isMobile, isLandscape } = useMedia();
  const { appTexts, locale, project } = useWizardContext();
  const logoSrc = project?.projectLogo?.lightThemeLogo?.asset?.url || "";
  const { entry } = appTexts || {};
  const buttonTextColor = project?.buttonTextColor?.hex;

  //This useEffect with the resize event is to calculate the viewport on portrait when we have address bar and when we not
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const viewport = window.visualViewport;
  const isAndroid = window.navigator.userAgent.match(/Android/i);
  const paddingTopAndBottom = "48px";

  useEffect(() => {
    const { height: currentHeight } = window.visualViewport;
    const handleResize = () => {
      const { height: afterChangeHeight, width: afterChangeWidth } = window.visualViewport;
      const isLandScapeView = afterChangeWidth > afterChangeHeight;
      const portrait = afterChangeWidth < afterChangeHeight;
      if (portrait && currentHeight < afterChangeHeight) setIsFullscreen(true);
      else if (portrait && currentHeight === afterChangeHeight) setIsFullscreen(false);
      else if (portrait && currentHeight > afterChangeHeight) setIsFullscreen(false);
      else if (isLandScapeView && currentHeight < afterChangeHeight) setIsFullscreen(true);
      else if (isLandScapeView && currentHeight > afterChangeHeight) setIsFullscreen(false);
    };

    window.visualViewport.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        position: isMobile && !isLandscape && !isAndroid && !isFullscreen ? "fixed" : "unset",
        top: isMobile && !isLandscape && !isAndroid && !isFullscreen ? 0 : "unset",
        left: isMobile && !isLandscape && !isAndroid && !isFullscreen ? 0 : "unset",
        right: isMobile && !isLandscape && !isAndroid && !isFullscreen ? 0 : "unset",
        bottom: isMobile && !isLandscape && !isAndroid && !isFullscreen ? 0 : "unset",
        height: "100%",
        padding: 1.5,
        backgroundColor: "white",
        width:
          isMobile && !isLandscape && !isFullscreen && isAndroid
            ? `calc(100vh - ${paddingTopAndBottom})`
            : isMobile && !isLandscape && !isFullscreen && !isAndroid
            ? viewport.height
            : "unset",
        transform:
          isMobile && !isLandscape && !isFullscreen && !isAndroid
            ? `translateX(0)`
            : isMobile && !isLandscape && !isFullscreen && isAndroid
            ? `translateX(${locale === LOCALE.HE ? paddingTopAndBottom : "0"})`
            : "unset",
      }}
    >
      <Box
        display="flex"
        justifyContent={isLandscape || isMobile ? "space-between" : locale === LOCALE.EN ? "unset" : "flex-end"}
        flexDirection={(isLandscape || isMobile) && locale === LOCALE.HE ? "row-reverse" : "row"}
      >
        <LanguageSelection isWhiteBackground />
        {(isLandscape || isMobile) && logoSrc && <Box component="img" src={logoSrc} alt="tidhar" />}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={!isLandscape && isMobile ? "auto" : "unset"}
      >
        <Title align="center" variant="h1" maxWidth={!isMobile && !isLandscape ? "unset" : "700px"}>
          {entry?.header?.[locale]}
        </Title>

        <Typography
          lineHeight={1.33}
          maxWidth={!isMobile && !isLandscape ? "760px" : "520px"}
          mt={0.75}
          mb={2.375}
          fontSize="18px"
          align="center"
          sx={{
            fontStretch: "condensed",
          }}
        >
          {entry?.body?.[locale]}
        </Typography>

        <Button
          color="info"
          size="large"
          sx={{
            width: isMobile ? "138px" : "200px",
            py: isMobile ? 0.875 : 1.25,
            color: buttonTextColor || "common.black",
            "&:hover": {
              backgroundColor: "info.dark",
            },
          }}
          variant="contained"
          onClick={() => navigate('about')}
        >
          <Typography fontWeight="fontWeightBold" lineHeight={1}>
            {entry?.startButton?.[locale]}
          </Typography>
        </Button>
      </Box>

      <Box
        dir="ltr"
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mt={!isLandscape && isMobile ? "auto" : "unset"}
      >
        <Typography fontWeight="fontWeightMedium" fontSize="12px" lineHeight={1}>
          Powered by <Box component="img" src={"/assets/homez.svg"} alt="homez" />
        </Typography>

        {!isLandscape && !isMobile && logoSrc && <Box component="img" src={logoSrc} alt="tidhar" />}
      </Box>
    </Box>
  );
};

export default Entry;
