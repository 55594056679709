export const aptWizthemeEnFontStyle = {
  typography: {
    fontFamily: ["DM Sans", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Wulkan Display", "sans-serif"].join(","),
    },
    body1: {
      fontWeight: 500,
    },
    body2: {
      fontWeight: 500,
    },
  },
};
