import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useWizardContext } from "../../context/WizardContext";
import { ProjectSection } from "../../generated/graphql";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/system";
import { SxProps } from "@mui/system";
interface SectionItemsListProps {
  section?: ProjectSection;
  isOpen: boolean;
  onToggleSelection: () => void;
  onChange: (itemIndex: number | null) => void;
  isActive?: boolean;
  sx?: SxProps;
  activePosition: number;
  selectedItemIndex: number | null;
}

const SectionItemsList: React.FC<SectionItemsListProps> = ({
  section,
  isOpen,
  onToggleSelection,
  onChange,
  isActive,
  sx,
  activePosition,
  selectedItemIndex,
}) => {
  const { locale } = useWizardContext();

  const theme = useTheme();

  const handleItemSelected = (index: number) => {
    onChange(index);
  };

  const handleToggleSelection = () => {
    onToggleSelection();
    onChange(null);
  };

  return (
    <List
      component="ul"
      disablePadding
      className={isOpen && isActive ? "active" : ""}
      dir={theme.direction === "rtl" ? "ltr" : "rtl"}
      sx={{
        ...sx,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "6px",
        boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.12)",
        height: "0",
        overflow: "auto",
        transition: "height 1s, top 1s ",
        background: "white",
        msOverflowStyle: "none", /* IE and Edge */
        scrollbarWidth: "none",/* Firefox */
        "&::-webkit-scrollbar": { display: "none" },

        "&.active": {
          height: "100%",
          top: activePosition,
          zIndex: 7,
        },
      }}
      subheader={
        <Button
          disableTouchRipple
          onClick={handleToggleSelection}
          endIcon={<ExpandMore />}
          dir={theme.direction}
          sx={{
            backgroundColor: selectedItemIndex === null ? "info.dark" : "common.white",
            ":hover": {
              backgroundColor: selectedItemIndex === null ? "info.dark" : "common.white",
              boxShadow: "none",
            },
            "& .MuiButton-endIcon": {
              transform: isOpen ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 1s",
            },
            ...listSubHeaderBtnStyle,
          }}
        >
          {section?.title?.[locale]}
        </Button>
      }
    >
      {section?.items?.map((item, index) => {
        if (!item) return null;
        const isSectionItemActive = selectedItemIndex === index;
        return (
          <ListItemButton
            key={`${item.name?.[locale]}${index}`}
            onClick={() => handleItemSelected(index)}
            disableRipple
            sx={{
              backgroundColor: isSectionItemActive ? "info.dark" : "common.white",
              color: "common.black",
              ":hover": {
                backgroundColor: isSectionItemActive ? "info.dark" : "common.white",
              },
              ":last-of-type": {
                borderBottomRightRadius: "6px",
                borderBottomLeftRadius: "6px",
              },
            }}
          >
            <ListItemText> {item.name?.[locale]}</ListItemText>
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default SectionItemsList;

const listSubHeaderBtnStyle = {
  position: "sticky",
  display: "flex",
  width: "100%",
  top: 0,
  zIndex: 1,
  justifyContent: "space-between",
  p: 0.875,
  fontStretch: "condensed",
  lineHeight: 1,
  letterSpacing: "0.26",
  fontSize: "fontSize",
  fontWeight: "fontWeightBold",
  color: "common.black",
  boxShadow: "none",
  borderRadius: "0",
  textAlign: "start",
  transition: "background-color 1s",
};
