import { Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps } from "@mui/material";
import { useWizardContext, WizardSteps } from "../../context/WizardContext";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { WIZARD_STEP } from "../../context/WizardContext";
import Link from "@mui/material/Link";
import { SxProps, Theme } from "@mui/material/styles";

const BASE_ROUTE = process.env.REACT_APP_BASE_ROUTE || '';

interface WizardStepProps extends BreadcrumbsProps {
  steps: WizardSteps;
  theme?: "light" | "dark";
  sx?: SxProps<Theme>;
  texts?: {
    breadcrumbs: {
      [key: string]: {
        en: string;
        he: string;
      };
    };
  };
}

const breadcrumbsItemStyle = {
  fontSize: "15px",
  fontWeight: "fontWeightBold",
  fontStretch: "condensed",
};

const Breadcrumbs: React.FC<WizardStepProps> = ({ steps, theme = "light", sx = [], texts, ...rest }) => {
  const pathname = useLocation().pathname.substring(1);
  const indexOfActiveStep = steps.indexOf(pathname as WIZARD_STEP);
  const { appTexts, locale, visitedSteps, selectedApartment } = useWizardContext();
  const { breadcrumbs: breadcrumbsTexts } = appTexts || texts || {};

  const breadcrumbs = steps.map((step, index) => {
    const isStepClickable = indexOfActiveStep >= index || visitedSteps?.[step];
    const isStepActive = pathname === step;

    const itemColor = theme === "light" ? "#9b9b9b" : "#ffffff";

    if (!isStepClickable || (step === WIZARD_STEP.SUMMARY && !selectedApartment)) {
      return (
        <Typography
          sx={{
            ...breadcrumbsItemStyle,
            color: itemColor,
          }}
          key={index}
        >
          {breadcrumbsTexts?.[step]?.[locale]}
        </Typography>
      );
    }

    return (
      <Link
        to={`/${BASE_ROUTE}/${step}`}
        underline="none"
        component={RouterLink as any}
        key={index}
        sx={{
          ...breadcrumbsItemStyle,
          color: isStepActive ? (theme === "light" ? "primary.main" : "info.main") : itemColor,
        }}
      >
        {breadcrumbsTexts?.[step]?.[locale]}
      </Link>
    );
  });

  return (
    <MuiBreadcrumbs
      separator="›"
      sx={[
        {
          "& .MuiBreadcrumbs-separator": {
            mx: 1,
            color: theme === "light" ? "rgba(0,0,0,0.6)" : "white",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {breadcrumbs}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
