import axios from "axios";

import { Apartment } from "../../generated/graphql";
import { getLogger } from "../../utils/logging/logger";

const logger = getLogger("LEAD CAPTURE");
const apiURL = `${process.env.REACT_APP_NETLIFY_FUNCTIONS_URL}/lead-capture`;

interface LeadCaptureData {
  user: {
    name: string;
    email: string;
    phone: string | null;
  };
  projectId: string;
  apartmentId: string;
  apartment: Apartment | null;
  building: {
    id: string;
    name: string;
  } | null;
}

const postLeadCapture = async (
  data: LeadCaptureData
): Promise<{ error: boolean; message: string; orderNumber?: number }> => {
  try {
    const response = await axios({
      method: "post",
      url: apiURL,
      data: {
        email: data.user.email,
        name: data.user.name,
        apartmentId: data.apartmentId,
        projectId: data.projectId,
        // Optional
        phone: data.user.phone,
        price: data.apartment?.basePrice?.en,
        roomCount: data.apartment?.layout?.roomCount,
        apartmentNumber: data.apartment?.apartmentNumber,
        building: data.building?.id,
        direction: data.apartment?.direction,
        floor: data.apartment?.floor,
      },
    });

    return {
      error: false,
      message: response.data.message,
      orderNumber: response.data.orderNumber,
    };
  } catch (error: any) {
    const message = error && error.response && error.response.data ? error.response.data.message : error.message;
    logger.error("Failed to submit leadCapture data: " + message);
    return {
      error: true,
      message: message,
    };
  }
};

export { postLeadCapture };
