import FormControl from "@mui/material/FormControl";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import useMedia from "../../context/hooks/useMedia";

const Input: React.FC<TextFieldProps> = ({ ...props }) => {
  const { isMobile, isLandscape } = useMedia();
  return (
    <FormControl
      sx={{
        my: !isMobile ? 0.5 : 0,
        mx: !isMobile ? 0 : 0.5,
        "&:first-of-type": {
          ml: 0,
        },
        "& .MuiOutlinedInput-root": {
          backgroundColor: !isMobile && !isLandscape ? "transparent" : "background.paper",
        },
      }}
    >
      <TextField
        {...props}
        FormHelperTextProps={{
          sx: {
            margin: 0,
            marginTop: 0.75,
            position: isMobile ? "absolute" : "unset",
            top: isMobile ? "100%" : "unset",
            left: isMobile ? 0 : "unset",
            lineHeight: isMobile ? 1 : 1.66,
            "&.Mui-error": {
              fontWeight: "fontWeightMedium",
              color: isMobile ? "background.paper" : "error.main",
            },
          },
        }}
        InputProps={{
          sx: {
            "&.Mui-focused": {
              "& input": {
                "&::placeholder": {
                  opacity: 0,
                },
              },
            },
            input: {
              px: 1,
              py: 0,
              height: "48px",
              width: "100%",
              color: "common.black",
              fontSize: 14,
              lineHeight: 1,
              fontWeight: "fontWeightMedium",
              letterSpacing: "0.26px",
              fontStretch: "condensed",
              "&:hover, &:focus": {
                "& ~ .MuiOutlinedInput-notchedOutline": { borderColor: "common.black" },
              },
              "&::placeholder": {
                opacity: 1,
                color: "grey.600",
              },
              "& ~ .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, .5)",
              },
            },
          },
        }}
      />
    </FormControl>
  );
};

export default Input;
