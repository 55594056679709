import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LOCALE, useWizardContext } from "../../context/WizardContext";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import useMedia from "../../context/hooks/useMedia";
import { FC } from "react";

interface Props {
  isFullscreen?: boolean;
  currentHeight?: number;
  isAndroid?: RegExpMatchArray | boolean | null | undefined;
}
const Landscape: FC<Props> = ({ isFullscreen, currentHeight, isAndroid }) => {
  const { appTexts, locale, project } = useWizardContext();
  const logoSrc = project?.projectLogo?.lightThemeLogo?.asset?.url || "";
  const { landscape } = appTexts || {};
  const { isMobile, isLandscape } = useMedia();
  const paddingTopAndBottom = "48px";

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        transformOrigin: "center",
        height: "100%",
        padding: 1.5,
        position: "fixed",
        width:
          !isFullscreen && isAndroid
            ? `calc(100vh - ${paddingTopAndBottom})`
            : !isFullscreen && !isAndroid
            ? `${currentHeight}px`
            : "100vh",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1600,
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <Box
        display="flex"
        justifyContent={isLandscape || isMobile ? "space-between" : locale === LOCALE.EN ? "unset" : "end"}
        flexDirection={(isLandscape || isMobile) && locale === LOCALE.HE ? "row-reverse" : "row"}
      >
        <LanguageSelection isWhiteBackground />
        {(isLandscape || isMobile) && logoSrc && <Box component="img" src={logoSrc} alt="tidhar" />}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={!isLandscape && isMobile ? "auto" : "unset"}
      >
        <Typography variant="h1" color="grey.600">
          {landscape?.header?.[locale]}
        </Typography>
      </Box>

      <Box
        dir="ltr"
        alignItems="center"
        display="flex"
        justifyContent={isMobile ? "center" : "space-between"}
        mt={!isLandscape && isMobile ? "auto" : "unset"}
      >
        <Typography fontWeight="fontWeightMedium" fontSize="12px" lineHeight={1}>
          Powered by <Box component="img" src={"/assets/homez.svg"} alt="homez" />
        </Typography>

        {!isLandscape && !isMobile && logoSrc && <Box component="img" src={logoSrc} alt="tidhar" />}
      </Box>
    </Box>
  );
};

export default Landscape;
