import { LOCALE } from "../context/WizardContext";

const PRICE_NORMALIZATION_CONSTANT = 1000;
const SQFEET_TO_METER_PARAM = 10.7639;

export function formatPrice(locale: LOCALE, price: number, showCurrencySign = true) {
  return new Intl.NumberFormat(locale, {
    style: showCurrencySign ? "currency" : "decimal",
    currency: locale === LOCALE.HE ? "ILS" : "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(price / PRICE_NORMALIZATION_CONSTANT);
}

export const layoutSize = (locale: LOCALE, size: string): string => {
  if (locale === LOCALE.EN) {
    return size;
  }

  const numbers = size.match(/(\d+)/);
  if (!numbers || numbers.length === 0 || isNaN(Number(numbers[0])) || !numbers[0]) {
    return size;
  }

  return size.replace(numbers[0], `${Math.round(Number(numbers[0]) / SQFEET_TO_METER_PARAM)}`);
};
