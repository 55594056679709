import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";

if (process.env.REACT_APP_ENVIRONMENT === "staging" || process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing(), new CaptureConsole({ levels: ["error"] })],
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION,
    tracesSampleRate: 1.0,
  });
}
