import { FirebaseApp } from "firebase/app";
import { getAnalytics, logEvent as analyticsLogEvent, EventParams, Analytics } from "firebase/analytics";

let analytics: Analytics;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init(firebaseApp: FirebaseApp) {
    analytics = getAnalytics(firebaseApp);
  },
  logEvent(eventName: string, eventParams: EventParams) {
    analyticsLogEvent(analytics, eventName, eventParams);
  },
};
