import { useState, useEffect } from "react";

const useImageLoader = (src: string | undefined) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (src) {
      const imageLoader = new Image();
      imageLoader.src = src;
      imageLoader.onload = () => {
        setIsLoading(false);
      };
    }
  }, [src]);

  return isLoading;
};

export default useImageLoader;
