import React from "react";
import { useWizardContext, LOCALE } from "../../context/WizardContext";
import { Dropdown } from "../Dropdown/Dropdown";
import LanguageIcon from "@mui/icons-material/Language";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";
import { SxProps, Theme } from "@mui/material/styles";
import { useTheme } from "@mui/system";
import useMedia from "../../context/hooks/useMedia";

interface LanguageSelectionProps {
  isWhiteBackground?: boolean;
  onlyIcon?: boolean;
  sx?: SxProps<Theme>;
}

const LanguageSelection: React.FC<LanguageSelectionProps> = ({ isWhiteBackground, onlyIcon, sx = [] }) => {
  const { appTexts, locale, setLocale } = useWizardContext();
  const { languageSelection } = appTexts || {};
  const theme = useTheme();
  const { isMobile, isLandscape } = useMedia();
  const color = isWhiteBackground ? "common.black" : "common.white";
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setLocale(event.target.value as LOCALE);
  };

  const DropdownData = Object.values(LOCALE).map((language) => ({
    id: `LanguageSelection.${language}`,
    innerText: languageSelection?.[language]?.[locale] || "",
    value: language,
  }));

  return (
    <Box display="flex" alignItems="center" dir="ltr" sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Dropdown
        IconComponent={onlyIcon ? LanguageIcon : undefined}
        data={DropdownData}
        onChange={handleChange}
        fullWidth
        value={locale}
        variant="standard"
        disableUnderline
        labelId="language-select-label"
        id="language-select"
        MenuProps={{
          sx: {
            "& .MuiPaper-root": {
              transform: isMobile && !isLandscape ? "rotate(90deg) !important" : "none",
              transformOrigin: isMobile && !isLandscape ? "120px 35px !important" : "inherit",
              top: isMobile && !isLandscape && locale ? "106px !important" : "inherit",
            },
            "& .MuiList-root": {
              p: 0,
            },
          },
        }}
        renderValue={
          onlyIcon
            ? () => false
            : () => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LanguageIcon
                    sx={{
                      marginRight: locale === LOCALE.HE ? 0 : 0.375,
                      marginLeft: locale === LOCALE.HE ? 0.375 : 0,
                      color,
                    }}
                    fontSize="small"
                  />
                  {languageSelection?.[locale]?.[locale]}
                </Box>
              )
        }
        sx={{
          fontSize: "typography.fontSize",
          fontWeight: "typography.fontWeightMedium",
          color,
          "& .MuiSvgIcon-root": {
            color,
            left: locale === LOCALE.HE ? 0 : "unset",
            right: locale === LOCALE.HE ? "unset" : 0,
          },
          "& .MuiSelect-select": {
            p: onlyIcon ? "0 !important" : "unset",
            "&.MuiInput-input.MuiInputBase-input": {
              paddingLeft: locale === LOCALE.HE ? theme.spacing(1.5) : 0,
              paddingRight: locale === LOCALE.HE ? 0 : theme.spacing(1.5),
            },
          },
        }}
      />
    </Box>
  );
};

export default LanguageSelection;
