import Stack from "@mui/material/Stack";

export default function Error() {
  return (
    <Stack sx={{ height: "100%", width: "100%" }} justifyContent="center" alignItems="center" direction="column">
      <span>
        Something went wrong. Try reloading the page or <a href="/">go home</a>
      </span>
    </Stack>
  );
}
