import { FC } from "react";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import InfoList from "../InfoList/InfoList";
import Box from "@mui/material/Box";
import { WIZARD_STEP, useWizardContext, LOCALE, DIRECTION } from "../../context/WizardContext";
import { SxProps, Theme } from "@mui/material/styles";
import useMedia from "../../context/hooks/useMedia";
import Button from "@mui/material/Button";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import useImageLoader from "../../context/hooks/useImageLoader";
import Loader from "../Loader/Loader";
import useForceUpdate from "../../context/hooks/useForceUpdate";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import { ReactComponent as HomezLogo } from "../../assets/homez.svg";

interface StepContentProps {
  backgroundUrl?: string;
  apartmentSelectionParams?: {};
  isWhiteBackground?: boolean;
  sx?: SxProps<Theme>;
}

const HEADER_HEIGHT_REM = 4;
const FOOTER_HEIGHT_REM = 4;

const StepContent: FC<StepContentProps> = ({ backgroundUrl, sx = [], children, isWhiteBackground }) => {
  const forceUpdate = useForceUpdate();
  const isImageLoading = useImageLoader(backgroundUrl);
  const { SUMMARY } = WIZARD_STEP;
  const { activeStep, selectedApartment, isMobileContentOpen, setIsMobileContentOpen } = useWizardContext();
  const { isMobile } = useMedia();
  const handleOpenModal = () => setIsMobileContentOpen(true);
  const handleCloseModal = () => setIsMobileContentOpen(false);
  const { palette } = useTheme();
  const { locale, project } = useWizardContext();
  const projectLogo = project?.projectLogo?.[isWhiteBackground ? "lightThemeLogo" : "darkThemeLogo"]?.asset?.url || "";
  const appDirection = locale === LOCALE.EN ? DIRECTION.RTL : DIRECTION.LTR;

  const renderExpandButton = () => (
    <Button
      disableElevation={true}
      disableRipple={true}
      sx={{
        background: "white",
        minWidth: "unset",
        width: "24px",
        height: "24px",
        boxShadow: "none",
        ":hover": {
          background: "white",
        },
      }}
      onClick={isMobileContentOpen ? handleCloseModal : handleOpenModal}
    >
      <OpenInFullIcon sx={{ height: "12px", width: "12px" }} />
    </Button>
  );

  if (backgroundUrl && isImageLoading) {
    return <Loader onReloadContent={forceUpdate} />;
  }

  const isBoxExpendedStyle = isMobile &&
    isMobileContentOpen && {
      bottom: 0,
      right: 0,
      left: 0,
    };

  return (
    <Box
      sx={[
        {
          backgroundColor: "background.paper",
          borderRadius: 1,
          overflow: "hidden", // to enforce border radius on the content
          position: isMobile ? (isMobileContentOpen ? "fixed" : "sticky") : "relative",
          top: isMobile ? (isMobileContentOpen ? 0 : "74px") : 0,
          zIndex: 1,
          order: isMobile ? 1 : "unset",
          height: isMobile && !isMobileContentOpen ? 290 : "unset",
          pt: !isMobile ? HEADER_HEIGHT_REM : 0,
          pb: !isMobile ? FOOTER_HEIGHT_REM : 0,
          boxShadow: isMobile ? `0px 12px 0 ${palette.grey[300]}, 0px -12px 0 ${palette.grey[300]}` : "unset",
          direction: isMobile ? appDirection : "unset",
          "& .react-transform-wrapper": {
            maxHeight: "100vh",
            maxWidth: "100%",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
        { ...isBoxExpendedStyle },
      ]}
    >
      <Box sx={{ height: "100%", width: isMobile && isMobileContentOpen /*TODO is What Open?? */ ? "auto" : "100%" }}>
        {children}
      </Box>

      {isMobile && (
        <Box sx={{ position: "absolute", top: 0, right: 0, p: 0.75, zIndex: 1 }}>{renderExpandButton()}</Box>
      )}

      {!isMobile && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            height: `${HEADER_HEIGHT_REM}rem`,
            display: "flex",
            alignItems: "center",
            px: 1.5,
            zIndex: 2,
          }}
        >
          <LanguageSelection isWhiteBackground={isWhiteBackground} />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: !isMobile ? "space-between" : "stretch",
          alignItems: "center",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: !isMobile ? `${FOOTER_HEIGHT_REM}rem` : "unset",
          px: !isMobile ? 1.5 : 0,
          zIndex: 2,
        }}
      >
        {!isMobile &&
            (projectLogo ? <Box component="img" src={projectLogo} alt="tidhar" /> : <div />)
        }

        {selectedApartment && activeStep === SUMMARY && !isMobileContentOpen && <InfoList />}

        {!isMobile && (
          <Box dir="ltr" display="flex" alignItems="center">
            <Typography
              color={isWhiteBackground ? "black" : "white"}
              fontWeight="fontWeightMedium"
              fontSize="12px"
              lineHeight={1}
              sx={(theme) => ({ mr: theme.direction === "ltr" ? 0.3 : 0, ml: theme.direction === "rtl" ? 0.3 : 0 })}
            >
              Powered by {/* Translation?? */}
            </Typography>

            {!isMobile && <HomezLogo color={isWhiteBackground ? palette.common.black : palette.common.white} />}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StepContent;
