import { FC } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { useWizardContext } from "../../context/WizardContext";
import useMedia from "../../context/hooks/useMedia";
import GroupedButtons from "../GroupedButtons/GroupedButtons";

interface StepSidePanelProps {
  onSubmit?: () => void;
  onNextStep?: () => void;
  nextDisabled?: boolean;
}

const StepSidePanel: FC<StepSidePanelProps> = ({ children, onNextStep, onSubmit, nextDisabled }) => {
  const { steps } = useWizardContext();
  const { isMobile } = useMedia();

  const boxStyle = () => ({
    borderRadius: "6px",
    backgroundColor: "background.paper",
    px: !isMobile ? 2.25 : 1.5,
  });

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflowY: "auto",
        scrollBehavior: "smooth",
        order: isMobile ? 2 : "unset",
        minHeight: isMobile ? "445px" : "unset",
      }}
    >
      {!isMobile && (
        <Box sx={[{ display: "grid", py: !isMobile ? 1.25 : 1.125 }, boxStyle]}>
          <Breadcrumbs
            sx={{
              "& .MuiBreadcrumbs-ol": {
                justifyContent: "space-between",
                flexWrap: "nowrap",
              },
            }}
            steps={steps}
          />
        </Box>
      )}
      <Box
        sx={[
          {
            py: !isMobile ? 1.875 : 1.125,
            flex: 1,
            overflowY: !isMobile ? "auto" : "initial",
            scrollBehavior: "smooth",
          },
          boxStyle,
        ]}
      >
        {children}
      </Box>
      {!isMobile && <GroupedButtons nextDisabled={nextDisabled} onNextStep={onNextStep} onSubmit={onSubmit} />}
    </Box>
  );
};

export default StepSidePanel;
