import React, { FC, useState, useEffect } from "react";
import { Box, FormGroup, Grid, Typography } from "@mui/material";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import StepLayout from "../../components/StepLayout/StepLayout";
import InfoDialog from "../../components/InfoDialog/InfoDialog";
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import { DIRECTION, LOCALE, useWizardContext } from "../../context/WizardContext";
import useMedia from "../../context/hooks/useMedia";
import { formatText } from "../../utils/appTextsReducer";
import { postLeadCapture } from "../../api/backend/leadCapture";
import ResponsiveMainImage from "../../components/ResponsiveMainImage/ResponsiveMainImage";
import StepLayoutMobile from "../../components/StepLayoutMobile/StepLayoutMobile";
import Button from "@mui/material/Button";
import InfoList from "../../components/InfoList/InfoList";
import useScreenTitle from "../../components/ScreenTitle/ScreenTitle";

const urlRegex = new RegExp(
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi
);
const onlyLettersRegex = new RegExp(/^[a-zA-Zא-ת_ ]+$/);
const phoneNumberValidation = new RegExp(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/);
const emailValidation = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

const initialInputValidationState = { name: false, email: false, phone: false };

enum FormInputs {
  EMAIL = "email",
  NAME = "name",
  PHONE = "phone",
}

interface UserPayload {
  name: string | null;
  email: string | null;
  phone: string | null;
}

const Summary: FC = () => {
  const { project, selectedBuilding, selectedApartment, isMobileContentOpen, appTexts, locale } = useWizardContext();
  useScreenTitle(appTexts?.screenName?.Summary?.[locale]);
  const { summary, infoDialog: infoDialogTexts } = appTexts || {};
  const { stepSidePanel } = appTexts || {};
  const { isMobile, isLandscape } = useMedia();
  const appDirection = locale === LOCALE.EN ? DIRECTION.LTR : DIRECTION.RTL;
  const imgUrl = selectedApartment?.layout?.furnishedImage?.asset?.url;
  const mobileImg = project?.defaultBackground?.defaultImage?.[locale]?.asset?.url;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<boolean>(false);
  const [reservationNumber, setReservationNumber] = useState<number | null>(null);
  const [userPayload, setUserPayload] = useState<UserPayload>({ name: null, email: null, phone: null });
  const [isAndroidKeyboardOpen, setIsAndroidKeyboardOpen] = useState<boolean>(false);
  const [inputsValidation, setInputsValidation] =
    useState<{ [key in FormInputs]: boolean }>(initialInputValidationState);
  const name = userPayload?.name || "";
  const isAllFilled = Object.values(userPayload).every((value) => value);
  const isPhoneValid =
    userPayload?.phone &&
    userPayload?.phone?.length >= 7 &&
    userPayload?.phone?.length <= 17 &&
    userPayload?.phone.match(phoneNumberValidation);
  const isEmailValid = userPayload?.email?.match(emailValidation);
  const isNameValid =
    name.trim() && name.trim()?.length < 20 && name.trim().match(onlyLettersRegex) && !urlRegex.test(name.trim());

  const onSubmit = async () => {
    setBackendError(false);
    if (!(isAllFilled && project?._id && selectedApartment?._id)) return;
    if (!isEmailValid || !isPhoneValid || !isNameValid) {
      setInputsValidation({ ...inputsValidation, email: !isEmailValid, phone: !isPhoneValid, name: !isNameValid });
      return;
    }
    setIsSending(true);
    setInputsValidation(initialInputValidationState);

    const result = await postLeadCapture({
      user: {
        name: userPayload.name as string,
        email: userPayload.email as string,
        phone: userPayload.phone,
      },
      projectId: project?._id,
      apartmentId: selectedApartment?._id,
      apartment: selectedApartment,
      building: selectedBuilding,
    });

    if (result.error) {
      setBackendError(true);
    } else {
      setInputsValidation(initialInputValidationState);
      setIsOpen(true);
      setReservationNumber(result.orderNumber || null);
    }

    setIsSending(false);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setUserPayload((prevState) => ({ ...prevState, [name as string]: value }));
  };

  useEffect(() => {
    //On android when the keyboard is open when we tap on input fields the keyboard is pushing the content.
    const resizeHandler = () => {
      if (!window.navigator.userAgent.match(/Android/i)) return;
      if (window.visualViewport.height < 160) setIsAndroidKeyboardOpen(true);
      else setIsAndroidKeyboardOpen(false);
    };
    window.visualViewport.addEventListener("resize", resizeHandler);
    return () => {
      window.visualViewport.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const renderForm = () => (
    <FormGroup sx={{ flexDirection: isMobile ? "row" : "column", flexWrap: isMobile ? "noWrap" : "wrap" }}>
      <Input
        name={FormInputs.NAME}
        type="text"
        required
        placeholder={summary?.placeholderName?.[locale]}
        error={inputsValidation.name}
        helperText={inputsValidation.name && summary?.errorName?.[locale]}
      />

      <Input
        name={FormInputs.EMAIL}
        type="email"
        required
        placeholder={summary?.placeholderEmail?.[locale]}
        error={inputsValidation.email}
        helperText={inputsValidation.email && summary?.errorEmail?.[locale]}
      />

      <Input
        name={FormInputs.PHONE}
        type="tel"
        required
        placeholder={summary?.placeholderPhone?.[locale]}
        error={inputsValidation.phone}
        helperText={inputsValidation.phone && summary?.errorPhone?.[locale]}
      />
      {isMobile && (
        <Button
          variant="contained"
          color="info"
          sx={{
            height: 48,
            width: 160,
            ml: 0.5,
            "&.Mui-disabled": {
              backgroundColor: "info.main",
              color: "common.black",
            },
          }}
          onClick={onSubmit}
          disabled={!isAllFilled}
        >
          {stepSidePanel?.send?.[locale]}
        </Button>
      )}
    </FormGroup>
  );

  const renderInfoDialog = () => (
    <InfoDialog isOpen={isOpen} onClose={handleCloseModal}>
      <Box
        sx={{
          position: "absolute",
          left: !isMobile ? "50%" : 0,
          right: 0,
          top: "50%",
          transform: !isMobile ? "translate(-50%,-50%)" : "translate(0,-50%)",
          textAlign: "center",
        }}
      >
        <Title
          variant="h1"
          sx={{
            "&.MuiTypography-h1": {
              direction: "ltr",
            },
          }}
        >
          {formatText(infoDialogTexts?.header?.[locale], name.trim().split(" ")[0])}
        </Title>
      </Box>
      <Title
        variant="body2"
        sx={{
          "&.MuiTypography-body2": {
            position: "absolute",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            paddingBottom: !isMobile ? 5 : 3.75,
            letterSpacing: "0.12px",
          },
        }}
      >
        {formatText(infoDialogTexts?.order?.[locale], reservationNumber || "")}
      </Title>
    </InfoDialog>
  );

  // This is the children of StepSidePanel component
  const sidePanelContent = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ marginBottom: "25px" }}>
        <Title variant="h1">{summary?.header?.[locale]}</Title>
        <Title
          variant="body2"
          sx={{
            "&.MuiTypography-body2": {
              marginTop: 0.5,
            },
          }}
        >
          {summary?.contentText?.[locale]}
        </Title>
      </Box>
      <Box>
        <Title variant="h6" sx={{ mb: 0.25 }}>
          {summary?.contactHeader?.[locale]}
        </Title>
        <Box component="form" onChange={onChange} display="flex" flexDirection="column" marginBottom="25px">
          {renderForm()}
        </Box>
      </Box>
      {backendError ? (
        <Typography sx={{ mt: "auto", color: "error.main" }}>{summary?.errorRequestFailed?.[locale]}</Typography>
      ) : null}
    </Box>
  );

  // This is the children of StepSideContent component
  const stepContent = (
    <>
      <Grid
        sx={{
          height: "100%",
          maxWidth: isMobile ? 720 : "none",
          margin: "0 auto",
          direction: isMobile ? appDirection : "unset",
        }}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <ResponsiveMainImage sx={{ objectFit: "contain" }} src={imgUrl || ""} alt="" hidden={isMobileContentOpen} />
        {isMobile && (
          <TransformWrapper centerOnInit centerZoomedOut disabled={!isMobileContentOpen}>
            <TransformComponent>
              <img width="100%" height="100%" src={imgUrl || ""} alt="" hidden={!isMobileContentOpen} />
            </TransformComponent>
          </TransformWrapper>
        )}
      </Grid>

      {renderInfoDialog()}
    </>
  );

  // StepLayout hold the StepSidePanel and StepSideContent components,
  // and we transfer their children through the props sidePanelChildren and contentChildren
  // StepLayout get another props dependent what the screen needs
  // All the props are optional

  return (
    <>
      {!isMobile && !isLandscape ? (
        <StepLayout
          sidePanelChildren={sidePanelContent}
          contentChildren={stepContent}
          onNextStep={onSubmit}
          nextDisabled={!isAllFilled || isSending}
          isWhiteBackground
        />
      ) : (
        <StepLayoutMobile
          isHeaderAndFooter={!isAndroidKeyboardOpen}
          dimBackground
          showForwardButton={false}
          footerContent={
            <Title variant="body2" sx={{ textAlign: "center" }}>
              {summary?.contentText?.[locale]}
            </Title>
          }
        >
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                px: 3.75,
              }}
            >
              <Box sx={{ zIndex: 2 }}>
                {isMobile && !isAndroidKeyboardOpen && <InfoList />}

                <Box component="form" onChange={onChange} display="flex" flexDirection="column" mt={2}>
                  {renderForm()}
                </Box>
              </Box>
              <Box
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  display: "block",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "#333",
                  opacity: 0.9,
                }}
              />
              <ResponsiveMainImage
                sx={{
                  objectFit: "cover",
                  zIndex: 0,
                  position: "absolute",
                  display: "block",
                  opacity: 0.9,
                }}
                src={mobileImg || ""}
                alt=""
              />
            </Box>
            {backendError ? (
              <Typography sx={{ color: isMobile ? "background.paper" : "error.main", textAlign: "center" }}>
                {summary?.errorRequestFailed?.[locale]}
              </Typography>
            ) : null}
            {renderInfoDialog()}
          </>
        </StepLayoutMobile>
      )}
    </>
  );
};

export default Summary;
