import React from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import { ReactComponent as CheckCircleRoundedIconModified } from "./check.svg";
import { SvgIcon, Stack } from "@mui/material";
import { Box } from "@mui/system";

interface CustomRadioButtonProps extends RadioGroupProps {
  options: ({ id: string | number; name: string | number; iconUrl?: string } | (string | number))[];
  widthType?: "short" | "oneThird" | "wide";
  variant?: "desktop" | "mobile";
}

const DEFAULT_GAP_BETWEEN_ITEMS = 0.65;

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  options,
  onChange,
  value,
  sx = [],
  variant = "desktop",
  widthType = "short",
  ...props
}) => {
  const WIDTH_TYPES: { [key: string]: number | string } = {
    short: variant === "mobile" ? 42 : 54,
    oneThird: `calc(100% / 3 - ${DEFAULT_GAP_BETWEEN_ITEMS}rem)`,
    wide: variant === "mobile" ? 72 : 149,
  };

  return (
    <RadioGroup
      row
      name="row-radio-buttons-group"
      onChange={onChange}
      value={value}
      sx={[
        {
          mt: -DEFAULT_GAP_BETWEEN_ITEMS, // this is required to compensate for the top margin of the items
          ml: -DEFAULT_GAP_BETWEEN_ITEMS,
        },
        variant === "mobile" && { flexWrap: "nowrap" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {options.map((option, index) => {
        let id: string | number, name, iconUrl;

        if (typeof option === "object") {
          id = option.id;
          name = option.name;
          iconUrl = option.iconUrl;
        } else {
          id = name = option;
        }

        return (
          <FormControlLabel
            key={`${index}-${id}`}
            sx={{
              mt: DEFAULT_GAP_BETWEEN_ITEMS, // Using margin instead of gap because of safari not supporting it
              ml: DEFAULT_GAP_BETWEEN_ITEMS,
              mr: 0,
              position: "relative",
              minWidth: WIDTH_TYPES[widthType],
              minHeight: WIDTH_TYPES["short"],
              "&:hover": {
                "& .MuiFormControlLabel-label": {
                  backgroundColor: variant === "mobile" ? "" : "grey.400",
                },
              },
              "& .MuiFormControlLabel-label": {
                backgroundColor: variant === "mobile" && iconUrl ? "common.white" : "grey.100",
                borderRadius: 1,
                fontSize: "body1.fontSize",
                fontWeight: "fontWeightBold",
                letterSpacing: "0.3px",
                position: "relative",
                height: "100%",
                minHeight: "inherit",
                minWidth: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: widthType === "wide" && !iconUrl ? 0.875 : 0,
              },
            }}
            value={id}
            onClick={(event: any) => {
              if (id === value) {
                onChange?.(event, value);
              }
            }}
            control={
              <Radio
                sx={{
                  "&.MuiRadio-root": {
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    "&:hover": {
                      borderRadius: "unset",
                      backgroundColor: "transparent",
                    },
                    "& ~ .MuiFormControlLabel-label": {
                      borderWidth: variant === "mobile" ? 2 : 1,
                      borderStyle: "solid",
                      borderColor: "transparent",
                      color: "common.black",
                    },
                    "&.Mui-checked": {
                      color: "background.default",
                      "& ~ .MuiFormControlLabel-label":
                        variant === "mobile"
                          ? {
                              bgcolor: !iconUrl ? "info.main" : "",
                              borderColor: iconUrl ? "info.main" : "",
                              borderWidth: 2,
                            }
                          : {
                              bgcolor: "background.paper",
                              borderColor: "primary.main",
                            },
                    },
                    "& .css-hyxlzm": {
                      visibility: "hidden",
                    },
                  },
                }}
                checkedIcon={
                  variant === "mobile" && iconUrl ? (
                    <SvgIcon
                      inheritViewBox
                      component={CheckCircleRoundedIconModified}
                      color="info"
                      sx={{
                        zIndex: 1,
                        position: "absolute",
                        left: 0,
                        top: 0,
                        transform: "translate(-50%, -50%)",
                        height: 30,
                        width: 30,
                      }}
                    />
                  ) : undefined
                }
              />
            }
            label={
              iconUrl ? (
                <Stack
                  sx={{
                    height: "100%",
                    width: "100%",
                    pt: 0.75,
                    pb: 1,
                    px: 0.75,
                  }}
                >
                  {name && (
                    <Box sx={{ alignSelf: "center", mb: 0.75, fontSize: "0.8125rem", letterSpacing: "0.24px" }}>
                      {name}
                    </Box>
                  )}
                  <Box sx={{ position: "relative", flex: 1 }}>
                    <Box
                      component="img"
                      alt={name as string}
                      title={name as string}
                      src={iconUrl}
                      width="100%"
                      height="100%"
                      sx={{ position: "absolute", objectFit: "contain" }}
                    />
                  </Box>
                </Stack>
              ) : (
                name
              )
            }
          />
        );
      })}
    </RadioGroup>
  );
};

export default CustomRadioButton;
