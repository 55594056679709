import drawImageProp from "./drawImageProp";
import "./index.scss";

export interface ViewerType {
  rotate: (direction: "right" | "left") => Promise<void>;
  zoomInOut: (direction: "in" | "out") => void;
  clearAllHighlights: () => void;
  highlightItems: (ids: string[], onClick?: (event: MouseEvent, id: string | null) => void) => void;
  toggleOverlay: (show: boolean) => void;
}

const ZOOM_MIN = 1;
const ZOOM_MAX = 1.5;

export default class Viewer implements ViewerType {
  container: HTMLDivElement;
  containerSvg: HTMLDivElement;
  imageList?: string[] | null;
  svgList?: string[] | null;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D | null;
  zoom = 1;
  canvasOffsetX = 0.5;
  canvasOffsetY = 0.5;
  positionCounter = 0;
  left = document.getElementById("left-btn");
  right = document.getElementById("right-btn");
  zoomOut = document.getElementById("zoomOut-btn");
  zoomIn = document.getElementById("zoomIn-btn");
  content = document.getElementById("content");
  bufferImgResize: HTMLImageElement | null = null;
  list: HTMLImageElement[] = [];
  svgCache: { [key: number]: HTMLElement } = {};

  constructor(container: HTMLDivElement | null, imageList?: string[] | null, svgList?: string[] | null) {
    this.container = container || new HTMLDivElement();
    this.imageList = imageList || null;
    this.svgList = svgList || null;

    this.canvas = document.createElement("canvas");
    this.container.appendChild(this.canvas);

    this.ctx = this.canvas.getContext("2d");
    if (this.ctx) {
      this.ctx.canvas.width = this.container.clientWidth * 2;
      this.ctx.canvas.height = this.container.clientHeight * 2;
      this.ctx.canvas.style.width = `${this.container.clientWidth}px`;
      this.ctx.canvas.style.height = `${this.container.clientHeight}px`;
    }

    this.containerSvg = document.createElement("div");
    this.containerSvg.className = "container-svg";
    this.container.appendChild(this.containerSvg);

    this.init().catch((e) => console.error("Error initializing 3D model", e));

    this.container.addEventListener("pointerdown", this.dragAndDrop.bind(this));
    window.addEventListener("resize", this.resize.bind(this));
  }

  private async init() {
    const img = new Image();
    img.src = this.imageList ? this.imageList[0] : "";
    img.onload = () => {
      drawImageProp(
        this.ctx,
        img,
        0,
        0,
        this.ctx?.canvas.width,
        this.ctx?.canvas.height,
        this.canvasOffsetX,
        this.canvasOffsetY,
        this.zoom
      );
      this.bufferImgResize = img;
    };
    this.list = await this.initImg();
    await this.initSvg();
  }

  private refreshSvgLayer(shouldConsiderZoom: boolean = false): HTMLElement {
    const layer = this.containerSvg.children[0] as HTMLElement;
    layer.removeAttribute("style");
    if (this.container.clientWidth / this.container.clientHeight <= this.list[0]?.width / this.list[0]?.height) {
      layer.style.height = this.container.clientHeight.toString();
      const W = layer.getBoundingClientRect().width * (shouldConsiderZoom ? this.zoom : 1);
      layer.style.width = W.toString();
      layer.style.marginTop = shouldConsiderZoom
        ? (
            (this.container.clientHeight * this.zoom - this.container.clientHeight) / 2 -
            (this.container.clientHeight * this.zoom - this.container.clientHeight) * this.canvasOffsetY
          ).toString()
        : "0";
      layer.style.marginLeft = ((this.container.clientWidth - W) * this.canvasOffsetX).toString();
    } else {
      layer.style.width = this.container.clientWidth.toString();
      const H = layer.getBoundingClientRect().height * (shouldConsiderZoom ? this.zoom : 1);
      layer.style.height = H.toString();
      layer.style.marginTop = ((this.container.clientHeight - H) * this.canvasOffsetY).toString();
      layer.style.marginLeft = shouldConsiderZoom
        ? (
            (this.container.clientWidth * this.zoom - this.container.clientWidth) / 2 -
            (this.container.clientWidth * this.zoom - this.container.clientWidth) * this.canvasOffsetX
          ).toString()
        : "0";
    }

    layer.style.transform = `scale(${this.zoom})`;

    return layer;
  }

  private async initImg(): Promise<HTMLImageElement[]> {
    return await Promise.all(
      new Array(this.imageList?.length).fill(0).map(async (_, i) => {
        const link = this.imageList ? this.imageList[i] : "";
        return await new Promise((res) => {
          const img = new Image();
          img.onload = function () {
            res(img);
          };
          img.src = link || "";
        });
      })
    );
  }

  private async initSvg(index: number = 0, shouldConsiderZoom: boolean = false) {
    this.containerSvg.innerHTML = "";

    if (this.svgCache[index]) {
      this.containerSvg.appendChild(this.svgCache[index]);
      this.refreshSvgLayer(shouldConsiderZoom);
    } else {
      const xhr = new XMLHttpRequest();
      const url = this.svgList ? this.svgList[index] : "";
      xhr.open("GET", url, false);
      xhr.overrideMimeType("image/svg+xml");
      xhr.onload = () => {
        if (xhr.responseXML) {
          const child = this.containerSvg.appendChild(xhr.responseXML.documentElement);
          this.refreshSvgLayer(shouldConsiderZoom);
          this.svgCache[index] = child;
        }
      };
      xhr.send("");
    }
  }

  private resize() {
    if (this.ctx) {
      this.ctx.canvas.width = this.container.clientWidth * 2;
      this.ctx.canvas.height = this.container.clientHeight * 2;
    }

    drawImageProp(
      this.ctx,
      this.bufferImgResize,
      0,
      0,
      this.ctx?.canvas?.width,
      this.ctx?.canvas?.height,
      this.canvasOffsetX,
      this.canvasOffsetY,
      this.zoom
    );

    this.refreshSvgLayer(true);
  }

  private dragAndDrop() {
    let x = 0;
    let y = 0;
    const move = (e: MouseEvent) => {
      if (!x || !y) {
        x = e.clientX;
        y = e.clientY;
        return;
      }
      const deltaX = e.clientX - x;
      const deltaY = e.clientY - y;
      this.canvasOffsetX = this.canvasOffsetX - deltaX / 500;
      if (this.canvasOffsetX >= 1) {
        this.canvasOffsetX = 1;
      }
      if (this.canvasOffsetX <= 0) {
        this.canvasOffsetX = 0;
      }
      this.canvasOffsetY = this.canvasOffsetY - deltaY / 500;
      if (this.canvasOffsetY >= 1) {
        this.canvasOffsetY = 1;
      }
      if (this.canvasOffsetY <= 0) {
        this.canvasOffsetY = 0;
      }
      drawImageProp(
        this.ctx,
        this.bufferImgResize,
        0,
        0,
        this.ctx?.canvas.width,
        this.ctx?.canvas.height,
        this.canvasOffsetX,
        this.canvasOffsetY,
        this.zoom
      );
      x = e.clientX;
      y = e.clientY;

      this.refreshSvgLayer(true);
    };

    const up = () => {
      this.container.removeEventListener("mousemove", move);
      this.container.removeEventListener("mouseup", up);
      this.container.removeEventListener("mouseleave", leave);
    };
    const leave = () => {
      this.container.removeEventListener("mousemove", move);
      this.container.removeEventListener("mouseup", up);
      this.container.removeEventListener("mouseleave", leave);
    };
    this.container.addEventListener("mouseleave", leave);
    this.container.addEventListener("mousemove", move);
    this.container.addEventListener("mouseup", up);
  }

  public async rotate(direction: "right" | "left") {
    if (!this.list) return;
    this.containerSvg.style.display = "none";
    const right = direction === "right";
    const temp = right
      ? this.positionCounter * (this.list.length / (this.svgList ? this.svgList.length : 1))
      : this.positionCounter <= 0
      ? this.list.length
      : this.positionCounter * (this.list.length / (this.svgList ? this.svgList.length : 1));

    for (
      let index = right ? temp : temp - 1;
      right
        ? index < temp + this.list.length / (this.svgList ? this.svgList.length : 1) + 1
        : index >= temp - this.list.length / (this.svgList ? this.svgList.length : 1);
      right ? index++ : index--
    ) {
      const i = index >= this.list.length ? 0 : index;
      const img = this.list[i];
      this.bufferImgResize = img;
      drawImageProp(
        this.ctx,
        img,
        0,
        0,
        this.ctx?.canvas.width,
        this.ctx?.canvas.height,
        this.canvasOffsetX,
        this.canvasOffsetY,
        this.zoom
      );

      await new Promise((res) =>
        setTimeout(() => {
          res(null);
        }, 800 / 20)
      );
    }

    if (direction === "right") {
      if (this.positionCounter === (this.svgList ? this.svgList.length : 1) - 1) {
        this.positionCounter = 0;
      } else {
        this.positionCounter = this.positionCounter + 1;
      }
    } else if (direction === "left") {
      if (this.positionCounter === 0) {
        this.positionCounter = (this.svgList ? this.svgList.length : 1) - 1;
      } else {
        this.positionCounter = this.positionCounter - 1;
      }
    }

    this.containerSvg.style.display = "flex";
    await this.initSvg(this.positionCounter, true);
  }

  public zoomInOut(direction: "in" | "out") {
    if (direction === "in") {
      if (this.zoom < ZOOM_MAX) {
        this.zoom = this.zoom + 0.1;
      }
    }
    if (direction === "out") {
      if (this.zoom > ZOOM_MIN) {
        this.zoom = this.zoom - 0.1;
      }
    }
    this.resize();
  }

  toggleOverlay(value: boolean) {
    const item = document.getElementsByTagName("g");
    [...item].forEach((item) => {
      item.style.opacity = (+value).toString();
    });
  }

  clearAllHighlights() {
    const items = document.getElementsByTagName("g");
    for (let i = 0; i < items.length; i++) {
      items[i].style.opacity = "";
    }
  }

  highlightItems(ids: string[], handleShowApartmentDialog?: (event: MouseEvent, id: string | null) => void) {
    ids.forEach((id) => {
      const item = document.getElementById(id);
      if (item) {
        item.style.opacity = "1";
        if (handleShowApartmentDialog) {
          item.onmouseenter = (event) => handleShowApartmentDialog(event, id);
          item.onmouseleave = (event) => handleShowApartmentDialog(event, null);
        }
      }
    });
  }
}
