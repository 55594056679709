import * as React from "react";
import { Slider, SliderProps } from "@mui/material";
import { LOCALE, useWizardContext } from "../../context/WizardContext";
import { formatPrice } from "../../utils/format";
import useMedia from "../../context/hooks/useMedia";

interface SliderRangeProps extends SliderProps {}

const SliderRange: React.FC<SliderRangeProps> = ({ marks, value, min, max, sx = [], ...rest }) => {
  const { locale } = useWizardContext();
  const { isLandscape } = useMedia();

  if (!Array.isArray(value)) {
    return null;
  }

  const thumbWidthPx = isLandscape ? 42 : 24;
  const thumbHeightPx = thumbWidthPx;
  const trackHeightPx = 8;

  const formatRangeNumberToText = (number: number) =>
    `${locale === LOCALE.HE ? "K" : ""}${formatPrice(locale, number)}${locale === LOCALE.EN ? "K" : ""}`;

  const sliderRangePriceLabel = formatRangeNumberToText(value[0]) + " - " + formatRangeNumberToText(value[1]);

  return (
    <Slider
      marks={marks}
      min={min}
      max={max}
      value={value}
      {...rest}
      sx={[
        (theme) => ({
          width: `calc(100% - ${thumbWidthPx}px)`,
          height: trackHeightPx,
          mx: `${thumbWidthPx / 2}px`,
          py: `${(thumbHeightPx - trackHeightPx) / 2}px !important`,
          marginBottom: 0,
          "& .MuiSlider-rail": {
            backgroundColor: isLandscape ? "background.paper" : "grey.100",
            opacity: 1,
          },
          "& .MuiSlider-track": {
            color: "info.main",
            border: "none",
            "&::after": {
              content: `'${sliderRangePriceLabel}'`,
              position: "absolute",
              top: "30px",
              left: "50%  ",
              transform: "translate(-50%,-50%)",
              color: "primary.main",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              display: isLandscape ? "none" : "block",
            },
          },
          "& .MuiSlider-markLabel": {
            top: theme.spacing(-1.25),
            fontWeight: 600,
            display: isLandscape ? "none" : "block",
            color: "grey.500",
            "&[data-index='0']": {
              transform: "translate(0)",
            },
            [`&[data-index='${(marks as Exclude<SliderProps["marks"], boolean | undefined>[number][])?.length - 1}']`]:
              {
                transform: "translate(-100%)",
              },
          },
          "& .MuiSlider-thumb": {
            width: thumbWidthPx,
            height: thumbHeightPx,
            backgroundColor: isLandscape ? "info.main" : "background.paper",
            borderRadius: isLandscape ? "6px" : "50%",
            pointerEvents: "initial !important",
            border: isLandscape ? "none" : "1px solid black",
            "&:hover, &.Mui-focusVisible, &.Mui-active": {
              boxShadow: "none",
            },

            "&[data-index='0']::after": {
              content: `'${formatPrice(locale, value[0], false)}M'`,
              display: isLandscape ? "flex" : "none",
              ...muiSliderThumbLabelStyle,
            },
            "&[data-index='1']::after": {
              content: `'${formatPrice(locale, value[1], false)}M'`,
              display: isLandscape ? "flex" : "none",
              ...muiSliderThumbLabelStyle,
            },
          },
          "& .MuiSlider-valueLabelOpen": {
            top: theme.spacing(3.75),
            background: "none",
            color: "common.black",
            fontSize: "20px",
            fontWeight: "fontWeightBold",
            padding: 0,
          },
          "& .MuiSlider-valueLabelOpen:before": {
            display: "none",
          },
          "& .MuiSlider-mark": {
            display: "none",
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      aria-labelledby="non-linear-slider"
    />
  );
};

export default SliderRange;

const muiSliderThumbLabelStyle = {
  alignItems: "center",
  justifyContent: "center",
  color: "primary.main",
  fontWeight: "bold",
  fontSize: "12px",
};
