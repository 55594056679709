import { useEffect, useState } from "react";
import { Route, Routes, useLocation, Outlet, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import * as Sentry from "@sentry/react";
import AboutProject from "./screens/AboutProject/AboutProject";
import { ApartmentSelection } from "./screens/ApartmentSelection/ApartmentSelection";
import Entry from "./screens/Entry/Entry";
import Summary from "./screens/Summary/Summary";
import Error from "./screens/Error/Error";
import { LOCALE, useWizardContext, DIRECTION } from "./context/WizardContext";
import ModelApartments from "./screens/ModelApartments/ModelApartments";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ThemeProvider } from "@mui/material/styles";
import { aptWizTheme } from "./aptWiztheme";
import { aptWizthemeHeFontStyle } from "./aptWizthemeHeFontStyle";
import { aptWizthemeEnFontStyle } from "./aptWizthemeEnFontStyle";
import RtlProvider from "./rtl/RtlProvider";
import "./transitionStyle.css";
import { deepmerge } from "@mui/utils";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import { useMemo } from "react";
import useMedia from "./context/hooks/useMedia";
import Landscape from "./components/Landscape/Landscape";

const BASE_ROUTE = process.env.REACT_APP_BASE_ROUTE || '';

function App() {
  const { locale, isLoading, project } = useWizardContext();
  const location = useLocation();
  const appDirection = locale === LOCALE.EN ? DIRECTION.LTR : DIRECTION.RTL;

  const theme = useMemo(() => {
    const font = locale === LOCALE.EN ? aptWizthemeEnFontStyle : aptWizthemeHeFontStyle;
    return createTheme(deepmerge(aptWizTheme as ThemeOptions, font));
  }, [locale]);

  const { isMobile, isLandscape } = useMedia();
  const showLandscapePage = !isLandscape && isMobile && location.pathname !== "/";

  //This useEffect with the resize event is to calculate the viewport on portrait when we have address bar and when we not s
  const isAndroid = window.navigator.userAgent.match(/Android/i) || null;
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const { height: currentHeight } = window.visualViewport;

  useEffect(() => {
    const { height: currentHeight } = window.visualViewport;

    const handleResize = () => {
      const { height: afterChangeHeight, width: afterChangeWidth } = window.visualViewport;
      const isLandScapeView = afterChangeWidth > afterChangeHeight;
      const portrait = afterChangeWidth < afterChangeHeight;
      if (portrait && currentHeight < afterChangeHeight) setIsFullscreen(true);
      else if (portrait && currentHeight === afterChangeHeight) setIsFullscreen(false);
      else if (portrait && currentHeight > afterChangeHeight) setIsFullscreen(false);
      else if (isLandScapeView && currentHeight < afterChangeHeight) setIsFullscreen(true);
      else if (isLandScapeView && currentHeight > afterChangeHeight) setIsFullscreen(false);
    };

    window.visualViewport.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames="page" timeout={500}>
        {() => (
          <RtlProvider>
            <ThemeProvider
              theme={{
                ...theme,
                direction: appDirection,
                palette: {
                  ...theme.palette,
                  info: {
                    main: project?.accentColor?.hex || theme.palette.info.main
                  }
                }
              }}
            >
              <Sentry.ErrorBoundary fallback={<Error />}>
                <Box
                  sx={{
                    height: "100%",
                    display: showLandscapePage ? "none" : "block",
                    width: !isLandscape && isMobile ? "100vh" : "unset",
                  }}
                  dir={appDirection}
                >
                  {isLoading ? (
                    <Box />
                  ) : (
                    <>
                      <Routes location={location}>
                        <Route path={BASE_ROUTE}>
                          <Route index element={<Entry />} />
                          <Route path="about" element={<AboutProject />} />
                          <Route path="model" element={<ModelApartments />} />
                          <Route
                              path="selection"
                              element={
                                <ApartmentSelection />
                              }
                          />
                          <Route element={<ConditionalRoute />}>
                            <Route path="summary" element={<Summary />} />
                          </Route>
                        </Route>
                        <Route path="*" element={<RedirectToBase />} />
                      </Routes>
                    </>
                  )}
                </Box>
                <Box
                  dir={appDirection}
                  sx={{
                    height: "100%",
                    width: "100vh",
                    display: showLandscapePage ? "block" : "none",
                  }}
                >
                  <Landscape currentHeight={currentHeight} isAndroid={isAndroid} isFullscreen={isFullscreen} />
                </Box>
              </Sentry.ErrorBoundary>
            </ThemeProvider>
          </RtlProvider>
        )}
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;

const RedirectToBase = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(!pathname.includes(BASE_ROUTE)){
      navigate(`${BASE_ROUTE}${pathname}`)
    }
  }, [navigate, pathname])

  return <Outlet />;
}

const ConditionalRoute = () => {
  const { selectedApartment } = useWizardContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedApartment) {
      navigate(`/${BASE_ROUTE}`);
    }
  }, [selectedApartment, navigate]);

  if (!selectedApartment) {
    return null;
  }

  return <Outlet />;
};
